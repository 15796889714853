// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col, .row {
  height: 100%;
}

checkbox-list {
  flex: 1;
  overflow: hidden;
}

.chart-container {
  height: 100%;
  display: block;
  padding-top: 0px;
  background: white;
}

.sidebar-visible .col-chart {
  right: 280px;
}

.sidebar-float .col-params {
  position: fixed;
  z-index: 1;
}

.col-chart {
  padding-right: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0px;
  top: 0;
  bottom: 0;
  width: auto;
}

.chart-view-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.view-wrapper {
  overflow: hidden;
  flex-wrap: nowrap;
}

.btnRollUp {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0;
  margin: 0;
  height: 30px;
  color: #0094D1;
}

.btnParams:hover {
  background: #e6e6e6;
}

.btnParams {
  position: absolute;
  top: 8px;
  right: 46px;
  background: transparent;
  border: none;
  width: 26px;
  padding-left: 4px;
  /*position: absolute;
  bottom: 19px;
  right: 17px;
  background: transparent;
  border: none;
  width: 26px;
  padding-left: 4px;*/
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-detail-chart/plan-detail-chart.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;;AAIA;EACE,OAAA;EACA,gBAAA;AADF;;AAGA;EACE,YAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAEA;EACE,eAAA;EACA,UAAA;AACF;;AACA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,OAAA;EACA,UAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;AAEF;;AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAGF;;AADA;EACE,gBAAA;EACA,iBAAA;AAIF;;AAFA;EACE,kBAAA;EACA,MAAA;EACA,UAAA;EACA,UAAA;EACA,SAAA;EACA,YAAA;EACA,cAAA;AAKF;;AAFA;EACE,mBAAA;AAKF;;AAHA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA;;;;;;qBAAA;AAYF","sourcesContent":["@import \"src/assets/wfm_variables\";\n\n.col,.row{\n  height:100%;\n}\n\ncheckbox-list{\n  flex: 1;\n  overflow: hidden;\n}\n.chart-container{\n  height:100%;\n  display: block;\n  padding-top: 0px;\n  background: white;\n}\n\n.sidebar-visible .col-chart{\n  right:280px;\n}\n.sidebar-float .col-params{\n  position: fixed;\n  z-index: 1;\n}\n.col-chart{\n  padding-right: 0;\n  overflow: hidden;\n  position: absolute;\n  left:0;\n  right:0px;\n  top:0;\n  bottom: 0;\n  width:auto;\n}\n.chart-view-wrapper{\n  position: relative;\n  width:100%;\n  height:100%;\n}\n.view-wrapper{\n  overflow: hidden;\n  flex-wrap: nowrap;\n}\n.btnRollUp{\n  position: absolute;\n  top: 0;\n  left: 10px;\n  padding: 0;\n  margin: 0;\n  height: 30px;\n  color:#0094D1;\n}\n\n.btnParams:hover{\n  background: #e6e6e6;\n}\n.btnParams{\n  position: absolute;\n  top: 8px;\n  right: 46px;\n  background: transparent;\n  border: none;\n  width: 26px;\n  padding-left: 4px;\n  /*position: absolute;\n  bottom: 19px;\n  right: 17px;\n  background: transparent;\n  border: none;\n  width: 26px;\n  padding-left: 4px;*/\n}\n// ::ng-deep{\n//   .highcharts-button.highcharts-reset-zoom{\n//     transform: translateX(100%) translateX(-167px) translateY(6px) !important;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
