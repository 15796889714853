// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-plan-overlay {
  font-family: "Open Sans";
  font-weight: 400;
  color: #577085;
  font-size: 13px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/no-rows-overlay/no-rows-overlay.component.scss"],"names":[],"mappings":"AAEA;EACE,wBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AADF","sourcesContent":["$color-grey: #909090;\n\n.no-plan-overlay {\n  font-family: 'Open Sans';\n  font-weight: 400;\n  color: #577085;\n  font-size: 13px;\n  margin-top:20px\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
