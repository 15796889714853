// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fte-setting {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
}

.hours-per-day {
  padding-top: 18px;
  padding-bottom: 6px;
}

.hours-per-week {
  padding-top: 18px;
  padding-bottom: 6px;
}
.time-picker-container{
  display: flex;
  align-items: center;
}
.side-label{
  margin-left:16px;
}

form {
  margin-top: 24px;
}
form input.p-inputnumber{
  width:93px;
}
form table td{
  vertical-align: top;
}
table td .month-label{
  line-height:32px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/ct-settings/fte-settings/fte-settings.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,4BAA4B;EAC5B,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".fte-setting {\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-bottom-color: #cccccc;\n  padding-bottom: 12px;\n  font-weight: 700;\n  font-size: 16px;\n}\n\n.hours-per-day {\n  padding-top: 18px;\n  padding-bottom: 6px;\n}\n\n.hours-per-week {\n  padding-top: 18px;\n  padding-bottom: 6px;\n}\n.time-picker-container{\n  display: flex;\n  align-items: center;\n}\n.side-label{\n  margin-left:16px;\n}\n\nform {\n  margin-top: 24px;\n}\nform input.p-inputnumber{\n  width:93px;\n}\nform table td{\n  vertical-align: top;\n}\ntable td .month-label{\n  line-height:32px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
