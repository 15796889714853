import { Component, OnInit } from "@angular/core";
import { NumberUtils } from "src/app/common/utils/number.utils";

@Component({
  selector: "wfm-esp-validation-indicator-cell-v2",
  templateUrl: "./validation-indicator-cell-v2.component.html",
  styleUrls: ["./validation-indicator-cell-v2.component.css"]
})
export class ValidationIndicatorCellComponentV2 implements OnInit {

  public params: any;
  public validationSum: any;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
    // Format according to the locale.
    this.validationSum = NumberUtils.formatNumberIfNecessary(params.context.columnSum(this.params.colDef.field),
      params.locale, params.metadata.decimal);
  }

  isValidIndicator(){
    let context = this.params.context;
    //return context.isGridValid()==false;
    return context.isGridValid()==false &&
      context.isValidColumn(this.params.colDef.field)==false &&
      this.params.api.getFocusedCell() &&
      this.params.api.getFocusedCell().rowIndex==this.params.rowIndex &&
      this.params.api.getFocusedCell().column.colId==this.params.column.colId
  }

  refresh(){
    return false;
  }


}
