import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { Subscription, of, throwError } from "rxjs";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Actions, ofType } from "@ngrx/effects";
import { MessageService } from "primeng/api";
import { saveAs } from 'file-saver';
import {
  ImportForecastTemplate,
  ImportForecastTemplateSuccess,
  AppActionTypes,
  ImportForecastTemplateFailed,
  ImportForecastUploadFile,
  ImportForecastUploadFileFailed,
  ImportForecastUploadFileProgress,
  ImportForecastUploadFileSuccess,
  CheckImportForecastFileExists,
  ImportForecastViewLog, CheckImportForecastFileExistsSuccess, CheckImportForecastFileExistsFailed
} from 'src/app/store/actions';

@Component({
  selector: 'wfm-esp-imported-forecasts-dialog',
  templateUrl: './imported-forecasts-dialog.component.html',
  styleUrls: ['./imported-forecasts-dialog.component.scss'],
  providers: [MessageService]
})
export class ImportedForecastsDialogComponent implements OnInit, OnDestroy {

  private _isLoading = false;
  private _enableCancel = true;
  protected subscriptionList: Array<Subscription> = [];
  readonly _nameOfTemplateFile = "forecast-import-template.csv";
  readonly _blobType = 'text/csv';
  readonly _maxFileSize = 200000000;
  readonly _noFileChosen = this.translate.instant("import.forecast.dialog.file.choose.empty");
  readonly _fileExtensionIsNotValid = this.translate.instant("import.forecast.dialog.file.upload.validation");
  readonly _fileSizeNotIsNotValid = this.translate.instant("import.forecast.dialog.file.upload.validation.size");
  readonly _fileNameExists = this.translate.instant("import.forecast.dialog.same.file.exists");
  readonly _fileNameCheckFailed = this.translate.instant("import.forecast.dialog.same.file.check.failed");

  _showError = false;
  _messageSelectedFile = "";
  _errorMessage = "";
  _chkboxErrorMessage = "";
  _enableAccept = false;
  _isUploadingFile = false;
  _percentageLoaded : number = 0;
  _fileToUpload = null;
  _showCheckBox = false;
  isConfirmed = false;

  constructor(public activeModal: NgbActiveModal, private store: Store<any>,
              private action$: Actions, private messageService: MessageService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.initHandlers();
    this._messageSelectedFile = this._noFileChosen;
  }

  close(result) {
    this.activeModal.close(result);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  download() {
    let payload = {};
    this.store.dispatch(new ImportForecastTemplate(payload));
  }

  downloadSuccess(action: ImportForecastTemplateSuccess) {
    const blob = new Blob([(action.payload)], { type: this._blobType });
    saveAs(blob, this._nameOfTemplateFile);
  }

  downloadFailed(action: ImportForecastTemplateFailed) {
    let failMsg = this.translate.instant("import.forecast.dialog.template.failure");
    this.messageService.add({ severity: "error", detail: failMsg });
  }

  enableUploadButton(event){
    this._enableAccept = event.target.checked;
    this.isConfirmed = event.target.checked;
  }

  onFileChange(event) {
    let fileNameToUpload: string, typeOfFileToUpload: string;
    this._enableAccept = false;
    this._showError = false;
    this._showCheckBox = false;

    this._fileToUpload = event.target.files[0];
    if (!this._fileToUpload) {
      this._messageSelectedFile = this._noFileChosen;
      return;
    }

    // 1. Verify type of the file is CSV.
    fileNameToUpload = this._fileToUpload.name;
    typeOfFileToUpload = fileNameToUpload.substring(fileNameToUpload.lastIndexOf('.') + 1);
    if (typeOfFileToUpload.toLowerCase() !== 'csv') {
      this._showError = true;
      this._messageSelectedFile = this._noFileChosen;
      this._fileToUpload = null;
      this._errorMessage = this._fileExtensionIsNotValid;
      return;
    }

    // 2. Verify size of the file.
    if (this._fileToUpload.size > this._maxFileSize) {
      this._showError = true;
      this._messageSelectedFile = this._noFileChosen;
      this._fileToUpload = null;
      this._errorMessage = this._fileSizeNotIsNotValid;
      return;
    }

    // 3. Check if the file name already exists
    this._isLoading = true;
    this._messageSelectedFile = fileNameToUpload;
    this.checkFileExists(fileNameToUpload);
  }

  checkFileExists(importForecastName) {
    let payload = { "importForecastName": importForecastName };
    this.store.dispatch(new CheckImportForecastFileExists(payload));
  }

  checkFileExistsSuccess(action: CheckImportForecastFileExistsSuccess) {
    this._enableAccept = true;
    this._isLoading = false;
    this._showError = false;
    this._showCheckBox = false;
    this._errorMessage = "";
  }

  checkFileExistsFailed(action: CheckImportForecastFileExistsFailed) {
    if (action.payload.errorStatus) {
      this._errorMessage = this._fileNameCheckFailed;
      this._showCheckBox = false;
    } else {
      this._chkboxErrorMessage = this._fileNameExists;
      this._showCheckBox = true;
    }
    this._enableAccept = false;
    this._isLoading = false;
    this._showError = true;
  }

  uploadFile() {
    if (!this._fileToUpload) {
      console.log("fileToUpload is not valid.");
      return;
    }
    this._percentageLoaded = 1;
    this._isUploadingFile = true; // start the upload of the file, disable buttons.
    const formData = new FormData();
    formData.append("file", this._fileToUpload);
    this.store.dispatch(new ImportForecastUploadFile(formData));
  }

  uploadFileSuccess(action: ImportForecastUploadFileSuccess) {

    this._messageSelectedFile = this._noFileChosen;
    this._enableAccept = false;
    this._isUploadingFile = false;

    this.close('uploadFileSuccess');
  }

  uploadFileFailed(action: ImportForecastUploadFileFailed) {
    this._errorMessage = this.translate.instant("import.forecast.dialog.file.upload.failed");
    this._isUploadingFile = false;
    this._messageSelectedFile = this._noFileChosen;
    this._enableAccept = false;
    this._showError = true;
  }

  uploadFileProgress(action: ImportForecastUploadFileProgress) {
    if (action.payload.percentageLoaded) {
      this._percentageLoaded = action.payload.percentageLoaded;
    }
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastTemplateSuccess))
        .subscribe(this.downloadSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastTemplateFailed))
        .subscribe(this.downloadFailed.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastUploadFileSuccess))
        .subscribe(this.uploadFileSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastUploadFileFailed))
        .subscribe(this.uploadFileFailed.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ImportForecastUploadFileProgress))
        .subscribe(this.uploadFileProgress.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.CheckImportForecastFileExistsSuccess))
        .subscribe(this.checkFileExistsSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.CheckImportForecastFileExistsFailed))
        .subscribe(this.checkFileExistsFailed.bind(this))
    );
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  public ngOnDestroy() {
    this.clearSubscriptionList();
  }

}
