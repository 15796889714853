// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* modal dialogs */
.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  min-height: 90px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 0px;
  line-height: 1.5;
  /*width:550px;*/
  min-width: 450px;
}
.modal-body .modal-buttons {
  text-align: center;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

::ng-deep .wfm-modal .modal-content {
  border-radius: 0;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
}
::ng-deep .wfm-modal .modal-header.warn {
  border-bottom: solid 1px #FC9537;
}
::ng-deep .wfm-modal .modal-header.error {
  border-bottom: solid 1px #fa3f40;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}

.close:focus-visible {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wfm-modal/wfm-modal.component.scss"],"names":[],"mappings":"AAAA,kBAAA;AAEA;EAEE,2CAAA;EACA,gCAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AADF;AAGE;EACE,kBAAA;AADJ;;AAKA;EACE,2CAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,6BAAA;AAFF;;AAME;EACE,gBAAA;EACA,yBAAA;AAHJ;AAME;EACE,YAAA;EACA,mBAAA;AAJJ;AAMI;EACE,gCAAA;AAJN;AAOI;EACE,gCAAA;AALN;AAUE;EACE,YAAA;EACA,6BAAA;AARJ;;AAWA;EACE,aAAA;AARF","sourcesContent":["/* modal dialogs */\n\n.modal-header {\n  // padding: 12px 20px 11px 20px;\n  font-family: \"Open Sans\", Arial, sans-serif;\n  border-bottom: 1px solid #DAE2E8;\n  border-radius: 0;\n}\n\n.modal-body {\n  min-height: 90px;\n  background-color: #e9f1f5;\n  padding: 20px;\n  margin-left: 0px;\n  line-height: 1.5;\n  /*width:550px;*/\n  min-width: 450px;\n\n  .modal-buttons {\n    text-align: center;\n  }\n}\n\n.modal-title {\n  font-family: \"Open Sans\", Arial, sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: normal;\n  color: #404040;\n  margin-left: 5px;\n}\n\n.modal-footer {\n  padding: 12px 8px;\n  border-top: solid 1px #d0d5d9;\n}\n\n::ng-deep .wfm-modal {\n  .modal-content {\n    border-radius: 0;\n    border: 1px solid #DAE2E8;\n  }\n\n  .modal-header {\n    height: 80px;\n    align-items: center;\n\n    &.warn {\n      border-bottom: solid 1px #FC9537;\n    }\n\n    &.error {\n      border-bottom: solid 1px #fa3f40;\n    }\n\n  }\n\n  .modal-footer {\n    height: 80px;\n    border-top: solid 1px #d0d5d9;\n  }\n}\n.close:focus-visible{\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
