// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.modal-body {
  font-size: 14px;
}

/* native progressbar */
.b-p-progress {
  width: 370px;
  height: 15px;
  border: solid 1px #d0d5d9;
  display: inline-block;
  box-sizing: border-box;
}

.b-progress-bar {
  background-color: #237bc2;
  display: inline-block;
  height: 14px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.b-p-label {
  color: #707070;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.b-p-textlabel {
  color: #707070;
  font-size: 12px;
  margin-right: 5px;
}

.error-message-label {
  position: relative;
  padding-left: 5px;
  font: 400 14px "Open Sans Regular";
}`, "",{"version":3,"sources":["webpack://./src/app/pages/imported-forecasts/imported-forecasts-dialog/imported-forecasts-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,UAAA;EACA,SAAA;EACA,wBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA,uBAAA;AACA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AACA;EACE,yBAAA;EACA,qBAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AAEF;;AAAA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAGF;;AADA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;AAIF;;AAFA;EACE,kBAAA;EACA,iBAAA;EACA,kCAAA;AAKF","sourcesContent":[".btn-link.btn-anchor {\n  outline: none !important;\n  padding: 0;\n  border: 0;\n  vertical-align: baseline;\n}\n\n.modal-body {\n  font-size: 14px;\n}\n\n/* native progressbar */\n.b-p-progress{\n  width: 370px;\n  height: 15px;\n  border: solid 1px #d0d5d9;\n  display: inline-block;\n  box-sizing: border-box;\n}\n.b-progress-bar{\n  background-color: #237bc2;\n  display: inline-block;\n  height: 14px;\n  box-sizing: border-box;\n  margin-bottom: 1px;\n}\n.b-p-label{\n  color: #707070;\n  font-size: 16px;\n  font-weight: bold;\n  margin-left:5px;\n}\n.b-p-textlabel{\n  color:#707070;\n  font-size:12px;\n  margin-right:5px;\n}\n.error-message-label {\n  position: relative;\n  padding-left: 5px;\n  font:400 14px \"Open Sans Regular\";\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
