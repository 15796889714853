import {Injectable} from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";

import {Observable} from "rxjs";

import {Store} from "@ngrx/store";
import {RequestSuccessful} from "../store/actions";
import { StorageUtil } from "../helpers/storage-util";
import {tap} from "rxjs/operators";

@Injectable()
export class WfmTokenInterceptor implements HttpInterceptor {

  isRefreshingToken = false;

  constructor(public store: Store<any>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const customHeaders = {setHeaders: {}};
    const localData = StorageUtil.getUserData();

    const currentToken = localData?localData.token:null;

    // if we have a http post and the body is formdata we skip the Content-type
    if ( !(request.method.toLowerCase() === "post" && request.body instanceof FormData) ) {
      customHeaders.setHeaders["Content-Type"] = "application/json";
    }

    if (typeof currentToken !== "undefined" && currentToken !== null) {
      customHeaders.setHeaders["Authorization"] = `Bearer ${currentToken}`;
    }

    request = request.clone(customHeaders);

    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse && !(event instanceof HttpErrorResponse)) {
          this.store.dispatch(new RequestSuccessful(true));
        }
      })
    );
  }
}
