import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2, SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[frozenScroll]'
})
export class GridFrozenScrollDirective implements OnDestroy,AfterViewInit, OnChanges{

  @Input('frozenScroll') gridApi:any;

  private scrollListener: () => void;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.rowDataUpdated = this.rowDataUpdated.bind(this);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.gridApi && changes.gridApi.currentValue) {
      this.gridApi.addEventListener('rowDataUpdated',this.rowDataUpdated);
    }
  }
  rowDataUpdated(){
    this.listenToScroll();
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {
    if (this.scrollListener) {
      this.scrollListener();
    }
    if(this.gridApi){
      this.gridApi.removeEventListener('rowDataUpdated',this.rowDataUpdated);
    }

  }

  listenToScroll(){
    if (this.scrollListener) {
      this.scrollListener();
    }

    const childElement = this.el.nativeElement.querySelector('.ag-floating-top-viewport');

    if (childElement) {
      this.scrollListener = this.renderer.listen(childElement, 'wheel', (event) => {
        event.preventDefault();

        let mainBodyViewport = this.el.nativeElement.querySelector(".ag-body-horizontal-scroll-viewport");
        mainBodyViewport.scrollLeft+=event.deltaX;
        mainBodyViewport.dispatchEvent(new Event("scroll"));
      });
    }
  }

}
