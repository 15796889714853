import { Component } from "@angular/core";


@Component({
    selector: 'wfm-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})

export class ErrorComponent {
   

}