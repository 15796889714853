/* istanbul ignore file */

import { Component, Input, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {select, Store} from '@ngrx/store';
import {Actions} from '@ngrx/effects';
import {GridApi, GridReadyEvent} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {TooltipFieldComponent} from '../tooltip-field/tooltip-field.component';
import {NumberUtils} from '../../../common/utils/number.utils';
import {Observable} from 'rxjs';


@Component({
  selector: 'mswf-rules-dialog',
  templateUrl: './mswf-rules-dialog.component.html',
  styleUrls: ['./mswf-rules-dialog.component.css']
})
export class MswfRulesDialogComponent implements OnInit {
  private gridApi: GridApi;
  frameworkComponents: any;
  colDefs: any;
  mswfData:any;
  planName: string;
  private rules: any;

  public language = "en";
  public locale = "en-US";
  public userInfo$: Observable<string>;

  @Input()
  public displayPlanName = true;

  constructor(public activeModal: NgbActiveModal,
              private store: Store<any>,
              private action$: Actions,
              protected translateSvc: TranslateService,
              private modalService: NgbModal) {
    this.initColumnDefs();
    this.frameworkComponents = {
      TooltipField: TooltipFieldComponent
    }

    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.user && value.user.language) {
        if (this.language != value.user.language) {
          this.language = value.user.language;
        }
      }
      if (value && value.locale) {
        this.locale = value.locale;
      }
    });
  }

  ngOnInit(): void {
    this.initRules();
  }
  get ctSetName(){
    return `${this.mswfData.ctSet.id} ${this.mswfData.ctSet.name}`
  }
  initColumnDefs(){
    this.colDefs = [
      {
        headerName: null,
        headerValueGetter: () => this.translateSvc.instant("multistep.workflow.column.ct"),
        field: "displayName",
        tooltipField: "displayName",
        //cellRenderer: "TooltipField",
        flex:1,
        //colId: "plan_label_average",
        suppressMovable: true,
        enableBrowserTooltips:true

      },
      {
        headerName: null,
        headerValueGetter: () => this.translateSvc.instant("multistep.workflow.column.percent"),
        field: "distributionPercent",
        //cellRenderer: "TooltipField",
        //colId: "plan_label_average",
        suppressMovable: true,
        valueFormatter:this.cellValueFormatter,
        width:110,
      },
      {
        headerName: null,
        headerValueGetter: () => this.translateSvc.instant("multistep.workflow.column.offset"),
        field: "offsetDays",
        //cellRenderer: "TooltipField",
        //colId: "plan_label_average",
        suppressMovable: true,
        width:90
      }
    ]
  }
  dismiss() {
    this.activeModal.close();
  }

  onGridReady($event: GridReadyEvent) {
    this.gridApi = $event.api;
  }

  private initRules() {
    this.rules = this.mswfData.cts.filter((ct)=>!ct.initial).sort((ct1,ct2)=>ct1-ct2);
    let initialCT = {...this.mswfData.cts.find((ct)=>ct.initial),offsetDays:null,distributionPercent:null}

    //add initial CT to head
    this.rules.unshift(initialCT);
    //add displayName to every ct object
    this.rules = this.rules.map((ct)=>{return {displayName:`${ct.ctId} ${ct.ctName}`,...ct}});
  }
  public cellValueFormatter = (params: any) => {
    if (params && params.data) {
      return NumberUtils.formatNumberIfNecessary(params.value, this.locale, 4);
    }
  };
}
