export class ApiCredentialResponse {
    apiCredentials:ApiCredential[];
}

export class ApiCredential {
    oid: string;
    status: ApiCredentialStatus;
    createdTime: string;
    accessKey: string;
    secretKey: string;
}

export enum ApiCredentialStatus {
    ACTIVATION_FAILED = 'ACTIVATION_FAILED',
    ACTIVE = 'ACTIVE',
    DEACTIVATION_FAILED = 'DEACTIVATION_FAILED',
    INACTIVE = 'INACTIVE',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
    PENDING_DEACTIVATION = 'PENDING_DEACTIVATION'
}
