import { Component, OnInit } from '@angular/core';
import { MessageUtils } from 'src/app/common/utils/message.utils';

@Component({
  selector: 'wfm-esp-sso-login-error',
  templateUrl: './sso-login-error.component.html',
  styleUrls: ['./sso-login-error.component.css']
})
export class SsoLoginErrorComponent implements OnInit {

  copyrightMessage = "";

  constructor() { }

  ngOnInit(): void {
    this.copyrightMessage = MessageUtils.getCopyrightMessage();
  }

  loginToESP(): void {
    window.location.href = location.origin;
  }
}
