// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  display: flex;
}
.validation-navigator{
  background: #fff1f1;
  border: 1px solid #cc9b9b;
  color: #6b7984;
  display:flex;
  height: 40px;
  padding:4px 14px;
  border-radius: 5px;
  align-items: center;
  gap:8px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  transition: color 0.05s ease-in-out, background-color 0.05s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0px 1px 0px 0px #cc9b9b !important;

}
.validation-navigator:hover:active{
  border: 1px solid #D23C40;
  background: #f8dddd;

}
.validation-navigator:hover{
  border: 1px solid #D23C40;
  background: #FFECEC;

}
.icon-system_error{
  color: #d24444;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/validation-navigator/validation-navigator.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,OAAO;EACP,eAAe;EACf,yBAAiB;UAAjB,iBAAiB;EACjB,qIAAqI;EACrI,8CAA8C;;AAEhD;AACA;EACE,yBAAyB;EACzB,mBAAmB;;AAErB;AACA;EACE,yBAAyB;EACzB,mBAAmB;;AAErB;AACA;EACE,cAAc;AAChB","sourcesContent":[":host{\n  display: flex;\n}\n.validation-navigator{\n  background: #fff1f1;\n  border: 1px solid #cc9b9b;\n  color: #6b7984;\n  display:flex;\n  height: 40px;\n  padding:4px 14px;\n  border-radius: 5px;\n  align-items: center;\n  gap:8px;\n  cursor: pointer;\n  user-select: none;\n  transition: color 0.05s ease-in-out, background-color 0.05s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n  box-shadow: 0px 1px 0px 0px #cc9b9b !important;\n\n}\n.validation-navigator:hover:active{\n  border: 1px solid #D23C40;\n  background: #f8dddd;\n\n}\n.validation-navigator:hover{\n  border: 1px solid #D23C40;\n  background: #FFECEC;\n\n}\n.icon-system_error{\n  color: #d24444;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
