import { Component, OnInit } from '@angular/core';
import {ILoadingOverlayAngularComp} from "ag-grid-angular";

@Component({
  selector: 'wfm-esp-imported-forecasts-loading-overlay',
  templateUrl: './imported-forecasts-loading-overlay.component.html',
  styleUrls: ['./imported-forecasts-loading-overlay.component.scss']
})
export class ImportedForecastsLoadingOverlayComponent implements ILoadingOverlayAngularComp {

  constructor() { }

  agInit(): void {
  }

}
