import {Component, HostListener, OnDestroy, OnInit} from "@angular/core";
import {GridReadyEvent} from "@ag-grid-enterprise/all-modules";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {DatePipe} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MessageService} from "primeng/api";
import {filter, map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {DateUtils} from "../../common/utils/date";
import {EspNoRowsOverlayComponent} from "../../components/no-rows-overlay/no-rows-overlay.component";
import {EspPermission} from "../plan/plan-list-page/plan-list-page.component";
import {PlanParam} from "../plan/common/plan-param";
import {v4 as uuid} from "uuid";

import {
  AppActionTypes, EditHistoricalData, EditHistoricalDataFailed, EditHistoricalDataSuccess, GetHistoricalData,
  GetHistoricalDataCTs, GetHistoricalDataCTsFailed,
  GetHistoricalDataCTsSuccess, GetHistoricalDataFailed,
  GetHistoricalDataSuccess,
  SaveHistoricalData,
  SaveHistoricalDataFailed,
  SaveHistoricalDataSuccess
} from "../../store/actions";
import {LoadingRowsOverlayComponent} from "../../components/loading-rows-overlay/loading-rows-overlay.component";
import {addDays} from "date-fns";
import {NumericEditorComponent} from "../plan/common/cell-editors/numeric/numeric-editor.component";
import {ValidatorFactory} from "../plan/common/validator-factory";
import {NumberUtils} from "../../common/utils/number.utils";
import {ClipboardModule} from "@ag-grid-enterprise/clipboard";
import {RangeSelectionModule} from "@ag-grid-enterprise/range-selection";
import {EnterpriseCoreModule} from "@ag-grid-enterprise/core";
import {DialogOptions} from "../../common/dialog-options";
import {WfmModalComponent as modalComponent} from "../../components/wfm-modal/wfm-modal.component";
import {HistoryValidationCellComponent} from "./history-validation-cell/history-validation-cell.component";
import * as moment from "moment";
import {EspTableHeaderComponent} from '../../components/esp-table-header/esp-table-header.component';
import {Features} from '../../models/plan';
import {AuthenticationService} from '../../authentication.service';
import {DebounceUtil} from '../../helpers/debounce';


var dateLocale = DateUtils.getDatefnsLocale("en");

export enum PRESET_DATES {
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_4_WEEKS = "LAST_4_WEEKS",
  CUSTOM_RANGE = "CUSTOM_RANGE"
};

@Component({
  selector: "wfm-esp-historical-data",
  templateUrl: "./historical-data.component.html",
  styleUrls: ["./historical-data.component.scss"],
  providers: [MessageService]
})
export class HistoricalDataComponent implements OnInit, OnDestroy {
  rowData: any;
  defaultColDef: any;
  frameworkComponents: any;
  rowClassRules: any;
  loadingOverlayComponent: any;
  _isLoading: boolean;
  _isGridValid: any | boolean = true;
  _isPristine = true;
  colDefs = [];
  gridOptions: any;
  modules = [ClipboardModule, RangeSelectionModule, EnterpriseCoreModule]
  gridContext: any;
  rangeDates: any[];
  espPermission: any;
  sessionId: any;
  requestId: number;
  maxDateRange: any;
  minDateRange: any;
  ctList: any = [];
  selectedCTs = [];
  paramsList: any;
  selectedParams: any[];

  icons = {
    sortAscending: `<svg class="icon"><use xlink:href="#icon-sort-ascending" /></svg>`,
    sortDescending: '<svg class="icon"><use xlink:href="#icon-sort-descending" /></svg>'
  };

  histDataOptions = {
    cts: [],
    params: [],
    startDate: "",
    endDate: ""
  }

  public noRowsComponentParams = {
    getOverlayMsg: () => {
      let msgKey = "historical-data.no.results";
      return this.translateSrv.instant(msgKey);
    }
  };
  private unsubscribe$: Subject<void> = new Subject();
  private locale = "en-US";
  private userInfo$;
  private language: any;
  gridApi: any;
  _isInitializing = true;
  datePresets: any = [];
  selectedPreset: any;
  private editingContext: any;

  private lastEditedCell: any;
  public _dirtyCells = new Set();
  public _invalidCells = new Set();
  private columnApi = null;
  private cellClassRules = null;

  constructor(private translateSrv: TranslateService,
              private store: Store<any>,
              private action$: Actions,
              private datePipe: DatePipe,
              public modalService: NgbModal,
              private messageService: MessageService,
              private authService: AuthenticationService) {
    this.maxDateRange = addDays(new Date(), -1);
    this.minDateRange = addDays(new Date(), 7 * 26 * -1);
    this.updateGridValid = DebounceUtil.debounce(this.updateGridValid.bind(this),100);
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next()
  }

  get hasEspModifyPermission() {
    return this.espPermission === EspPermission.MODIFY;
  }

  get isValidFilter() {
    return this.selectedCTs != null &&
      this.selectedCTs.length > 0 &&
      this.selectedParams != null &&
      this.selectedParams.length > 0 &&
      this.rangeDates != null &&
      this.rangeDates.length == 2 &&
      this.rangeDates[0] != null &&
      this.rangeDates[1] != null;
  }

  onDateRangeClosed() {
    if (this.rangeDates && this.rangeDates.length == 2 && this.rangeDates[1] === null) {
      this.rangeDates = [this.rangeDates[0], this.rangeDates[0]]
      this.updateHistoricalData();
    }
  }

  onPresetDateRangeChange() {
    if (this.selectedPreset === PRESET_DATES.CUSTOM_RANGE) {
      this.rangeDates = null;
    } else {
      this.updateDateRange();
    }
    this.updateHistoricalData();
  }

  onDateRangeChange() {
    //this.rowData = [];
    let yesterday = addDays(new Date(), -1);
    // console.log(arguments, this.rangeDates);
    if (this.rangeDates.length == 2 && this.rangeDates[1] === null) {
      this.maxDateRange = new Date(this.rangeDates[0]);
      this.maxDateRange = addDays(this.maxDateRange, 27);
      if (this.maxDateRange > yesterday) {
        this.maxDateRange = yesterday;
      }
    } else {
      this.maxDateRange = yesterday;
      this.updateHistoricalData();
    }

  }

  updateDateRange() {
    let yesterday = addDays(new Date(), -1);
    if (this.selectedPreset === PRESET_DATES.LAST_7_DAYS) {
      this.rangeDates = [addDays(new Date(), -7), yesterday]
    } else if (this.selectedPreset === PRESET_DATES.LAST_4_WEEKS) {
      this.rangeDates = [addDays(new Date(), -28), yesterday]
    }
  }

  ngOnInit(): void {
    this.sessionId = uuid();

    this.initGridContext();
    this.initPresetDates();
    this.initParamsData();
    this.initGridOptions();
    this.initHandlers();
    this.initRowClassRules();
    this.restoreFilterSelection() //we do it twice so while the CT list is loading, we won't see wrong dates/params
    this.updateCtList();

    this.store.select("state").pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data.user) {
          this.espPermission = data.user.espPermission;
        }
        if (data.locale) {
          this.locale = data.locale;
        }
      });
    this.frameworkComponents = {
      noRowsOverlay: EspNoRowsOverlayComponent,
      loadingRowsOverlayComponent: LoadingRowsOverlayComponent,
      numericEditor: NumericEditorComponent,
      validationIndicatorCell: HistoryValidationCellComponent,
      agColumnHeader: EspTableHeaderComponent
    };
    this.defaultColDef = {
      cellClassRules:this.cellClassRules
    }
  }

  initGridContext() {
    this.gridContext = {
      isLoading: this.isLoading.bind(this),
      cellEditable: this.cellEditable.bind(this),
      isGridValid: () => this._isGridValid,
      editingContext: () => this.editingContext,
      showMessage: this.showMessage.bind(this),
      //isReadOnly:()=>this.isReadOnly,
      isValidCell: (row, field) => {
        return this._invalidCells.has(this.getCellID(row)) === false;
      },
      getValidatorMessage(param) {
        console.log(param);
      }
    };
  }

  initGridOptions() {
    this.gridOptions = {
      onCellEditingStarted: this.cellEditingStarted.bind(this),
      onCellEditingStopped: this.cellEditingStopped.bind(this),
      onCellValueChanged: this.cellValueChanged.bind(this),
      onPasteEnd: this.onPasteEnd.bind(this),
      //processCellForClipboard:this.processCellForClipboard.bind(this),
      processDataFromClipboard: this.processDataFromClipboard.bind(this),
      processCellFromClipboard: this.processCellFromClipboard.bind(this)
    }
  }

  initParamsData() {
    this.paramsList = [
      {
        value: {
          label: this.translateSrv.instant(PlanParam.CONTACTS),
          name: this.translateSrv.instant(PlanParam.CONTACTS),
          id: "CONTACTS",
          metadata: {
            decimal: "0",
            validator: ValidatorFactory.getValidator(PlanParam.ACTUAL_CONTACTS, null, this.translateSrv, this.locale),
          }
        },
        isSelected: false,
        isReadOnly: false
      },
      {
        value: {
          name: this.translateSrv.instant(PlanParam.AHT),
          id: "AHT",
          label: this.translateSrv.instant(PlanParam.AHT),
          metadata: {
            decimal: "2",
            validator: ValidatorFactory.getValidator(PlanParam.ACTUAL_AHT, null, this.translateSrv, this.locale),
          }
        },
        isSelected: false,
        isReadOnly: false
      }
    ];
    this.selectedParams = this.paramsList.map((param) => param.value);
  }


  initRowClassRules() {
    this.rowClassRules = {
      'editable-row':(params)=>params.data.ct.permission==="MODIFY",
      'readonly-row':(params)=>params.data.ct.permission==="VIEW"
    }
    this.cellClassRules = {
      "cell-editable":(params)=>{
        return this.cellEditable(params);
      },
    }
  }

  initColumns() {
    this.colDefs = [
      {
        headerName: null,
        headerValueGetter: () => this.translateSrv.instant("historical-data.ct.column"),
        field: "ct.label",
        tooltipField: "ct.label",
        headerClass: "",
        width: 200,
        lockPosition: true,
        pinned: "left",
        sortable: true,
        suppressFillHandle:true,
        sortingOrder: ["asc", "desc"],
        cellClassRules: {
          "read-only": (params) => {
            return this.hasEspModifyPermission == false || params.data.ct.permission === EspPermission.VIEW
          },
        },
        comparator: this.compareCTsColumn,
      },
      {
        headerName: null,
        headerValueGetter: () => this.translateSrv.instant("plan.label.param"),
        field: "param.name",
        valueGetter: (row) => this.translateSrv.instant(row.data.param.label),
        tooltipValueGetter: (row) => this.translateSrv.instant(row.data.param.label),
        cellClass: "text-left",
        width: 200,
        lockPosition: true,
        pinned: "left",
        suppressFillHandle:true,
        sortingOrder: ["asc", "desc"],
        sortable: true,
        cellClassRules: {
          "read-only": (params) => {
            return this.hasEspModifyPermission == false || params.data.ct.permission === EspPermission.VIEW
          },
        },
        comparator: this.compareParamsColumn
      }
    ];


    let days = this.getDays(this.rangeDates[0], this.rangeDates[1]);

    days.forEach((day) => {
      this.colDefs.push({
        field: day,
        tooltipValueGetter: this.cellValueFormatter.bind(this),
        headerName: DateUtils.getShortDate3(this.datePipe, day, this.locale),
        //headerComponent: "weekHeader",
        suppressSizeToFit: true,
        width: 114,
        //headerClass: "no-bold no-icon no-black " + (index === 0 ? "first-item" : ""),
        //cellEditorSelector: this.cellEditor,
        valueGetter: this.cellValueGetter.bind(this),
        valueSetter: this.cellValueSetter.bind(this),
        valueFormatter: this.cellValueFormatter.bind(this),
        cellEditorSelector: this.cellEditor.bind(this),
        cellEditorParams: this.cellEditorParam.bind(this),
        editable: this.cellEditable.bind(this),
        cellRenderer: "validationIndicatorCell",
        cellRendererParams: this.cellRendererParams.bind(this),
        lockPosition: true,

        cellClassRules: {
          invalid: (params) => {
            return this._isGridValid == false && this._invalidCells.has(this.getCellID(params));
          },
          locked: (params) => {
            return this._isGridValid == false && this._invalidCells.has(this.getCellID(params)) == false;
          },
          "read-only": (params) => {
            return this.hasEspModifyPermission == false || params.data.ct.permission === EspPermission.VIEW
          },
          "imported-value": (params) => {
            const key = params.colDef.field;
            return params.data[key]?.imported;
          },
        }
      });

    });
  }

  initHandlers() {
    this.userInfo$ = this.store.select("state");
    this.userInfo$.pipe(takeUntil(this.unsubscribe$)).subscribe((value: any) => {
      if (value && value.user && value.user.language) {
        if (this.language != value.user.language) {
          dateLocale = DateUtils.getDatefnsLocale(value.user.language);
          this.language = value.user.language;
        }
      }
      if (value && value.locale) {
        this.locale = value.locale;
      }
    });

    this.action$
      .pipe(
        ofType(AppActionTypes.GetHistoricalDataCTsSuccess),
        map((action: GetHistoricalDataCTsSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.historicalDataCTsReceivedHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetHistoricalDataCTsFailed),
        map((action: GetHistoricalDataCTsFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.historicalDataCTsFailureHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetHistoricalDataSuccess),
        filter((action: GetHistoricalDataSuccess) => action.requestId == this.requestId),
        map((action: GetHistoricalDataSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.historicalDataReceivedHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetHistoricalDataFailed),
        filter((action: GetHistoricalDataFailed) => action.requestId == this.requestId),
        map((action: GetHistoricalDataFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.historicalDataFailureHandler.bind(this));


    this.action$
      .pipe(
        ofType(AppActionTypes.EditHistoricalDataSuccess),
        map((action: EditHistoricalDataSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.editHistoricalDataReceivedHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.EditHistoricalDataFailed),
        map((action: EditHistoricalDataFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.editHistoricalDataFailureHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.SaveHistoricalDataSuccess),
        map((action: SaveHistoricalDataSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.saveSuccessHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.SaveHistoricalDataFailed),
        map((action: SaveHistoricalDataFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.saveFailureHandler.bind(this));



  }

  updateCtList() {

    this.store.dispatch(new GetHistoricalDataCTs());
  }

  updateHistoricalData() {
    if (this.isValidFilter == false) {
      this.requestId = Date.now();
      this.rowData = [];
    } else {
      this.updateDateRange(); //make sure 7 days is always up to date.
      this.saveFiltersSelection();
      this.requestId = Date.now();
      this.showLoadingOverlay();
      this.store.dispatch(new GetHistoricalData({
        ctOids: this.selectedCTs.map((ct) => ct.oid),
        startDate: DateUtils.getLocalISODate(this.rangeDates[0]),
        endDate: DateUtils.getLocalISODate(this.rangeDates[1]),
        paramNames: this.selectedParams.map((param: any) => param.id)
      }, this.sessionId, this.requestId));

    }

  }

  isLoading() {
    return this._isLoading;
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  filterRows($event: any) {

  }

  saveChanges() {
    this.setGridLoading(true)
    this.store.dispatch(new SaveHistoricalData(this.sessionId ))

  }

  discardChanges(){
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "historical-data.discard.dlg.title";
    dgOption.messageKey = "historical-data.discard.dlg.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.cancelLabel = "btn.discard.cancel.label";
    dgOption.confirmLabel = "btn.discard.confirm.label";

    let result = modalComponent.showModalMessage(dgOption, this.modalService);
    result.then(this.startNewSession, () => console.debug("cancel discard changes"));

  }

  startNewSession = () => {
    this.sessionId = uuid();
    this.updateHistoricalData();
    this._isPristine=true;
  }

  updateSelectedCTs($event: any) {
    this.ctList.forEach((ct) => {
      ct.isSelected = $event.value.indexOf(ct.value) > -1;
    });
    this.updateHistoricalData();
  }


  updateSelectedParams($event: any) {
    this.paramsList.forEach((param) => {
      param.isSelected = $event.value.indexOf(param.value) > -1;
    });
    this.updateHistoricalData();
  }

  getDays(startDate, endDate) {
    //we clone the objects because we modify it
    startDate = new Date(startDate);
    endDate = new Date(endDate)


    var daysOfYear = [];
    for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
      const withoutTime = moment(d).format("YYYY-MM-DD");
      daysOfYear.push(withoutTime);
    }
    return daysOfYear;
  }

  historicalDataCTsReceivedHandler(payload) {
    this.ctList = this.initCtList(payload);
    this.restoreFilterSelection();
    this.updateHistoricalData();
    this._isInitializing = false;
  }

  initCtList(payload: any): any {
    return payload.entityList.map(entity => {
      return {
        value: {
          ...entity,
          label: `${entity.id} ${entity.name}`
        },
        isSelected: false,
        isReadOnly: entity.entityPermission !== "MODIFY"
      };
    });
  }

  historicalDataCTsFailureHandler(payload) {
    this.rowData = [];
    this.messageService.add({severity: "error", detail: this.translateSrv.instant("historical-data.err.cts")});
  }

  historicalDataFailureHandler(payload) {
    this.rowData = [];
    this.messageService.add({severity: "error", detail: this.translateSrv.instant("historical-data.err.retrieved")});


  }

  historicalDataReceivedHandler(payload) {
    let historicalData = payload.historicalData;
    let rows = [];

    if(!this.isValidFilter){
      this.setGridLoading(false);
      return;
    }

    this.initColumns();

    historicalData.forEach((data) => {
      let ctInfo = {
        ...data.ctInfo,
        label: `${data.ctInfo.id} ${data.ctInfo.name}`
      };
      //server might not send the parameters if there's no data, but we still want to see the rows in the grid
      this.selectedParams.forEach((selectedParam: any) => {
        let remoteParamInfo = data.params.find((param) => param.paramName == selectedParam.id);
        let row = {
          ct: ctInfo,
          param: selectedParam,
          metadata: selectedParam.metadata
        };

        if (remoteParamInfo) {
          row = {
            ...row,
            ...remoteParamInfo.data
          };
        }
        rows.push(row);
      });
    });
    this.rowData = rows;
    this._invalidCells.clear();
    this._dirtyCells.clear();
    this._isGridValid = true;
    this.setGridLoading(false);
  }

  showLoadingOverlay() {
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
  }

  hideOverlay() {
    if (this.gridApi) {
      this.gridApi.hideOverlay();
    }
  }

  saveSuccessHandler(payload:any){

    this.setGridLoading(false);
    this._isPristine = true;
    let successMsg = this.translateSrv.instant("historical-data.save.success");
    this.messageService.add({severity: "success", detail: successMsg});


  }
  saveFailureHandler(payload: any){

    this.setGridLoading(false);
    let failureMsg = this.translateSrv.instant("historical-data.save.failure");
    this.messageService.add({severity: "error", detail: failureMsg});
  }

  saveFiltersSelection() {

    const histDataOptions = {
      cts: this.selectedCTs.map(ct => ct.oid),
      params: this.selectedParams.map(p => p.id),
      selectedPreset: this.selectedPreset,
      startDate: this.rangeDates[0],
      endDate: this.rangeDates[1]
    }
    localStorage.setItem("histDataSettings", JSON.stringify(histDataOptions));
  }

  restoreFilterSelection() {

    try {
      const optionsStr = localStorage.getItem("histDataSettings");
      const histDataOptions = optionsStr ? JSON.parse(optionsStr) : this.defaultFilterSelection();

      this.selectedCTs = this.ctList.filter(ct => histDataOptions.cts.includes(ct.value.oid)).map(ct => ct.value);
      this.ctList.filter(ct => histDataOptions.cts.includes(ct.value.oid)).map(ct => ct.isSelected = true);


      this.selectedParams = this.paramsList.filter(p => histDataOptions.params.includes(p.value.id)).map(p => p.value);
      this.paramsList.filter(p => histDataOptions.params.includes(p.value.id)).map(p => p.isSelected = true);

      this.selectedPreset = this.datePresets.find((range) => range.value === histDataOptions.selectedPreset).value;
      this.rangeDates = [new Date(histDataOptions.startDate), new Date(histDataOptions.endDate)];

      if (this.selectedPreset !== PRESET_DATES.CUSTOM_RANGE) {
        this.updateDateRange();
      }
    } catch (e) {
      this.selectedCTs = [];
      this.selectedPreset = PRESET_DATES.LAST_7_DAYS;
    }

  }

  defaultFilterSelection() {
    const params: [] = this.paramsList.map(param => param.value.id);

    return {
      cts: [],
      params,
      selectedPreset: this.datePresets[0].value
    }
  }

  saveSort() {
    var colState = this.columnApi.getColumnState();
    var sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return {colId: s.colId, sort: s.sort, sortIndex: s.sortIndex};
      });
    //let savedSort = sortState;
    window.localStorage.setItem("historicalDataSort", JSON.stringify(sortState));
  }

  restoreSort() {
    let savedSort = window.localStorage.getItem("historicalDataSort");
    let savedSortObj = savedSort ? JSON.parse(savedSort) : [{colId: "ct.label", sort: "asc", sortIndex: 0}];
    if (this.gridApi && this.gridApi.columnModel) {
      this.columnApi.applyColumnState({
        state: savedSortObj,
        defaultState: {sort: null},
      });
    }
  }

  onSortChanged() {
    this.saveSort();
  }

  onNewColumnsLoaded() {
    this.restoreSort();
  }

  private initPresetDates() {
    this.datePresets = [
      {name: this.translateSrv.instant("historical-data.presets.7.days"), value: PRESET_DATES.LAST_7_DAYS},
      {name: this.translateSrv.instant("historical-data.presets.4.weeks"), value: PRESET_DATES.LAST_4_WEEKS},
      {name: this.translateSrv.instant("historical-data.presets.custom.range"), value: PRESET_DATES.CUSTOM_RANGE}
    ];
  }

  cellEditingStarted(params: any) {
    this.editingContext = params;
  }

  cellEditingStopped(params: any) {

  }

  cellValueChanged(cellInfo) {
      this._dirtyCells.add(this.getCellID(cellInfo));
      this._isGridValid = false;
      this._isPristine = false;
      this.updateGridValid();

  }

  onPasteEnd() {

  }

  /*  processCellForClipboard(){

    }*/
  processDataFromClipboard(params) {
    //console.log(params);
    let data = params.data;
    let lastRow = data[data.length - 1];
    if (lastRow.length === 1 && lastRow[0] === "") { //ms excel adds a new row with empty cell on windows
      data.pop();                           //which we need to remove before the cell parsing
    }
    return data;
  }

  processCellFromClipboard(params) {
    //console.log(params);
    return params.value;
  }

  updateGridValid() {

    let result = true;
    this._dirtyCells.forEach((cellId: string) => {
      let isValidCell = this.validateCellById(cellId);
      result = result && isValidCell;
    });

    this._isGridValid = result;
    if(result && this._dirtyCells.size>0){
      this.dispatchEditRequest();
    }
    this.refreshCells();
  }

  private refreshCells() {
    if (this.gridApi) {
      this.gridApi.refreshCells({force: true});
    }
  }


  cellEditor(params: any) {
    var editor = {component: null, param: params};
    editor.component = "numericEditor";

    return editor;
  }

  cellEditable(params: any) {
    //if(this.isReadOnly){
    //  return false;
    //}
    if (this.hasEspModifyPermission == false || params.data.ct.permission === EspPermission.VIEW)
      return false;


    if (this._isGridValid) {
      return true;
    }

    return this._invalidCells.has(this.getCellID(params));

  }

  cellRendererParams(params: any) {
    var param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  }

  cellEditorParam(params: any) {
    var param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      zeroOnEmpty: false,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  }

  getValue(row, column) {
    let result = row[column]
    if (result === undefined) {
      result = {value: ""};
    }
    return result.value;
  }

  cellValueGetter(cell) {
    let value = this.getValue(cell.data, cell.colDef.field);

    if (value !== null && value !== "" && !isNaN(value)) {
      return parseFloat(value);
    }
    return value;
  }

  cellValueFormatter(params) {
    //let value = this.weekValueGetterOrBase(cell.data, cell.colDef.field);
    /*let value = this.getValue(cell.data,cell.colDef.field);
    if(value==="") {
      return "";
    }else{
      return Number(value).toLocaleString(this.locale, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%";
    }*/

    if (params && params.data && params.data.metadata) {
      let options = {decimal: params.data.metadata.decimal};
      return NumberUtils.formatNumberIfNecessary(params.value, this.locale, options.decimal);
    }
  }


  cellValueSetter(params) {
    const decimal = params.data.param.metadata.decimal;
    let newVal:any = params.newValue ? parseFloat(this.floor(params.newValue, decimal)) : parseFloat(params.newValue);
    let oldVal = params.oldValue ? parseFloat(this.floor(params.oldValue, decimal)) : parseFloat(params.oldValue);

    const data = params.data;

    if (newVal !== oldVal) {
      newVal = isNaN(newVal) ? "" : newVal
      data[params.colDef.field] = {value: newVal};
      //this.setBaseValue(params.colDef.field);
      this.lastEditedCell = {...data, oldVal, newVal, field: params.colDef.field};
      return true;
    }
    return false;
  }

  public floor(num: any, dec: any): string {
    if (num && num.toString().includes(".")) {
      let array = num.toString().split(".");
      let decPart = array.pop();
      if (!decPart) {
        decPart = "0";
      }
      array.push(decPart.substring(0, dec));
      let result = array.join(".");
      return result;
    } else {
      return num.toString();
    }
  }

  async showMessage(titleKey, msgKey, type) {

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = titleKey;
    dgOption.messageKey = msgKey;
    dgOption.msgType = type;
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    try {
      const dialog = await modalComponent.showModalMessage(dgOption, this.modalService);
      return dialog;
    } catch (e) {

    }

  }

  getCellID(cellInfo) {
    let id = cellInfo.data.ct.oid + "|" + cellInfo.data.param.id + "|" + cellInfo.colDef.field;
    return id;
  }

  private validateCellById(cellId: string) {
    let [oid, param, date] = cellId.split("|");
    let row = this.rowData.find((row) => row.ct.oid === oid && row.param.id === param);
    let value = row[date].value;
    let validator = row.metadata.validator;

    let isValid = validator.isValid(value);
    if (isValid) {
      this._invalidCells.delete(cellId);
    } else {
      this._invalidCells.add(cellId);
    }
    return isValid;
  }

  compareCTsColumn(valueA, valueB, nodeA, nodeB, isInverted) {
    let ct1 = nodeA.data.ct.id;
    let ct2 = nodeB.data.ct.id;
    let param1 = nodeA.data.param.id;
    let param2 = nodeB.data.param.id;
    if (ct1 > ct2) {
      return 1;
    } else if (ct1 < ct2) {
      return -1;
    } else {
      if (param1 < param2) {
        return isInverted ? -1 : 1;
      } else {
        return isInverted ? 1 : -1;
      }
    }
  }

  compareParamsColumn(valueA, valueB, nodeA, nodeB, isInverted) {
    let ct1 = nodeA.data.ct.id;
    let ct2 = nodeB.data.ct.id;
    let param1 = nodeA.data.param.id;
    let param2 = nodeB.data.param.id;

    if (param1 > param2) {
      return 1;
    } else if (param1 < param2) {
      return -1;
    } else {
      if (isInverted) {
        if (ct1 < ct2) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (ct1 > ct2) {
          return 1;
        } else {
          return -1;
        }
      }
    }
  }
  private dispatchEditRequest(){
    let editedData = {};
    this._dirtyCells.forEach((cellId:string)=>{
     let [oid, param, date] = cellId.split("|");
     let row = this.rowData.find((row) => row.ct.oid === oid && row.param.id === param);
     let value = row[date].value;

     if(!editedData[oid])
       editedData[oid]={};
     if(!editedData[oid][date])
       editedData[oid][date]={};

     editedData[oid][date][param]=value;
    });
    this.setGridLoading(true);
    this.store.dispatch(new EditHistoricalData(editedData,this.sessionId));
    // console.log(editedData);
  }
  private setGridLoading(value){
    this._isLoading = value;
    if(this.gridApi){
      value ? this.gridApi.showLoadingOverlay() : this.gridApi.hideOverlay();
    }
  }
  private editHistoricalDataReceivedHandler(){
    this._isPristine=false;
    this._dirtyCells.clear();
    this._invalidCells.clear();
    this.updateGridValid();
    this.setGridLoading(false);
  }
  private editHistoricalDataFailureHandler(){
    this.messageService.add({severity: "error", detail: this.translateSrv.instant("historical-data.edit.failure")});
    this.setGridLoading(false);
    this.updateHistoricalData();
  }


  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
    if (!this._isPristine) {
      $event.returnValue=true;
    }
  }

  async canDeactivate() {
    // allow navigation away if grid is pristine
    if (this._isPristine) {
      return true;
    }

    // save changes dialog setup
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unsaved.dialog.title";
    dgOption.messageKey = "plan.exit.unsaved.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";

    try {
      await modalComponent.showModalMessage(dgOption, this.modalService);
      // allow user to leave
      return true;
    } catch(e) {
      console.debug("user chose not to leave staffing grid");
      return false;
    }
  }

}
