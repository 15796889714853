import { Component, OnInit } from '@angular/core';
import {MessageUtils} from "../../common/utils/message.utils";

@Component({
  selector: 'esp-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.css']
})
export class LogoutPageComponent implements OnInit {
  copyrightMessage = "";

  ngOnInit(): void {
    this.copyrightMessage = MessageUtils.getCopyrightMessage();
  }

  goLoginPage() {
    window.location.href = location.origin;
  }

}
