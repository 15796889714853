import { PlanParam } from "./plan-param";


export class ConfirmationMsgFactory {

    static getMsg(param: any) {
        let msg: WfmConfirmMsg = new WfmConfirmMsg();
        switch (param.paramName) {
            case PlanParam.ASA:
            case PlanParam.MS_EFFICIENCY:
            case PlanParam.CONTACTS:
            case PlanParam.HOURLY_RATE:
            case PlanParam.OCCUPANCY:
            case PlanParam.SERVICE_LEVEL_PERCENT:
            case PlanParam.SERVICE_LEVEL_TIME:
            case PlanParam.SHRINKAGE:
            case PlanParam.MEAN_CONCURRENCY:
                msg.title = "confirm.msg.title";
                msg.msg = "confirm.msg.body." + param.viewMode.toLowerCase();
                break;
            default:
                break;
        }
        return msg;
    }
}

class WfmConfirmMsg {
    public title: string;
    public msg: string;


}


