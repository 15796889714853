import { Component, OnInit, Renderer2 } from "@angular/core";
import { IHeaderAngularComp } from "ag-grid-angular";
import { Store } from "@ngrx/store";
import { SortingCleared } from "src/app/store/actions";

@Component({
  selector: "esp-table-header",
  templateUrl: "./esp-table-header.component.html",
  styleUrls: ["./esp-table-header.component.scss"]
})
export class EspTableHeaderComponent implements OnInit, IHeaderAngularComp {
  params: any;
  public currentSortOrder = "";
  colId: string;

  public static headers:Map<string,EspTableHeaderComponent> = new Map<string,EspTableHeaderComponent>();

  constructor(private renderer: Renderer2, private store: Store<any> ) {

  }

  ngOnInit() { }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.currentSortOrder = SortOrder.ASC;
    } else if (this.params.column.isSortDescending()) {
      this.currentSortOrder = SortOrder.DESC;
    } else {
      this.currentSortOrder = SortOrder.NONE;
    }
  }

  onSortRequested(order, event) {
    if(this.params.context && !this.params.context.isLoading())
      this.params.setSort(order, event.shiftKey);
  }

  toggleSortOrder(event) {
    if (!this.params.enableSorting || (this.params.context && this.params.context.isLoading())) {
      return;
    }
    switch (this.currentSortOrder) {
      case SortOrder.ASC:
        this.currentSortOrder = SortOrder.DESC;
        break;
      case SortOrder.DESC:
        this.currentSortOrder = SortOrder.NONE;
        break;
      case SortOrder.NONE:
        this.currentSortOrder = SortOrder.ASC;
        break;
    }
    this.params.setSort(this.currentSortOrder, event.shiftKey);
    if(this.currentSortOrder == SortOrder.NONE)
      this.store.dispatch(new SortingCleared(""));
    else
      this.clearOtherSorting();


  }

  public static setSortOrder(colId:string, order:string){
    const headerComp: EspTableHeaderComponent = EspTableHeaderComponent.headers.get(colId);
    headerComp.currentSortOrder = order;

  }

  clearOtherSorting() {
    EspTableHeaderComponent.headers.forEach((value: EspTableHeaderComponent, key: string) => {
      if (this.colId.indexOf(key) < 0) {
        const headerComp: EspTableHeaderComponent = value;
        headerComp.currentSortOrder = SortOrder.NONE;
      }
    });

  }

  public static clearSortOrder() {
    EspTableHeaderComponent.headers.forEach((value: EspTableHeaderComponent) => {
      const headerComp: EspTableHeaderComponent = value;
      headerComp.currentSortOrder = SortOrder.NONE;
    });
  }

  public static getSingleColumnSortModel(colId: string, sort: string, colIdArr: string[]) {
    const result = [];
    colIdArr.forEach((singleColId) => {
      if (singleColId === colId) result.push({ colId, sort });
      else result.push({ colId: singleColId, sort: "" });
    });
    return result;
  }

  agInit(params) {
    EspTableHeaderComponent.headers.set(params.column.colDef.colId, this);
    this.params = params;
    this.colId = params.column.colDef.colId ? params.headerPrefix + params.column.colDef.colId : "";
    this.renderer.listen(this.params.column, "sortChanged", this.onSortChanged.bind(this));
    this.onSortChanged();
  }


  getSortOrderStatus(order: SortOrder){
    return (this.currentSortOrder == order)? "active": "inactive";
  }
  refresh(params: import("ag-grid-community").IHeaderParams): boolean {

    return false;
  }
  afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {

  }
}

enum SortOrder{
  ASC = "asc", DESC = "desc", NONE=""
}
