
export class ViewLogHelper {

    public static replaceParamsFromMessage(message: string, args: any): string {
        if (message && args) {
            let logMessage: string = message;
            let checkForMoreArgsToReplace: boolean = false;
            let indexParam = 0;
            do {
                const param = "{" + indexParam + "}";
                if (logMessage.indexOf(param) === -1) checkForMoreArgsToReplace = false;
                else {
                    if (args[indexParam] && args[indexParam].value) {
                        logMessage = logMessage.replace(param, args[indexParam].value);
                        checkForMoreArgsToReplace = true;
                        indexParam++;
                    } else {
                        // If total arguments are not available, undefined.
                        return undefined;
                    }
                }
            } while (checkForMoreArgsToReplace);
            return logMessage;
        } else {
            return undefined;
        }

    }

  public static replaceParamsFromMessageWithNewlines(message: string, args: any): string {
    if (message && args) {
      let logMessage: string = message;
      let checkForMoreArgsToReplace: boolean = false;
      let indexParam = 0;
      do {
        const param = "{" + indexParam + "}";
        if (logMessage.indexOf(param) === -1) checkForMoreArgsToReplace = false;
        else {
          if (args[indexParam] && args[indexParam].value) {
            let value = args[indexParam].value;
            if (typeof value === 'string' && /^\[.*\]$/.test(value)) {
              value = "\n\t" + ViewLogHelper.convertStringToArray(value).join("\n\t");
            } else if (Array.isArray(value)) {
              value = "\n\t" + value.join("\n\t");
            }
            logMessage = logMessage.replace(param, value);
            checkForMoreArgsToReplace = true;
            indexParam++;
          } else {
            return undefined;
          }
        }
      } while (checkForMoreArgsToReplace);
      return logMessage;
    } else {
      return undefined;
    }
  }

  public static convertStringToArray(input: string): any[] {
    // Remove the leading and trailing square brackets
    const trimmedInput = input.slice(1, -1);

    // Split the string by commas and trim each element
    return trimmedInput.split(',').map(item => item.trim());
  }

}
