import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TranslateService} from '@ngx-translate/core';


export class SelectItem {

  label: string;
  value: string;
  styleClass?:string;
  object: any = null;

}

@Component({
  selector: "wfm-select",
  templateUrl: "./wfm-select.component.html",
  styleUrls: ["./wfm-select.component.scss"]
})
export class WfmSelectComponent implements OnInit {
  @Input() required = true;
  @Input() label = null;
  @Input() placeHolder = null;
  @Input() inlineStyleObject = {};
  @Input() wfmAutoComplete = "on";
  @Input() selectId = null;
  @Input() selectName = null;
  @Input() items: Array<SelectItem>;
  @Input() selectedItem: SelectItem;
  @Input() disabled: boolean;
  @Input() showLoading: boolean;
  @Output() wfmSelectEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectOnBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectOnChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("wfmSelect") ngSelect: NgSelectComponent;

  constructor(private cd: ChangeDetectorRef, private translateSrv: TranslateService) {
    this.items = new Array<SelectItem>();
  }

  ngOnInit() {
  }

  updateValue() {
    var selectedValue = this.ngSelect.selectedItems[0].value;
    this.wfmSelectEvent.emit(selectedValue);
  }

  clear() {
    //debugger;
    this.selectedItem = null;
  }

  showLoadingIcon(sl: boolean) {
    this.showLoading = sl;
    this.cd.detectChanges();

  }

  displayText(item: SelectItem) {
    if (item && item.value && item.label) {
      return item.label;
    } else {
      return item;
    }

  }
}
