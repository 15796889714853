// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-message {
  color: #d82132;
}

.show-error {
  display: block;
}

.invalid-input {
  border: solid 1px #d82132;
}

.valid-input {
  border: solid 1px rgb(96, 189, 96);
}

.input-label {
  color: #8c95a5;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

input {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  color: #333;
}
input::-ms-clear {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wfm-textbox/wfm-textbox.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EAEE,cAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,kCAAA;AAAF;;AAGA;EACE,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAAF;;AAGA;EACE,2CAAA;EACA,eAAA;EACA,WAAA;AAAF;AAEE;EACE,aAAA;AAAJ","sourcesContent":[".error-message {\n  color: #d82132;\n}\n\n.show-error {\n\n  display: block;\n}\n\n.invalid-input {\n  border: solid 1px #d82132;\n}\n\n.valid-input {\n  border: solid 1px rgb(96, 189, 96);\n}\n\n.input-label {\n  color: #8c95a5;\n  font-size: 11px;\n  font-weight: bold;\n  text-transform: uppercase;\n}\n\ninput {\n  font-family: \"Open Sans\", Arial, sans-serif;\n  font-size: 14px;\n  color: #333;\n\n  &::-ms-clear {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
