import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wfm-esp-loading-rows-overlay',
  templateUrl: './loading-rows-overlay.component.html',
  styleUrls: ['./loading-rows-overlay.component.css']
})
export class LoadingRowsOverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  agInit(): void {
  }

}
