/* istanbul ignore file */

import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from "@angular/core";

import {CheckboxListItem} from "../../../components/checkbox-list/checkbox-list.component";
import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import Drilldown from "highcharts/modules/drilldown";
// Load the exporting module.
import OfflineExporting from "highcharts/modules/offline-exporting";
import DragablePoints from "highcharts/modules/draggable-points.src";
import Exporting from "highcharts/modules/exporting";
import {DetailViewMode} from "../plan-detail/plan-detail.component";
import HighchartsCustomEvents from "highcharts-custom-events";
import {ParamsConfig} from "../common/validator-factory";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {ResizeAwareService} from '../../../services/resizeaware.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { getLocaleNumberSymbol, NumberSymbol } from "@angular/common";

if(HighchartsCustomEvents){
  HighchartsCustomEvents(Highcharts);
}

More(Highcharts);
Drilldown(Highcharts);
// Initialize exporting module.
Exporting(Highcharts);
OfflineExporting(Highcharts);
DragablePoints(Highcharts);

export enum SidebarVisibility{
  Hidden="Hidden",
  Visible="Visible",
  Float="Float"
}
// fix for WFM-92983
Highcharts.wrap(Highcharts.Pointer.prototype, "normalize", function (proceed, event, chartPosition) {

  var e:any = proceed.call(this, event, chartPosition);

  var element = this.chart.container;
  let rect = element.getBoundingClientRect();

  //console.log("clientX:",e.clientX,"left:",rect.left);
  e.chartX = e.clientX-rect.left;

  return e;
});

Highcharts.Pointer.prototype.reset = function () {
  return undefined;
};

Highcharts.setOptions({
  lang:{
    resetZoomTitle:"Reset zoom level 1:1"
  }
})

//ignore
@Component({
  selector: "plan-detail-chart-v2",
  templateUrl: "./plan-detail-chart-v2.component.html",
  styleUrls: ["./plan-detail-chart-v2.component.scss"],
  //animations
})
export class PlanDetailChartV2Component implements OnInit, AfterViewChecked,OnDestroy {
  @ViewChild("chartEl") chartEl: ElementRef;
  @Input() chartId = null;
  @Input() displayFooter = true;
  @Input() displayHeader = true;
  @Input() isMultiChart = false;
  @Input() locale = "en-US";

  @Output() pointHovered: EventEmitter<any> = new EventEmitter<any>();
  @Output() pointOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() setExtremes: EventEmitter<any> = new EventEmitter<any>();
  @Output() paramsListChanged: EventEmitter<any> = new EventEmitter<any>();
  private ignoreHighlight=false;
  private ignoreExtremes=false;
  private ignoreRemoveHighlight: boolean;
  private refreshTimer = null;
  private unsubscribe$: Subject<void> = new Subject();

  @Input() set rowData(rows) {
    this._rowData = rows;

    this.updateParamsList();
    this.paramsListChanged.emit(this.paramsList);

    //clearTimeout(this._drildownTimer);
    //this._drildownTimer = setTimeout(() => {
      this.updateChart();
    //}, 300);

  }

  @Input() set isLoading(value){
    if(!this.chart) return;

    if(value){
      this.chart.showLoading();

    }else{
      this.chart.hideLoading();
    }
  }



  @Input() set columnDefs(columns) {
    this._columnDefs = columns;
    //clearTimeout(this._drildownTimer);
    //this._drildownTimer = setTimeout(() => {
      this.updateChart();
    //}, 300);
  }
  @Input() isVisible = false;

  @Input() set viewMode(viewMode: DetailViewMode) {
    this._viewMode = viewMode;
    this._shouldResetZoom=true;
  }

  @Input() set contactType(ctMU){
    if(ctMU==="MU" && this._contactType!="MU"){
      this._contactType="MU";
      this.updateChart();
    }else if(ctMU=="CT" && this._contactType!="CT"){
      this._contactType="CT";
      this.updateChart();
    }
  }

  get contactType(){
    return this._contactType;
  }

  get viewMode(){
    return this._viewMode;
  }

  @Input() gridContext;
  @Input() sidebar:SidebarVisibility=SidebarVisibility.Visible;

  @Output() drillDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() rollUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() displayParams: EventEmitter<any> = new EventEmitter<any>();


  startMax = 0;
  _rowData: any = null;
  _columnDefs: any = null;
  _colDefsFlat:any = null;
  _viewMode: DetailViewMode;
  _shouldResetZoom = false;
  //_drildownTimer:any = 0;
  _colors = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
  _rowsParamMap = {};
  _skipAnimation=false;
  _hoveredPoint = null;
  _yaxis=0;
  _isTranslationReady=false;
  chart = null;
  Highcharts = Highcharts; // required
  chartConstructor = "chart"; // optional string, defaults to "chart"
  updateFlag = false; // optional boolean
  paramsList: CheckboxListItem[] = [];
  _contactType="CT";
  lastExtremesSet=0;
  isDragging=false;
  selectedParams={
    "CT":{
      "CONTACTS":true
    },
    "MU":{
      "ACTUAL_FTE_rollup":true
    }
  };
  primaryAxisParams={
    "CT":{

    },
    "MU":{

    }
  }

  chartOptions: any;


  constructor(private modalService: NgbModal,private translateSrv: TranslateService,private resizeAwareService:ResizeAwareService) {
    this.chartCallback = this.chartCallback.bind(this);

    translateSrv.get([
      "chart.btn.chart.menu",
      "plan.chart.resetZoom",
      "plan.chart.resetZoomTitle",
      "plan.chart.exitFullscreen",
      "plan.chart.viewFullscreen",
      "plan.chart.printChart",
      "plan.chart.downloadJPEG",
      "plan.chart.downloadPDF",
      "plan.chart.downloadPNG",
      "plan.chart.downloadSVG",
      "plan.chart.loading",
    ])
      .subscribe((keys=>{
        Highcharts.setOptions({
          lang: {
            numericSymbols: null, // for language reasons, we are disabling shortening
            contextButtonTitle:keys["chart.btn.chart.menu"],
            exitFullscreen:keys["plan.chart.exitFullscreen"],
            viewFullscreen:keys["plan.chart.viewFullscreen"],
            resetZoom:keys["plan.chart.resetZoom"],
            resetZoomTitle:keys["plan.chart.resetZoomTitle"],
            printChart:keys["plan.chart.printChart"],
            downloadJPEG:keys["plan.chart.downloadJPEG"],
            downloadPDF:keys["plan.chart.downloadPDF"],
            downloadPNG:keys["plan.chart.downloadPNG"],
            downloadSVG:keys["plan.chart.downloadSVG"],
            loading:keys["plan.chart.loading"]
          }
        });
        this._isTranslationReady=true;


    }));




  }

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        decimalPoint: getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal),
        thousandsSep: getLocaleNumberSymbol(this.locale, NumberSymbol.Group),
      }
    });

    this.loadParamSelection();
    this.initChartOptions();
    let self = this;
    this.resizeAwareService.Resized.pipe(takeUntil(this.unsubscribe$)).subscribe(()=>{
      if(this.chart){
        this.chart.reflow();
      }
    });
  }

  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    //clearTimeout(this._drildownTimer);
  }
  private initChartOptions() {
    this.chartOptions= {
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      navigation: {
        buttonOptions: {
          x:-8,
          y:0
        }
      },
      chart: {
        reflow:true,
        zoomType: "x",
        panning: true,
        panKey: "shift",
        marginLeft: 130,
        marginRight: 130,
        marginTop:40,
        resetZoomButton: {
          position: {
            // relativeTo:'plotBox',
            // align: 'right', // by default
            // verticalAlign: 'top', // by default
            x: 0,
            y: -35
          }
        }
        /*events: {
          drilldown: this.onDrillDown.bind(this)
        }*/
      },
      scrollbar: {
        enabled: true
      },
      type: "line",
      xAxis: {
        crosshair: {
          width:2,
          color:"#c1c1c1"
        },
        categories: [],

        labels: {
          events: {
            click: this.onDrillDown.bind(this)
          }
        },
        events:{
          afterSetExtremes:function(e) {

          }
        }
      },
      yAxis: [{
        type: "number",
        showEmpty:false,
        title:{
          style:{
            color:"rgb(0,124,190)"
          },
          text:this.translateSrv.instant("chart.yAxis.values")
        },
        labels:{
          style:{
            color:"rgb(0,124,190)",
            fontSize:"12px"
          }
        }
      },
        {
          type: "number",
          opposite: true,
          showEmpty:false,
          title:{
            style:{
              color:"rgb(249,163,70)"
            },
            text:this.translateSrv.instant("chart.yAxis.values")
          },
          labels:{
            style:{
              color:"rgb(249,163,70)",
              fontSize:"12px"
            }
          }
        }],
      tooltip: {
        animation:true,
        snap: 0,
        //hideDelay:200,
        //distance:5,
        /*formatter: function (tooltip) {
          debugger;
          return "The value for <b>" + this.x +
            "</b> is <b>" + this.y + "</b>";
        },*/
        useHTML: true,
        style: {
          pointerEvents: "auto"
        },
        shared: true,
      },
      plotOptions: {
        line: {
          turboThreshold: 2000,
          stickyTracking:false
        },
        series: {
          cursor: "pointer",
          stickyTracking: false,
          turboThreshold: 2000,
          marker: {
            lineWidth: 1
          },
          states:{
            inactive:0.5
          },
          point: {
            events: {
              mouseOver: (e)=>{
                //console.log("Hover", this.chartId);
                if(this.isDragging==false){
                  this._hoveredPoint = e.target;
                }
                if(this.ignoreHighlight){
                  //console.log("Ignoring highlight");
                  this.ignoreHighlight=false;
                  return;
                }

                //console.log("Hovered Emitted", this.chartId);
                this.pointHovered.emit(e);
              },
              mouseOut:(e)=>{
                //console.log("Blur", this.chartId);
                if(this.ignoreHighlight){
                  this.ignoreHighlight=false;
                  return;
                }
                //console.log("Blur Emitted", this.chartId);
                this.pointOut.emit(e);
              },
              touchstart:(e)=>{
                //console.log("Touch start");
                //this.onPointDragStart(e);
              },
              dragStart:  (e)=> {
                this.onPointDragStart(e);
              },
              /*touchmove:(e)=>{
                console.log("Touch move")
                if(this.isDragging==false){
                  this._hoveredPoint = e.target;
                }else{
                  this.onPointDrag(e);
                }

              },*/
              drag:  (e)=> {
                this.isDragging=true;
                this.onPointDrag(e);

              },
              drop: (e)=>{
                this.isDragging=false;
                this.onPointDrop(e);
              },
              dblclick:(e)=>{
                //console.log(this._hoveredPoint);
                //this.showEditValueDlg(this._hoveredPoint);
              }
            }
          },
        }
      },

      series: [],
      exporting: {
        libURL:"assets/js-libs"
      }
    };
  }
  private chartCallback(chart) {
    //exporting a chart causes it to be called with a temp chart
    if(this.chart)
      return;


    //setTimeout(()=>{
      this.chart = chart;
      this.updateChart();
    //},3000)


  }

  private updateParamsList() {
    let rows=this._rowData;
    if (!rows) {
      return this.paramsList = [];
    }

    // removing parameters with not valid data.
    rows = rows.filter((row) => (row.metadata && row.metadata.paramName && !row.isRollupHeader));

    //set default
    rows.forEach((row)=>{
      if(this.primaryAxisParams[this.contactType][this.getParamName(row)]===undefined){
        this.primaryAxisParams[this.contactType][this.getParamName(row)]=true;
      }
    })

    this.paramsList = this.distinctRows(rows).map((row) => {
      return {
        display: row.plan_label_param,
        isChecked: this.selectedParams[this.contactType][this.getParamName(row)]==true,
        isPrimary: this.primaryAxisParams[this.contactType][this.getParamName(row)]===true,
        data:row,
        isNested:row.rowChild,
        isRollup: row.entityInfo.isRollup
      };
    });
  }
  private distinctRows(rows){
    const seen = new Set();

    return rows.filter(row => {
      let paramName = this.getParamName(row);
      if (seen.has(paramName)) {
        return false;
      } else {
        seen.add(paramName);
        return true;
      }
    });
  }
  private onDrillDown(e) {
    if(this.gridContext.canDrillDown && this.gridContext.canDrillDown()) {
      this.drillDown.emit();
    }
  }

  public onRollUp(){
    this.rollUp.emit();
  }
  public canRollUp(){
    return this._viewMode!=DetailViewMode.Monthly
  }
  private onPointClick() {
    //this.drillDown.emit();
  }

  private getColumnDefinitionsFlat() {
    let columnDefsFlat = [];
    //should replace this with flatMap
    this._columnDefs.forEach((colDef) => {
      if (colDef.children) {//it"s a week/day view
        colDef.children.forEach((colDefChild) => {
          columnDefsFlat.push(colDefChild);
        });
      } else {
        columnDefsFlat.push(colDef);
      }
    });
    if(this.contactType=="CT"){
      return columnDefsFlat.slice(1, -2); //remove first column, average and total
    }else{
      return columnDefsFlat.slice(2, -2); //remove group column, parameter name column, average and total
    }

  }

  private clearSeries() {
    let chart = this.chart;
    for (let i = chart.series.length - 1; i >= 0; i--) {
      chart.series[i].remove(false);
    }
  }

  private setCategories(columnDefsFlat){
    let categories=[];
    columnDefsFlat.forEach((column, colIndex) => {
      categories.push(column.headerNameFull);
    });
    this.chart.xAxis[0].setCategories(categories, false);
  }

  private addCTRow(row,columnDefsFlat,isPrimaryAxis) {
    let isDraggable = this.gridContext.cellEditable({data:row});



    let rowIndex = this._rowData.indexOf(row);

    this._rowsParamMap[row.metadata.paramName]=row;

    let series = {
      name: row.plan_label_param,
      id: row.metadata.paramName,
      data: [],
      type: "line",
      stickyTracking:false,
      yAxis: isPrimaryAxis?0:1,
      dashStyle:isPrimaryAxis?"Solid":"Dash",
      dragDrop: {
        draggableY:isDraggable,
        draggableX:false,
        liveRedraw:true,
        dragPrecisionY:0.5
      },
      tooltip:{
        valueDecimals:row.metadata.decimal
      },
      cursor:isDraggable?"pointer":"default",
      color:this._colors[rowIndex%this._colors.length],
      marker: {
        symbol: "circle"
      },
      metadata:{
        row
      }
    };
    columnDefsFlat.forEach((column, colIndex) => {
      let value = row[column.field] ? row[column.field] : null;
      if (value == "???") {
        value = null;
      }
      series.data.push({
        name: column.headerNameFull,
        y: parseFloat(value),
        metadata: row.metadata.cellmetadata[column.field]
      });
    });
    this.chart.addSeries(series, false, true);
  }
  private addMURows(paramRow,columnDefsFlat) {
    let paramName = paramRow.metadata.paramName;
    let isPlanRollup = paramRow.entityInfo.isRollup;
    let hasMUrows = this._rowData.some((row)=>row.entityInfo.isRollup);
    if(hasMUrows==false){ //when switching from CT to MU, we have the CT rows while MU is loading.
      return;
    }
    this._rowData.forEach((row)=>{
      if(row.metadata.paramName === paramName && row.entityInfo.isRollup==isPlanRollup){
        this.addMURow(row,columnDefsFlat)
      }
    })
  }
  private addMURow(row,columnDefsFlat) {
    let isDraggable = this.gridContext.cellEditable({data:row});

    let rowIndex = this._rowData.indexOf(row);
    let muName = this.getMUName(row);
    let muId = this.getMUId(row);
    let seriesId = this.getSeriesId(row);
    this._rowsParamMap[seriesId] = row;

    let series = {
      name: muName,
      id: seriesId,
      data: [],
      type: "line",
      stickyTracking:false,
      yAxis: 0,
      dashStyle:"Solid",
      dragDrop: {
        draggableY:isDraggable,
        draggableX:false,
        liveRedraw:true,
        dragPrecisionY:0.5
      },
      tooltip:{
        valueDecimals:row.metadata.decimal
      },
      cursor:isDraggable?"pointer":"default",
      color:this._colors[muId%this._colors.length],
      marker: {
        symbol: "circle"
      },
      metadata:{
        row
      }
    };
    columnDefsFlat.forEach((column, colIndex) => {
      let value = row[column.field] ? row[column.field] : null;
      if (value == "???") {
        value = null;
      }
      series.data.push({
        name: column.headerNameFull,
        y: parseFloat(value),
        metadata: row.metadata.cellmetadata[column.field]
      });
    });
    this.chart.addSeries(series, false, true);
  }
  private setxAxisStyle(){
    let xAxisLabelStyle = {
      labels: {
        style: {
          color: "#0094D1",
          "text-decoration": "underline",
          cursor: "pointer"
        }
      }
    };

    if (this.gridContext.canDrillDown && this.gridContext.canDrillDown()==false) {
      xAxisLabelStyle.labels.style["color"] = "black";
      xAxisLabelStyle.labels.style["text-decoration"] = "none";
      xAxisLabelStyle.labels.style["cursor"] = "default";
    }
    this.chart.xAxis[0].update(xAxisLabelStyle, false);

  }
  private removeCTRow(row){
    let chart = this.chart;
    for (let i = chart.series.length - 1; i >= 0; i--) {
      if(chart.series[i].name==row.plan_label_param){
        chart.series[i].remove(false);
      }
    }
  }
  private removeMURows(row){
    let chart = this.chart;
    for (let i = chart.series.length - 1; i >= 0; i--) {
      if(chart.series[i].userOptions.id.startsWith(row.plan_label_param)){
        chart.series[i].remove(false);
      }
    }
  }
  public updateChart() {
    if (!this._columnDefs || this._columnDefs.length == 0 || !this._rowData || !this.chart || this.chartId===null) {
      return;
    }

    this.updateParamsList();
    this.paramsListChanged.emit(this.paramsList);

    this._colDefsFlat = this.getColumnDefinitionsFlat();
    this._rowsParamMap = {};

    this.chart.update({

      xAxis: {
        visible:this.displayFooter,
      },

      plotOptions: {
        line: {
          animation: !this._skipAnimation
        }
      }
    });

    this._skipAnimation=false;
    this.hideTooltips();
    this.clearSeries();

    if(this._shouldResetZoom){
      this.chart.zoom();
      this._shouldResetZoom=false;
    }

    this.setCategories(this._colDefsFlat);

    this.paramsList.forEach((checkBoxItem, rowIndex) => {
      if(checkBoxItem.isChecked){
        if(this.contactType=="CT"){
          this.addCTRow(checkBoxItem.data,this._colDefsFlat,checkBoxItem.isPrimary);
        }else{
          this.addMURows(checkBoxItem.data,this._colDefsFlat);
        }
        this.updateYAxisTitle(checkBoxItem.data);
      }
    });

    this.setxAxisStyle();

    this.chart.redraw();
    this.chart.reflow();
    this.chart.yAxis[0].setExtremes(null,null);
    this.chart.yAxis[1].setExtremes(null,null);
  }
  ngAfterViewChecked(){

  }

  onParamSelectionChange(item: CheckboxListItem) {
    let row = item.data;
    let primaryDataMax = this.chart.yAxis[0].dataMax;
    let secondaryDataMax = this.chart.yAxis[1].dataMax;

    if(this.contactType=="CT"){
      this.removeCTRow(row);
    }


    if(item.isChecked) {
      let columnDefsFlat = this.getColumnDefinitionsFlat();
      if(this.contactType=="CT"){
        this.addCTRow(row, columnDefsFlat,item.isPrimary);
      }else{
        this.clearSeries();
        this.addMURows(row, columnDefsFlat);
      }
      this.updateYAxisTitle(row);
    }

    this.selectedParams[this.contactType][this.getParamName(row)]=item.isChecked;
    this.primaryAxisParams[this.contactType][this.getParamName(row)]=item.isPrimary;
    this.saveParamSelection();

    this.chart.redraw();
    let newPrimaryDataMax = this.chart.yAxis[0].dataMax;
    let newSecondaryDataMax = this.chart.yAxis[1].dataMax;
    if(primaryDataMax!=newPrimaryDataMax || secondaryDataMax!=newSecondaryDataMax){
      this.chart.zoom();
    }
  }

  private loadParamSelection(){
    let localData = localStorage.getItem("ChartParamsV2-"+this.chartId);
    if(localData){
      this.selectedParams = JSON.parse(localData);
    }

    localData = localStorage.getItem("PrimaryChartParamsV2-"+this.chartId);
    if(localData) {
      this.primaryAxisParams = JSON.parse(localData);
    }
  }

  private saveParamSelection(){
    localStorage.setItem("ChartParamsV2-"+this.chartId,JSON.stringify(this.selectedParams));
    localStorage.setItem("PrimaryChartParamsV2-"+this.chartId,JSON.stringify(this.primaryAxisParams));
  }

  private onValueSet(e: any) {
    let colKey = this._colDefsFlat.filter((col)=>col.headerNameFull==e.target.category);
    let row = null;
    if(this.contactType==="CT"){
      row = this._rowData.find((row)=>row.metadata.paramName==e.target.series.userOptions.id);
    }else{
      row = this._rowData.find((row)=>e.target?.series.userOptions.id==this.getSeriesId(row))
    }

    this._skipAnimation=true;
    this.gridContext.setCustomValue(row,colKey[0].field,e.newPoint.y);
  }

  private getParamNameFromPoint(point: any) {
    return point.series.userOptions.metadata.row.metadata.paramName
  }

  private onPointDragStart(e){

    //console.log(e);
    if(!this._hoveredPoint || !this._hoveredPoint.series || !this._hoveredPoint.series.name)
      return;

    let seriesId = this._hoveredPoint.series.userOptions.id;
    let yAxisId = this.primaryAxisParams[this.contactType][seriesId] ? 0 : 1;
    //console.log(yAxisId);

    this.startMax = this.chart.yAxis[yAxisId].max;
    let {min,max} = this.chart.yAxis[yAxisId].getExtremes();

    //otherwise, if we have a line of zero, we wouldn't be able to drag it
    if(max-min<5){
      max+=3;
      min-=3;
    }
    this.chart.yAxis[yAxisId].setExtremes(min,max);
    /*this.chart.yAxis[0].update({
      tickInterval:tickInterval
    });*/

    let paramName = this.getParamNameFromPoint(this._hoveredPoint);
    let metadataMax = this._hoveredPoint.metadata.maxValue;
    let metadataMin = this._hoveredPoint.metadata.minValue;
    let isWorkload = this._hoveredPoint.metadata.workload;


    //if we have a different range for workload ct, we should use it.
    if(isWorkload && ParamsConfig[paramName+"_WORKLOAD"]){
      paramName+="_WORKLOAD";
    }

    if(metadataMax||metadataMin){
      this._hoveredPoint.series.options.dragDrop.dragMaxY=metadataMax?metadataMax:0;
      this._hoveredPoint.series.options.dragDrop.dragMinY=metadataMin?metadataMin:0;
    }else{
      if(ParamsConfig[paramName]){
        this._hoveredPoint.series.options.dragDrop.dragMinY=ParamsConfig[paramName].min;
        this._hoveredPoint.series.options.dragDrop.dragMaxY=ParamsConfig[paramName].max;
      }else{
        this._hoveredPoint.series.options.dragDrop.dragMinY=null;
        this._hoveredPoint.series.options.dragDrop.dragMaxY=null;
      }
    }
  }

  private onPointDrag(e){
    if(!this._hoveredPoint || !this._hoveredPoint.series || !this._hoveredPoint.series.name){
      return;
    }

    let seriesId = this._hoveredPoint.series.userOptions.id;
    let yAxisId = this.primaryAxisParams[this.contactType][seriesId] ? 0 : 1;

    let validator = this._rowsParamMap[e.target.series.userOptions.id].metadata.validator;
    let pointMetadata = e.target.metadata;

    if(validator.isValid(e.newPoint.y,pointMetadata)==false){
      e.newPoint.y=e.target.options.y;
      return;
    }

    let {min,max} = this.chart.yAxis[yAxisId].getExtremes();
    let currentMax = max;

    if(e.newPoint.y*1.05>currentMax){
      if(new Date().getTime() - this.lastExtremesSet>1000) { //we needed to throttle the set extreme call
        //console.log("Setting new extreme");                  //beacuse the y-axis was jumping while the user dragged points
        this.chart.yAxis[yAxisId].setExtremes(null, Math.max(currentMax * 1.2,currentMax +5));
        this.lastExtremesSet=new Date().getTime();
      }
    }

    if(this.isMultiChart==false){
      this.chart.tooltip.refresh(e.target);
    }

    if(currentMax>this.startMax) {
      this.startMax = currentMax;
      //e.newPoint.y*=1.1;
    }
  }

  private onPointDrop(e){
    if(!this._hoveredPoint || !this._hoveredPoint.series || !this._hoveredPoint.series.name){
      return;
    }

    let seriesId = this._hoveredPoint.series.userOptions.id;
    let yAxisId = this.primaryAxisParams[this.contactType][seriesId] ? 0 : 1;

    if(e.newPoint.y<e.origin.points[e.newPointId].y){
      //this.chart.zoom();
    }
    this.onValueSet(e);
    //console.log("DONE",e);
    let {min,max} = this.chart.yAxis[yAxisId].getExtremes();
    if(this.chart.xAxis[0].displayBtn)
    {
      this.chart.yAxis[yAxisId].setExtremes(min,max);
    }else{
      this.chart.yAxis[yAxisId].setExtremes(null,null);
    }
    this._hoveredPoint=null;
  }
  private getSeriesByName(name){
    return this.chart.series.find((s)=>s.name==name);
  }

  highlight($event: any) {

    //console.log($event);
    //console.log(this.getSeriesByName($event.target.series.name))
    //console.log(this.chart);
    //console.log("Highlight", this.chartId,$event);

    if(this.chart.series && this.chart.series.length>0) {
      let series = this.chart.series[0];

      let point = series.points.find((p) => p.category == $event.target.category);
      let xExtremes = this.chart.xAxis[0].getExtremes();
      if(point.x>=Math.round(xExtremes.min) && point.x<=Math.round(xExtremes.max)){
        this.ignoreHighlight = true;
        point.onMouseOver();
      }
    }

  }
  removeHighlight($event: any) {

    //console.log($event);
    //console.log(this.getSeriesByName($event.target.series.name))
    //console.log(this.chart);

    //console.log("Remove Highlight", this.chartId);
    if(this.chart.series && this.chart.series.length>0) {
      let series = this.chart.series[0];
      let point = series.points.find((p) => p.name == $event.target.name);
      this.ignoreHighlight = true;
      point.onMouseOut();

      this.chart.tooltip.hide(0);
      series.yAxis.hideCrosshair();
      series.xAxis.hideCrosshair();
    }
  }
  hideTooltips(){
    this.chart.tooltip.hide(0);
  }
  syncExtremes(e) {
    //console.log(e);

    this.ignoreExtremes=true;

    if (this.chart.xAxis[0].setExtremes) { // It is null while updating
      this.chart.xAxis[0].setExtremes(
        e.min,
        e.max,
        undefined,
        false,
        {trigger: 'syncExtremes'}
      );
    }
  }
  onDisplayParams(){
    this.displayParams.emit(this.chartId);
  }
  onChartMouseLeave(){
    this.chart.tooltip.shared = true;
  }
  onChartMouseEnter() {
    this.chart.tooltip.shared = false;
  }
  getParamName(row){
    return row.entityInfo.isRollup ? row.metadata.paramName + "_rollup":row.metadata.paramName
  }
  getMUId(row){
    let object = row.entityInfo.object;
    if(object){
      return object.id;
    }else{
      return -1;
    }
  }
  getMUName(row){
    let object = row.entityInfo.object;
    if(object){
      return `${object.id} ${object.name}`;
    }else{
      return row.entityInfo.label;
    }
  }
  getSeriesId(row){
    return row.metadata.paramName + "_" + row.entityInfo.value.oid;
  }
  updateYAxisTitle(row) {
    if(this.chartOptions && this.chart) {
      let text = ""
      // Modify the chartOptions object with the new title
      if(this.contactType=="CT"){
        text = this.translateSrv.instant("chart.yAxis.values");
      }else{
        text = this.translateSrv.instant("plan.details.mu.view") + " - " + row.plan_label_param;
      }

      // Update the Highcharts instance with the new options
      //this.chart.update(this.chartOptions, true);  // The second parameter "true" means the update will be redrawn
      this.chart.yAxis[0].update({
        title: {
          text
        }
      });
    }
  }
}
