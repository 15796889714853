// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=number] {
  -moz-appearance: textfield;
}

input.numeric-editor:focus {
  box-shadow: none;
  /*border: 1px solid #226a97;*/
  outline: none;
}

input.numeric-editor {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  padding: 0px 7px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.popover {
  font-family: "Open Sans Regular";
  background-color: black;
  padding: 20px 20px 22px 20px;
}
.popover .popover-header {
  background-color: transparent;
  color: #fff;
  border-bottom: none;
  padding: 12px 14px 10px;
}
.popover .popover-body {
  padding: 0;
}
.popover p {
  color: white;
}
.popover .button-response {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
.popover .button-response button {
  min-width: 70px;
  height: 30px;
  line-height: 50%;
  margin-left: 0;
}
.popover.bs-popover-top .arrow::after {
  border-top-color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/common/cell-editors/numeric/numeric-editor.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ;;AAEA;EACE,gBAAA;EACA,6BAAA;EACA,aAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AAEF;;AACA;;EAEI,wBAAA;AAEJ;;AAEA;EACI,gCAAA;EACA,uBAAA;EACA,4BAAA;AACJ;AAAI;EACI,6BAAA;EACA,WAAA;EACA,mBAAA;EAEA,uBAAA;AACR;AACI;EACE,UAAA;AACN;AACI;EACE,YAAA;AACN;AACI;EACE,aAAA;EACA,qBAAA;EACA,yBAAA;AACN;AAAM;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;AAER;AAGQ;EACE,uBAAA;AADV","sourcesContent":["input[type='number'] {\n    -moz-appearance:textfield;\n}\n\ninput.numeric-editor:focus{\n  box-shadow: none;\n  /*border: 1px solid #226a97;*/\n  outline: none;\n}\ninput.numeric-editor{\n  width: 100%;\n  height: 100%;\n  border: none;\n  border-radius: 0;\n  padding: 0px 7px;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n}\n\n///deep/\n.popover {\n    font-family: 'Open Sans Regular';\n    background-color: black;\n    padding: 20px 20px 22px 20px;\n    .popover-header{\n        background-color: transparent;\n        color:#fff;\n        border-bottom:none;\n\n        padding: 12px 14px 10px;\n    }\n    .popover-body {\n      padding: 0;\n    }\n    p {\n      color: white;\n    }\n    .button-response {\n      display: flex;\n      flex-flow: row nowrap;\n      justify-content: flex-end;\n      button {\n        min-width: 70px;\n        height: 30px;\n        line-height: 50%;\n        margin-left: 0;\n      }\n    }\n    &.bs-popover-top {\n      .arrow {\n        &::after {\n          border-top-color: black;\n        }\n      }\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
