import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Subject} from "rxjs";
import {Actions, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {map, takeUntil} from "rxjs/operators";
import {
  AppActionTypes,
  DeactivateApiCredential,
  DeactivateApiCredentialFailed,
  DeactivateApiCredentialSuccess,
  GenerateApiCredential,
  GenerateApiCredentialFailed,
  GenerateApiCredentialSuccess,
  GetApiCredential,
  GetApiCredentialFailed,
  GetApiCredentialSuccess
} from "src/app/store/actions";
import {ApiCredential, ApiCredentialResponse, ApiCredentialStatus} from 'src/app/models/api-credential';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'api-credential',
  templateUrl: './api-credential.component.html',
  styleUrls: ['./api-credential.component.scss'],
  providers: [MessageService]
})
export class ApiCredentialComponent implements OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  createdTime: string;
  status: ApiCredentialStatus;
  accessKey: string;
  secretKey: string;
  apiCredOid: string;

  _isLoading = true;
  _isGenerated = false;
  _errorMessage:string;
  _showErrorMessage = false;
  _showGenerateWarningMessage = false;

  copy = require('clipboard-copy');
  _copyRef = this.copy;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<any>,
    private action$: Actions,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.initHandlers();
    this.store.dispatch(new GetApiCredential(""));
  }

  initHandlers() {
    this.action$
      .pipe(
        ofType(AppActionTypes.GetApiCredentialSuccess),
        map((action: GetApiCredentialSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.getApiCredentialSuccessHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GetApiCredentialFailed),
        map((action: GetApiCredentialFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.getApiCredentialFailedHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GenerateApiCredentialSuccess),
        map((action: GenerateApiCredentialSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.generateApiCredentialSuccessHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.GenerateApiCredentialFailed),
        map((action: GenerateApiCredentialFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.generateApiCredentialFailedHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.DeactivateApiCredentialSuccess),
        map((action: DeactivateApiCredentialSuccess) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.deactivateApiCredentialSuccessHandler.bind(this));

    this.action$
      .pipe(
        ofType(AppActionTypes.DeactivateApiCredentialFailed),
        map((action: DeactivateApiCredentialFailed) => action.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.deactivateApiCredentialFailedHandler.bind(this))
  }

  getApiCredentialSuccessHandler(payload: ApiCredentialResponse) {
    let data: ApiCredential = payload?.apiCredentials[0];
    this.status = data?.status;
    this.createdTime = data?.createdTime;
    this.accessKey = data?.accessKey ? this.maskValue(data.accessKey) : undefined;
    this.secretKey = data?.secretKey ? this.maskValue(data.secretKey) : undefined;
    this.apiCredOid = data?.oid;
    if ( this.status == ApiCredentialStatus.DEACTIVATION_FAILED ) {
      this._showErrorMessage = true;
      this._errorMessage = "api-credentials.deactivate.error.msg";
    }
    else {
      this._showErrorMessage = false;
      this._errorMessage = undefined;
    }
    this._isLoading = false;
  }

  private maskValue(val: string) {
    return "********-****-****-****-********" + val;
  }

  getApiCredentialFailedHandler(payload: any) {
    this.dismiss({retrieveError: true});
  }

  generateApiCredentialSuccessHandler(payload: ApiCredential) {
    this.status = payload.status;
    this.createdTime = payload.createdTime;
    this.accessKey = payload.accessKey;
    this.secretKey = payload.secretKey;
    this.apiCredOid = payload.oid;
    this._isLoading = false;
    this._isGenerated = true;
    this._showGenerateWarningMessage = true;
    this._showErrorMessage = false;

    let successMsg = this.translateService.instant("api-credentials.toast.generate.success");
    this.messageService.add({ severity: "success", detail: successMsg});
  }

  generateApiCredentialFailedHandler(payload: any) {
    this._isLoading = false;
    this._isGenerated = false;
    this.status = undefined;
    this.createdTime = undefined;
    this.accessKey = undefined;
    this.secretKey = undefined;
    this.apiCredOid = undefined;
    this._showGenerateWarningMessage = false;

    let failureMsg = this.translateService.instant("api-credentials.toast.generate.failure");
    this.messageService.add({ severity: "error", detail: failureMsg});
  }

  deactivateApiCredentialSuccessHandler(payload: ApiCredential) {
    this._isLoading = false;
    this._isGenerated = false;
    this.status = payload.status;
    this.accessKey = this.maskValue(payload.accessKey);
    this.secretKey = this.maskValue(payload.secretKey);
    this.createdTime = payload.createdTime;
    this._showGenerateWarningMessage = false;
    this._showErrorMessage = false;

    let successMsg = this.translateService.instant("api-credentials.toast.deactivate.success");
    this.messageService.add({ severity: "success", detail: successMsg});
  }

  deactivateApiCredentialFailedHandler(payload: any) {
    // status and createdTime stay as they are
    this._isLoading = false;
    this._isGenerated = false;
    this._showGenerateWarningMessage = false;
    this.accessKey = this.trimAndMask(this.accessKey);
    this.secretKey = this.trimAndMask(this.secretKey);

    let failureMsg = this.translateService.instant("api-credentials.toast.deactivate.failure");
    this.messageService.add({ severity: "error", detail: failureMsg});
  }

  trimAndMask(val: string) {
    if (val?.trim().length >= 4) {
      return this.maskValue(val.substring(val.length - 4));
    } else {
      return "";
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete();
  }

  dismiss(error: any) {
    this.activeModal.dismiss(error);
    this._isLoading = false;
  }

  close() {
    this.activeModal.close();
    this._isLoading = false;
  }

  get showCopyButtons() {
    return this._isGenerated;
  }

  get generateButtonEnabled() {
    return !this._isLoading &&
      !this._isGenerated &&
      this.status != ApiCredentialStatus.ACTIVE &&
      this.status != ApiCredentialStatus.PENDING_ACTIVATION &&
      this.status != ApiCredentialStatus.PENDING_DEACTIVATION;
  }

  get deactivateButtonEnabled() {
    return !this._isLoading && (this.status == ApiCredentialStatus.ACTIVE || this.status == ApiCredentialStatus.PENDING_ACTIVATION);
  }

  get closeButtonEnabled() {
    return true;
  }

  generate() {
    this._isLoading = true;
    this.store.dispatch(new GenerateApiCredential(""));
  }

  deactivate() {
    this._isLoading = true;
    this.store.dispatch(new DeactivateApiCredential({oid: this.apiCredOid}));
  }

  get copyRef() {
    return this._copyRef;
  }

  async copyAccessKey() {
    try {
      await this.copyRef(this.accessKey);
      let successMsg = this.translateService.instant("api-credentials.toast.copy.access-key.success");
      this.messageService.add({severity: "success", detail: successMsg});
      return true;
    } catch (error) {
      let failureMsg = this.translateService.instant("api-credentials.toast.copy.failure");
      this.messageService.add({ severity: "error", detail: failureMsg});
      return false;
    }
  }

  async copySecretKey() {
    try {
      await this.copyRef(this.secretKey);
      let successMsg = this.translateService.instant("api-credentials.toast.copy.secret-key.success");
      this.messageService.add({severity: "success", detail: successMsg});
      return true;
    } catch (error) {
      let failureMsg = this.translateService.instant("api-credentials.toast.copy.failure");
      this.messageService.add({ severity: "error", detail: failureMsg});
      return false;
    }
  }

}
