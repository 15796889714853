import { UserData } from "../models/user";

export class StorageUtil {
    static setFeatures(data: string[]) {
        localStorage.setItem("features", JSON.stringify(data));
    }
    static setHasEntities(hasEntities: boolean) {
        localStorage.setItem("hasEntities", JSON.stringify(hasEntities));
    }
    static setUserData(userData: UserData) {
        localStorage.setItem("userData", JSON.stringify(userData));
    }
    public static getFeatures(): string {
        return localStorage.getItem("features");
    }
    public static hasEntities(): boolean {
        const hasEnt = localStorage.getItem("hasEntities");
        return hasEnt ? JSON.parse(hasEnt) : false;
    }

    public static clearLocalStorage() {
        localStorage.removeItem("userData");
        localStorage.removeItem("features");
        localStorage.removeItem("hasEntities");
        localStorage.removeItem("planSessions");
    }

    public static getUserData(): UserData {
        const userData = localStorage.getItem("userData");
        return userData ? JSON.parse(userData) : null;
    }

}