import { TranslateService } from "@ngx-translate/core";
import {PlanParam} from "./plan-param";

export class ParamsConfig {
  
  static ACTUAL_CONTACTS={min:0,max:99999999};
  
  static ACTUAL_AHT={min:0,max:99999.99};
  
};

export class ValidatorFactory {

  static getValidator(paramKey: string, param: any, translate: TranslateService, locale: string) {
    let validator: WfmValidator = new WfmValidator();
    
    validator.title = "validation.err.title";

    validator.isValid = (value: any) => {
      if(param.allowBlank && (value === "" || value == null)) return true;
      const valid = (value >= param.minValue && value <= param.maxValue);
      return valid;
    };    
    
    let min:string;
    let max:string;
    switch (paramKey) {
      case PlanParam.ACTUAL_FTE:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.actual_fte", {0: min, 1: max});
        break;

      case PlanParam.ASA:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.asa", {0: min, 1: max});
        break;

      case PlanParam.MS_EFFICIENCY:
        min = this.formattedValue(param.minValue, locale, 4);
        max = this.formattedValue(param.maxValue, locale, 4);
        validator.msg = translate.instant("validation.err.ms_efficiency", {0: min, 1: max});
        break;

      case PlanParam.CONTACTS:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.contacts", {0: min, 1: max});
        
        break;
      case PlanParam.ACTUAL_CONTACTS:
        validator.msg = "validation.err.actual_contacts";
        validator.isValid = (value: any) => {
          return value == null || value === "" || (value >= ParamsConfig.ACTUAL_CONTACTS.min && value <= ParamsConfig.ACTUAL_CONTACTS.max);
        };
        break;

      case PlanParam.OCCUPANCY:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.occ", {0: min, 1: max});
        break;

      case PlanParam.HOURLY_RATE:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.hourly.rate", {0: min, 1: max});
        break;

      case PlanParam.NEW_HIRE_FTE:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.new.hire.fte", {0: min, 1: max});
        break;

        case PlanParam.AHT:
          min = this.formattedValue(param.minValue, locale, 2);
          max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.aht", {0: min, 1: max});
        break;
      case PlanParam.ACTUAL_AHT:
        validator.msg = "validation.err.actual_aht";
        validator.isValid = (value: any) => {
          return value == null || value === "" || (value >= ParamsConfig.ACTUAL_AHT.min && value <= ParamsConfig.ACTUAL_AHT.max);
        }
        break;

      case PlanParam.BACKLOG:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.backlog", {0: min, 1: max});
        break;

      case PlanParam.SERVICE_LEVEL_TIME:
        min = this.formattedValue(param.minValue, locale, 0);
        max = this.formattedValue(param.maxValue, locale, 0);
        validator.msg = translate.instant("validation.err.slt", {0: min, 1: max});
        break;

      case PlanParam.SERVICE_LEVEL_PERCENT:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.slp", {0: min, 1: max});
        break;

      case PlanParam.ABS_ATTRITION:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.att.abs", {0: min, 1: max});
        break;

      case PlanParam.SHRINKAGE:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.shrinkage", {0: min, 1: max});
        break;
      case PlanParam.SHRINKAGE_SUB:
        min = this.formattedValue(param.minValue, locale, 2);
        max = this.formattedValue(param.maxValue, locale, 2);
        validator.msg = translate.instant("validation.err.shrinkage.sub", {0: min, 1: max});        
        validator.isValid = (value: any, cellMetadata) => {
          return value == null || value === "" || (value >= 0.00 && value <= parseFloat(cellMetadata.maxValue));
        };
        break;
      case PlanParam.MEAN_CONCURRENCY:
        min = this.formattedValue(param.minValue, locale, 1);
        max = this.formattedValue(param.maxValue, locale, 1);
        validator.msg = translate.instant("validation.err.mean.concurrency", {0: min, 1: max});
       
        break;
      default:
        break;
    }
    return validator;
  }

  static formattedValue(value: any, locale: string, precision: number){
    return Number(value).toLocaleString(locale, 
      {useGrouping: false, 
        minimumFractionDigits: precision, 
        maximumFractionDigits: precision});
  }
}

export class WfmValidator {
  public title: string;
  public msg: string;
  public isValid: (value: any, cellMetadata?) => boolean;
}
