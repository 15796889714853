import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";

@Component({
  selector: "breeze-button",
  templateUrl: "./breeze-button.component.html",
  styleUrls: ["./breeze-button.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class BreezeButtonComponent implements OnInit, AfterViewInit {

  @Input() isAutoFocus = false;
  @Input() isDisabled = false;
  @Input() isSecondary = false;
  @Input() isPrimary = false;
  @Input() isRed = false;
  @Input() buttonText = "Test";
  @Input() btnSizeClass = "btn-lg";
  @Input() showTooltip = true;
  @Input() tooltipPosition = "right";
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('button') button:ElementRef;

  constructor() {
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    if(this.isAutoFocus){
      this.button.nativeElement.focus();
    }
  }

  emitClickButton(event) {
    this.clicked.emit(event);
  }

}
