import {AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from "@angular/core";
import {FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, Subscription, Subject, fromEvent} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";

@Component({
  selector: "input-text",
  templateUrl: "./input-text.component.html",
  styleUrls: ["./input-text.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextComponent),
    multi: true
  }]
})
export class InputTextComponent implements OnInit, AfterViewInit, OnDestroy {
  showLabelForInput = false;
  private inputEventSubscription: Subscription;
  private searchTextSub: Subscription;

  @Input() formRef: FormGroup;

  @Input("value") _value;
  @Input() placeHolderText: string;
  @Input() inputId: string;
  @Input() fieldName;
  @Input() isDisabled = false;
  @Input() isError = false;
  @Input() showPlaceholderAsLegend = false;
  @Input() width = "28em";
  @Input() isSearchField = false;
  @Input() required = false;
  @Output() onTextChange = new EventEmitter();
  @Output() onTextChangeWithTimeout = new EventEmitter();
  @Output() onSearchReset = new EventEmitter();

  public isTyping = false;

  @ViewChild("textInputRef") textBoxEL: any;

  constructor() {
    this.value = "";
  }

  searchText:Subject<string> = new Subject();

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.inputEventSubscription = fromEvent(this.textBoxEL.nativeElement, "input").pipe(
      map((event: Event) => {this.isTyping = true; return (event.target as HTMLInputElement).value;}),
      debounceTime(500)
    ).subscribe((result => {this.isTyping = false; this.searchText.next(result);}));

    this.searchTextSub = this.searchText.pipe(distinctUntilChanged()).subscribe(data => {
        this.onTextChangeWithTimeout.emit(data);
    });
  }

  ngOnDestroy() {
    this.inputEventSubscription.unsubscribe();
    this.searchTextSub.unsubscribe();
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  get hasError() {
    if (this.formRef && this.formRef !== null) {
      return (!this.formRef.get(this.fieldName).valid && this.formRef.get(this.fieldName).touched)
        || this.formRef.get(this.fieldName).errors
        && (this.formRef.get(this.fieldName).touched || this.formRef.get(this.fieldName).dirty);
    }
    return false;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  onTextInput($event) {
    this.value = $event.currentTarget.value;
    // const _val = $event.currentTarget.value;
    this.showLabelForInput = this.value !== "";
    // this.value = _val;
    this.onTextChange.emit(this.value);
  }

  onClose(){
    if(!this.isDisabled && !this.isTyping){
      this.clear();
      this.resetValue();
      this.onSearchReset.emit();
    }
  }

  resetValue() {
    this.searchText.next(this.value);
  }

  clear() {
    this.value = "";
  }
}
