import {TranslateService} from "@ngx-translate/core";
import { OnInit, Directive } from "@angular/core";
import {Observable} from "rxjs";

@Directive()
export class BasePage implements OnInit {
  protected translationKeys = [""];
  protected translationStr: any = {};
  protected translationResults: Observable<any>;
  constructor(protected translate: TranslateService) {}

  ngOnInit() {
    this.translationResults = this.translate.get(this.translationKeys);
  }

  protected updateTranslationObj(newObj) {
    this.translationStr = JSON.parse(JSON.stringify(newObj));
  }
}
