// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historical-data-container {
  padding: 20px;
}

.page-header {
  flex: 0 0 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.table-list {
  border: 1px solid #c1bebe;
  background: white;
}

ag-grid-angular {
  width: 100%;
  height: 100%;
}

.ct-selector {
  width: 300px;
}

.datepicker-wrapper p-dropdown {
  min-width: 140px;
}

.datepicker-wrapper p-calendar {
  margin-left: 4px;
}

.datepicker-wrapper {
  display: flex;
  flex-direction: row;
}

:host ::ng-deep .datepicker-wrapper .p-component:disabled {
  background: #ececec;
}

@media screen and (max-width: 1200px) {
  .ct-selector {
    width: 200px;
  }
  .toolbar-vertical-item {
    min-width: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/historical-data/historical-data.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;;AAEA;EACE,cAAA;EACA,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;EACA,iBAAA;AAEF;;AAAA;EACE,WAAA;EACA,YAAA;AAGF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,gBAAA;AAKF;;AAHA;EACE,gBAAA;AAMF;;AAJA;EACE,aAAA;EACA,mBAAA;AAOF;;AALA;EACE,mBAAA;AAQF;;AANA;EACE;IACE,YAAA;EASF;EAPA;IACE,gBAAA;EASF;AACF","sourcesContent":["\n.historical-data-container{\n  padding: 20px;\n}\n.page-header{\n  flex: 0 0 50px;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n}\n.table-list{\n  border:1px solid #c1bebe;\n  background: white;\n}\nag-grid-angular{\n  width: 100%;\n  height: 100%;\n}\n.ct-selector{\n  width:300px\n}\n.datepicker-wrapper p-dropdown{\n  min-width: 140px;\n}\n.datepicker-wrapper p-calendar{\n  margin-left: 4px;\n}\n.datepicker-wrapper{\n  display: flex;\n  flex-direction: row;\n}\n:host ::ng-deep .datepicker-wrapper .p-component:disabled{\n  background: #ececec;\n}\n@media screen and (max-width: 1200px) {\n  .ct-selector{\n    width:200px\n  }\n  .toolbar-vertical-item{\n    min-width:150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
