import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "entity-type-field",
  templateUrl: "./entity-type-field.component.html",
  styleUrls: ["./entity-type-field.component.scss"]
})
export class EntityTypeFieldComponent implements OnInit {

  keyPrefix: String = "manage.plan.entityType";
  _entityType: String;
  private params: any;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  agInit(params) {
    if (params && params.data && params.data.entityType) {
      this._entityType = params.data.entityType.toLowerCase();
    }
    this.params = params;

  }

  get entityTypeKey() {
    return this.keyPrefix + "." + this._entityType;
  }

  get entityType() {
    let value = this.translate.instant(this.entityTypeKey);
    return value == this.entityTypeKey ? "" : value;
  }

  openMswfRules(event) {
    event.stopPropagation();
    this.params.context.showMswfRulesDlg(this.params.data);
  }
}
