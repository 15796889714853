//import {Tenant} from '../models/tenant';
import {User} from "../models/user";
import {createFeatureSelector, createSelector} from "@ngrx/store";

export interface StoreData {
  currentUser: User;
}

export const INITIAL_STORE_DATA: StoreData = {
  currentUser: {username: null}
};

const storeDataFeatureSelector = createFeatureSelector<StoreData>("storeData");
export const userLoginSelector = createSelector(storeDataFeatureSelector, (storeData) => storeData.currentUser.isLoggedIn);