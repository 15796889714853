// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  align-content: center;
}

.header-wrapper {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #2E2E2E;
}

button {
  height: 22px;
  width: 22px;
  padding: 6px;
  border: 0px;
  margin-right: -6px;
  font-size: 14px;
  min-width: 0;
  box-shadow: none;
  color: #6b7984;
}
button.btn.disabled, button.btn.disabled:hover, button.btn[disabled]:hover {
  background-color: #fff;
  box-shadow: none;
}

::ng-deep .popover.reset-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.reset-popup .message-text p {
  color: black;
  white-space: pre-wrap;
}

::ng-deep .popover.reset-popup {
  max-width: 300px;
}

.icon-file-file-copy {
  height: 22px;
  width: 22px;
  padding: 2px 3px;
  min-width: 0;
  margin-top: 5px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-distribution/base-header-v2/base-header-v2.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;AACF;;AACA;EACE,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;EACA,cAAA;AAEF;;AACA;EACE,YAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;AAEF;AADE;EACE,sBAAA;EACA,gBAAA;AAGJ;;AAEA;EACG,eAAA;EACA,iBAAA;AACH;;AACA;EACE,YAAA;EACA,qBAAA;AAEF;;AAIA;EACE,gBAAA;AADF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;AAAF","sourcesContent":[":host {\n  display: flex;\n  align-content: center;\n}\n.header-wrapper{\n  /* display: flex; */\n  align-items: center;\n  justify-content: space-between;\n  font-size: 11px;\n  font-weight: bold;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-transform: uppercase;\n  color: #2E2E2E;\n}\n\nbutton{\n  height: 22px;\n  width: 22px;\n  padding: 6px;\n  border: 0px;\n  margin-right: -6px;\n  font-size: 14px;\n  min-width: 0;\n  box-shadow: none;\n  color: #6b7984;\n  &.btn.disabled, &.btn.disabled:hover, &.btn[disabled]:hover{\n    background-color: #fff;\n    box-shadow: none;\n  }\n}\n\n\n::ng-deep .popover.reset-popup .button-response button{\n   margin-right: 0;\n   margin-left: 10px;\n}\n::ng-deep .popover.reset-popup .message-text p{\n  color:black;\n  white-space: pre-wrap;\n\n}\n::ng-deep .popover.reset-popup .arrow::after{\n    //border-top-color: white;\n}\n::ng-deep .popover.reset-popup {\n  max-width: 300px;\n}\n.icon-file-file-copy{\n  height: 22px;\n  width: 22px;\n  padding: 2px 3px;\n  min-width: 0;\n  margin-top: 5px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
