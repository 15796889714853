import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {MessageService, PrimeNGConfig} from "primeng/api";
import * as moment from "moment";
import {AppActionTypes, ConvertPlan} from "../../../store/actions";
import {WfmModalComponent as modalComponent} from "../../../components/wfm-modal/wfm-modal.component";
import { DialogOptions } from "src/app/common/dialog-options";


@Component({
  selector: "convert-plan-dialog",
  templateUrl: "./convert-plan-dialog.component.html",
  styleUrls: ["./convert-plan-dialog.component.scss"],
  providers: [DatePipe, MessageService]
})
export class ConvertPlanDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  private _subscriptions=[];

  public _sourceFrom: Date;
  public _sourceTo: Date;

  public _targetFrom: Date;
  public _targetTo: Date;
  private _targetFromMin: Date;

  private _planStartDate: Date;
  private _planEndDate: Date;



  private _isLoading = false;
  private _enableAccept = false;
  private _enableCancel = true;
  private _isValidForm: boolean;
  private _showSourceFromValidationError: boolean;
  private _showSourceToValidationError: boolean;
  private _showSourceToRangeValidationError: boolean;
  private _showTargetFromValidationError: boolean;

  private _planYearRange:string;
  private _targetYearRange:string;

  private numberOfDays:number;

  private _forecastIsSameDay:boolean;
  private _datesFormat = "";

  @ViewChild("sourceFromCalendarEl") _sourceFromCalendarEl;
  @ViewChild("sourceToCalendarEl") _sourceToCalendarEl;
  @ViewChild("targetFromCalendarEl") _targetFromCalendarEl;


  @Input()
  planStartDate: string;

  @Input()
  planEndDate: string;

  @Input()
  planName: string;

  @Input()
  planId: string;

  constructor(public activeModal: NgbActiveModal,
              private fb: FormBuilder,
              private datePipe: DatePipe,
              private translate: TranslateService,
              private store: Store<any>,
              private action$: Actions,
              private modalService: NgbModal,
              private messageService: MessageService) {

  }

  ngOnInit() {
    moment.locale((this.translate.currentLang));
    this._datesFormat = this.translate.instant("date.picker.format");
    this._planStartDate = moment(this.planStartDate).utc().toDate();
    this._planEndDate = moment(this.planEndDate).utc().toDate();

    this._sourceFrom = moment(this.planStartDate).utc().toDate();
    this._sourceTo = moment(this.planEndDate).utc().toDate();
    this._targetFromMin = moment().add(1, "d").toDate();


    this._targetFrom = null;//moment().day(7+ moment(this._sourceFrom).day()).toDate();

    this._planYearRange=(moment(this.planStartDate).year()-1).toString()+":"+(moment(this.planEndDate).year()+1).toString();
    this._targetYearRange=moment().year().toString()+":"+moment().add(8,"y").year().toString();

    this.validateForm();

    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.ConvertPlanSuccess)).subscribe(this.convertPlanSuccessHandler));
    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.ConvertPlanFailed)).subscribe(this.convertPlanFailedHandler));
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub)=>sub.unsubscribe());
  }

  close(result) {
    this.activeModal.close(result);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  submit(){
    this._isLoading=true;
    this._enableAccept=false;
    this._enableCancel=false;

    this.store.dispatch(new ConvertPlan({
      fromStartDate:this.formatDate(this._sourceFrom),
      fromEndDate:this.formatDate(this._sourceTo),
      forecastStartDate:this.formatDate(this._targetFrom),
      forecastEndDate:this.formatDate(this._targetTo),
      planId:this.planId
    }))

  }
  formatDate(date){
    return moment(date).format("YYYY-MM-DD");
  }
  validateForm() {
    this._showSourceFromValidationError = !this._sourceFrom || this._sourceFrom<this._planStartDate;

    this._showSourceToValidationError = (!this._sourceTo || this._sourceTo>this._planEndDate);

    if(this._sourceFrom > this._sourceTo){
     this._showSourceToRangeValidationError = true;
     this._showSourceToValidationError=false;
    }else{
      this._showSourceToRangeValidationError = false;
    }

    this._showTargetFromValidationError =  (this._targetFrom && this._targetFrom<this._targetFromMin);


    this._isValidForm = this._targetFrom && !(this._showSourceFromValidationError ||
      this._showSourceToValidationError ||
      this._showSourceToRangeValidationError ||
      this._showTargetFromValidationError);

    this._enableAccept=this._isValidForm;
    this.updateTargetToDate();
    this.updateSourceDateRange();
  }

  onTargetFromChanged() {
    this.validateForm();
  }

  onSourceFromChanged() {
    this.validateForm();
  }

  onSourceToChanged() {
    this.validateForm();
  }

  onYearChanged(){
    console.log("Year Changed");
  }
  updateTargetToDate() {
    if (this._isValidForm) {
      this.numberOfDays = moment(this._sourceTo).diff(moment(this._sourceFrom),"days");
      this._targetTo = moment(this._targetFrom).add(this.numberOfDays,"d").toDate();
      this._forecastIsSameDay = moment(this._targetFrom).weekday()==moment(this._sourceFrom).weekday();
    }else{
      this.numberOfDays=-1;
      this._targetTo = null;
      this._forecastIsSameDay = true; //no need to display warning message
    }
  }

  private updateSourceDateRange() {

    this.updateYearRange(this._sourceFromCalendarEl);
    this.updateYearRange(this._sourceToCalendarEl);
    this.updateYearRange(this._targetFromCalendarEl);
    // ESP-2966 to prevent tooltip from reappearing
    setTimeout(()=>{
      if(this._sourceFromCalendarEl)
        this._sourceFromCalendarEl.updateUI();
      if(this._sourceToCalendarEl)
        this._sourceToCalendarEl.updateUI();
      }
        , 0)

  }
  private updateYearRange(datePicker){
    if(!datePicker || !datePicker.yearRange){
      return;
    }

    let range = datePicker.yearRange.split(":");


    if(datePicker.currentYear<parseInt(range[0])){
      range[0]=datePicker.currentYear;
    }

    if(datePicker.currentYear>parseInt(range[1])){
      range[1]=datePicker.currentYear;
    }
    datePicker.populateYearOptions(range[0],range[1]);
  }
  private warningMessageText(){
    let msg =  this.translate.instant("plan.convert.warning");
    msg = msg.replace("<start-date>",moment(this._targetFrom).format("L"));
    msg = msg.replace("<end-date>",moment(this._targetTo).format("L"));
    return msg;

  }

  convertPlanSuccessHandler = (e) => {
    let msg = this.translate.instant("plan.convert.success.msg").replace("<jobId>",e.payload.jobId);
    let dialogParam:DialogOptions = new DialogOptions();
      dialogParam.titleKey = "plan.convert.success.title";
      dialogParam.messageKey = msg;
      dialogParam.msgType = "info";
      dialogParam.showCancel = false;
      dialogParam.confirmLabel = "btn.ok.label";
      modalComponent.showModalMessage(dialogParam, this.modalService);
    this.dismiss();
  };

  convertPlanFailedHandler = async (e) => {
      const dialogParam: DialogOptions = new DialogOptions();
      dialogParam.titleKey = "plan.convert.failure.msg.title";
      dialogParam.msgType = "error";
      dialogParam.showCancel = false;
      dialogParam.confirmLabel = "btn.ok.label";
    if (e.payload.status === 422) { //Validation error. An active forecast must exist for the entire target date range.
      dialogParam.messageKey = "plan.convert.failure.date.range";
      await modalComponent.showModalMessage(dialogParam, this.modalService);
      this._isLoading = false;
      this._enableCancel = true;
      this._enableAccept = true;
    } else if (e.payload.status === 403) { //Unauthorized
      dialogParam.messageKey = "plan.convert.failure.unauthorized";
      await modalComponent.showModalMessage(dialogParam, this.modalService);
      this.dismiss();
    } else if (e.payload.status === 401) {
      this.dismiss();
    }
    else {
      dialogParam.messageKey = "plan.convert.failure.general";
      await modalComponent.showModalMessage(dialogParam, this.modalService);
      this.dismiss(); //?
    }
  };

}
