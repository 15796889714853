import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "wfm-esp-tooltip-field",
  templateUrl: "./tooltip-field.component.html",
  styleUrls: ["./tooltip-field.component.scss"]
})
export class TooltipFieldComponent implements OnInit {
  private currentItem;
  private params;
  content: String;

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
  }

  agInit(params) {
    if (params && params.data) {
      this.currentItem = params.data;
      this.params = params;
      this.content = params.data[params.column.colDef.field];

      // Translation part for the field importStatus of import forecast.
      if (params.column.colDef.field === 'importStatus') {
        let translatedValue:string = this.translate.instant("imported.forecasts.importStatus." + this.content);
        if (!translatedValue.includes("imported.forecasts.importStatus.")) {
          this.content = translatedValue;
        }
      } 
      
    }
  }
}
