import { Component, OnInit } from "@angular/core";

@Component({
  selector: "plan-detail-validation-cell",
  templateUrl: "./plan-detail-validation-cell.component.html",
  styleUrls: ["./plan-detail-validation-cell.component.css"]
})
export class PlanDetailValidationCellComponent implements OnInit {
  public params: any;
  public validationSum: any;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
    // Format according to the locale.
    /*this.validationSum = NumberUtils.formatNumberIfNecessary(params.context.columnSum(this.params.colDef.field),
      params.locale, params.metadata.decimal);*/
  }

  get isValidIndicator(){
    let context = this.params?.context;
    //return context.isGridValid()==false;
    return context.isGridValid()==false &&
      context.isValidCell(this.params,this.params.colDef.field)==false &&
      this.params.api.getFocusedCell() &&
      this.params.api.getFocusedCell().rowIndex==this.params.rowIndex &&
      this.params.api.getFocusedCell().column.colId==this.params.column.colId
  }
  get validationMessage(){
    let context = this.params.context;

    return this.params.data.metadata.validator.msg;
  }
  getValueToDisplay(params) {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
  refresh(){
    return false;
  }

}
