import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Directive({
  selector: "[iconName]"
})
export class IconNameDirective implements OnInit {

  @Input() iconName: string;
  @Input() fillColor: string;

  iconArray = {
    "eye-open": `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
				    <title>view</title>
				    <path d="M10 3C5 3 0 8.4 0 10.2 0 11.7 4.6 17 10 17s10-5.7 10-6.8C20 9 15.2 3 10 3zm0 12.3c-4.1 0-7.7-3.9-8.3-5.1.5-1.4 4.4-5.5 8.3-5.5 3.8 0 7.4 4 8.2 5.4-.8 1.4-4.3 5.2-8.2 5.2zm-.1-9.2c-2 0-3.6 1.7-3.6 3.7s1.6 3.7 3.6 3.7 3.6-1.7 3.6-3.7-1.7-3.7-3.6-3.7zm0 5.9c-1.2 0-2.1-1-2.1-2.2s1-2.2 2.1-2.2c1.2 0 2.1 1 2.1 2.2S11 12 9.9 12zm0-3.4c-.7 0-1.2.6-1.2 1.2 0 .7.5 1.2 1.2 1.2s1.2-.6 1.2-1.2c0-.7-.6-1.2-1.2-1.2z"></path>
				</svg>`,
    "eye-close": `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
				    <path d="M19.8,7.2C20.1,6.8,20,6.3,19.7,6c-0.4-0.3-0.9-0.2-1.2,0.1c0,0-3.2,3.9-8.5,3.9S1.5,6.2,1.5,6.1C1.2,5.8,0.7,5.7,0.3,6
				    C0,6.3-0.1,6.8,0.2,7.2c0.1,0.1,0.5,0.6,1.4,1.4l-1.5,1.8l1.3,1.1l1.5-1.8c0.6,0.4,1.4,0.8,2.2,1.2L4.3,13l1.6,0.6l0.8-2.4
				    c0.8,0.2,1.6,0.3,2.5,0.4v2.5h1.7v-2.5c0.9-0.1,1.8-0.2,2.5-0.4l0.9,2.4l1.6-0.6l-0.8-2.3c0.8-0.3,1.5-0.7,2.1-1.1l1.5,1.9l1.3-1
				    l-1.5-1.8C19.3,7.9,19.8,7.3,19.8,7.2z"/>
				</svg>`,
    "arrow-down": `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 20 20">
			    <title>arrow-down</title>
			    <path d="M8.8 15L.3 6.5C-.1 6.1-.1 5.4.3 5s1.1-.4 1.5 0l8 8 8.5-8c.4-.4 1.1-.4 1.5 0s.4 1.1 0 1.5l-9 8.5c-.3.3-.6.3-.9.3-.5 0-.9-.1-1.1-.3z"></path>
			    </svg>`,
    "accordian-close": `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>close</title>
						<path d="M9.3 11c.2.2.4.2.6.2s.4 0 .6-.2l5.4-5.1c.2-.2.2-.6 0-.9-.2-.2-.6-.2-.9 0L9.8 9.8 5.1 5c-.3-.2-.7-.2-.9 0s-.2.6 0 .9L9.3 11z"></path>
						<path d="M14.9 9l-5.1 4.8L5.1 9c-.3-.2-.7-.2-.9 0s-.2.6 0 .9L9.3 15c.2.2.4.2.6.2s.4 0 .6-.2l5.4-5.1c.2-.2.2-.6 0-.9-.3-.2-.7-.2-1 0z"></path>
						</svg>`,
    "accordian-open": `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>open</title>
					 <path d="M9.3 9c.2-.2.4-.2.6-.2s.4 0 .6.2l5.4 5.1c.2.2.2.6 0 .9-.2.2-.6.2-.9 0l-5.1-4.8L5.1 15c-.2.2-.6.2-.9 0-.2-.2-.2-.6 0-.9L9.3 9z"></path>
					 <path d="M14.9 11L9.8 6.2 5.1 11c-.2.2-.6.2-.9 0-.2-.2-.2-.6 0-.9L9.3 5c.2-.2.4-.2.6-.2s.4 0 .6.2l5.4 5.1c.2.2.2.6 0 .9-.3.2-.7.2-1 0z"></path>
					 </svg>`,
    search: `<svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 8c0 4.4 3.6 8 8 8 1.8 0 3.5-.6 4.9-1.7l5.4 5.4c.4.4 1 .4 1.4 0s.4-1 0-1.4l-5.4-5.4C15.4 11.5 16 9.8 16 8c0-4.4-3.6-8-8-8S0 3.6 0 8zm2 0c0-3.3 2.7-6 6-6s6 2.7 6 6-2.7 6-6 6-6-2.7-6-6z"></path>
			</svg>`,
    cross: `<svg _ngcontent-c4="" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
				<path _ngcontent-c4="" d="M11.4 10l4.7-4.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L10 8.6 5.3 3.8c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4L8.6 10l-4.7 4.7c-.4.4-.4 1 0 1.4s1 .4 1.4 0l4.7-4.7 4.7 4.7c.4.4 1 .4 1.4 0s.4-1 0-1.4L11.4 10z">
				</path>
      </svg>`,
    delete: `<svg version="1.1"   xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="#93a4aa">
      <path d="M19 4h-3v-4h-12v4h-3c-0.6 0-1 0.5-1 1s0.4 1 1 1h1.2l1.3 12.3c0 1 0.7 1.7 1.5 1.7h10c0.8 0 1.5-0.7 1.5-1.7l1.2-12.3h1.3c0.5 0 1-0.5 1-1s-0.4-1-1-1zM6 2h8v2h-8v-2zM14.5 18h-9l-1.3-12h11.5l-1.2 12z"></path>
      <path d="M7 8h2v7h-2v-7z"></path>
      <path d="M11 8h2v7h-2v-7z"></path>
			</svg>`,

    warn: `<svg xmlns="http://www.w3.org/2000/svg" id="icon-warning" viewBox="0 0 20 20" fill="#FC9537" class="nb-modal-icon" width="25" height="25">
        <path d="M9 8v4c0 0.6 0.4 1 1 1 0.5 0 1-0.4 1-1v-4c0-0.6-0.5-1-1-1-0.6 0-1 0.4-1 1z"></path>
        <path d="M10 15v0c-0.6 0-1 0.4-1 1s0.4 1 1 1v0c0.5 0 1-0.4 1-1s-0.5-1-1-1z"></path>
        <path d="M19.8 17.1l-8-15c-0.4-0.7-1.1-1.1-1.8-1.1s-1.4 0.4-1.8 1.1l-8 15c-0.3 0.6-0.3 1.4 0 2 0.4 0.6 1 1 1.7 1h16c0.7 0 1.4-0.4 1.7-1 0.5-0.7 0.5-1.4 0.2-2zM2 18l8-15 8 15h-16z"></path>
    </svg>`,

    error: `<svg xmlns="http://www.w3.org/2000/svg" id="icon-error" fill="#fa3f40" class="nb-modal-icon" viewBox="0 0 20 20" width="25" height="25">
        <path d="M10 0c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zM10 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"></path>
        <path d="M10 11v0c-0.6 0-1-0.4-1-1v-4c0-0.5 0.4-1 1-1v0c0.5 0 1 0.4 1 1v4c0 0.6-0.4 1-1 1z"></path>
        <path d="M10 16v0c-0.6 0-1-0.4-1-1v-1c0-0.5 0.4-1 1-1v0c0.5 0 1 0.4 1 1v1c0 0.6-0.4 1-1 1z"></path>
    </svg>
</svg>`,
    info:`<svg fill="#43ACD9" class="nb-modal-icon" viewBox="0 0 20 20" width="25" height="25">
                    <path d="M10 20c5.5 0 10-4.5 10-10s-4.5-10-10-10-10 4.5-10 10 4.5 10 10 10zM10 2c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8z"></path>
                    <path d="M10 9.5v0c0.6 0 1 0.4 1 1v4c0 0.5-0.4 1-1 1v0c-0.5 0-1-0.4-1-1v-4c0-0.6 0.4-1 1-1z"></path>
                    <path d="M10 4.5v0c0.6 0 1 0.4 1 1v1c0 0.5-0.4 1-1 1v0c-0.5 0-1-0.4-1-1v-1c0-0.6 0.4-1 1-1z"></path>
                </svg>`
  };

  constructor(private el: ElementRef, private renderer: Renderer2,
    private translate: TranslateService) {
  }

  ngOnInit() {
    let iconTxt = this.iconArray[this.iconName];
    // localization updates the tooltip, the token to use is in the title of the icon element.
    let tooltipText = this.translate.instant(`icon.${this.iconName}.tooltip`);
    if (tooltipText && iconTxt.includes(`icon.${this.iconName}.tooltip`)) {
      iconTxt = iconTxt.replace(`icon.${this.iconName}.tooltip`, tooltipText);
    }
    this.el.nativeElement.innerHTML = iconTxt;
    this.renderer.setStyle(this.el.nativeElement.querySelector("svg"), "fill,", this.fillColor);
  }

}
