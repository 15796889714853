import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { DialogOptions } from "src/app/common/dialog-options";

@Component({
  selector: "wfm-modal",
  templateUrl: "./wfm-modal.component.html",
  styleUrls: ["./wfm-modal.component.scss"]
})
export class WfmModalComponent implements OnInit {

  @Input() titleKey = "dialog.title";
  @Input() messageKey = "dialog.msg";
  @Input() cancelDisable = false;
  @Input() okDisable = false;
  @Input() showCancel = true;
  @Input() cancelLabel = "btn.cancel.label";
  @Input() confirmLabel = "btn.confirm.label";
  @Input() msgType = "warn";

  @Output() confirmEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectEvent: EventEmitter<any> = new EventEmitter<any>();


  constructor(public activeModal: NgbActiveModal,
              private translate: TranslateService) {

  }

  ngOnInit() {

  }

  reject() {
    this.activeModal.dismiss();
    this.rejectEvent.emit();
  }

  confirm() {
    this.activeModal.close();
    this.confirmEvent.emit();

  }

  public static showModalMessage(options: DialogOptions, modalService: NgbModal) {

    const modalRef = modalService
      .open(WfmModalComponent, {
        centered: true,
        windowClass: "b-info wfm-modal message-dialog",
        container: options.container,
        backdrop : 'static',
        keyboard : false
      });
    modalRef.componentInstance.titleKey = options.titleKey;
    modalRef.componentInstance.messageKey = options.messageKey;
    modalRef.componentInstance.msgType = options.msgType;
    modalRef.componentInstance.cancelDisable = options.cancelDisable;
    modalRef.componentInstance.showCancel = options.showCancel;
    modalRef.componentInstance.okDisable = options.okDisable;
    modalRef.componentInstance.cancelLabel = options.cancelLabel;
    modalRef.componentInstance.confirmLabel = options.confirmLabel;
    return modalRef.result;
  }

}
