// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  cursor: pointer;
}

.customHeaderLabel {
  text-transform: uppercase;
  color: #2E2E2E;
  font-size: 11px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-detail-clickable-header/plan-detail-clickable-header.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,yBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACF","sourcesContent":["div {\n  cursor: pointer;\n}\n\n.customHeaderLabel {\n  text-transform: uppercase;\n  color: #2E2E2E;\n  font-size: 11px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
