import { Component, OnInit } from "@angular/core";
import { ILoadingOverlayComp, IAfterGuiAttachedParams, ILoadingOverlayParams } from "ag-grid-community";
import { ILoadingOverlayAngularComp } from "ag-grid-angular";

@Component({
  selector: "wfm-plan-loading-overlay",
  templateUrl: "./plan-loading-overlay.component.html",

  styleUrls: ["./plan-loading-overlay.component.scss"]
})
export class PlanLoadingOverlayComponent implements ILoadingOverlayAngularComp  {


  private params: any;

  agInit(): void {

  }

}
