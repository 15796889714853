// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body{
  font-size: 14px;
}

input.input-wider {
  width: 8em;
}

::ng-deep .wfm-modal .modal-body{
  min-width: 420px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-settings-ct-dialog-v2/plan-settings-ct-dialog-v2.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal-body{\n  font-size: 14px;\n}\n\ninput.input-wider {\n  width: 8em;\n}\n\n::ng-deep .wfm-modal .modal-body{\n  min-width: 420px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
