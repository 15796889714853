import { Component, OnInit } from "@angular/core";
import {IHeaderAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, IHeaderParams} from "ag-grid-community";

@Component({
  selector: "wfm-esp-base-header-v2",
  templateUrl: "./base-header-v2.component.html",
  styleUrls: ["./base-header-v2.component.scss"]
})
export class BaseHeaderComponentV2 implements OnInit, IHeaderAngularComp {

  params:IHeaderParams;

  constructor() { }

  ngOnInit(): void {
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }
  get isResetAvailable(){
    return !(this.params.context.isReadOnly());
  }

  isValid(){
    return !this.params.context.isValidColumn("base")
  }

  copyBase(){
    this.params.context.copyBase();
  }

  refresh(params: IHeaderParams): boolean {
    return false;
  }


}
