import {INITIAL_UI_STATE, UiState} from "./ui-state";
import {INITIAL_STORE_DATA, StoreData} from "./store-data";



export enum TaskState{
  Ready,
  InProgress,
  Complete
}
export interface BackgroundTask {
  taskId:number,
  uuid?:string,
  status: TaskState,
  progressPercent:number,
  metadata?:any
}


export interface ApplicationState {
  uiState: UiState;
  storeData: StoreData;
}

export const INITIAL_APP_STATE: ApplicationState = {
  uiState: INITIAL_UI_STATE,
  storeData: INITIAL_STORE_DATA
};
