import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import {BehaviorSubject, Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../authentication.service";
import {TenantService} from "../../tenant.service";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { WfmSelectComponent } from "../../components/wfm-select/wfm-select.component";
import {MessageUtils} from "../../common/utils/message.utils";


@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"]
})

export class LoginPageComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  showInvalidLoginMessage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  locales: Array<any> = environment.locales;

  private selectedLocale = "en-US";
  private needToShowPassword = false;
  private isLoadingFlag = false;
  private isUserLoggedIn = false;
  private isLoginDone = false;
  private redirectUrl = null;
  public loggedIn$: Observable<boolean>;
  public userInfo$: Observable<string>;
  public loginErrorMessage = null;

  txtUsername = "";
  txtPassword = "";
  copyrightMessage = "";
  showErrorUsername = false;
  showErrorPassword = false;

  @ViewChild("localeCombo")
  localeEl: WfmSelectComponent;

  localeComboList:string[] = [];
  localeComboKeys:string[] = [];
  localeComboSelected = "";

  @ViewChild("inputPassword")
  private inputPassword;

  get showPassword() {
    return this.needToShowPassword;
  }

  get hasValidForm() {
    return this.loginForm.status === "VALID";
  }

  public readonly uNameCss = {"has-value": false, "is-valid":true, "is-invalid":false};
  public readonly pwdCss = {"has-value": false, "is-valid":true, "is-invalid":false};

  txtUsernameChanged(e) {
    if (e && e !== null && e !== "" && this.showErrorUsername) this.showErrorUsername = false;
  }

  txtPasswordChanged(e) {
    if (e && e !== null && e !== "" && this.showErrorPassword) this.showErrorPassword = false;
  }

  get usernameCssValidationObject() {
    const obj = this.getValidationCssObject("username");
    this.uNameCss["has-value"] = obj["has-value"];
    this.uNameCss["is-valid"] = obj["is-valid"],
    this.uNameCss["is-invalid"] = obj["is-invalid"];
    return (!this.showErrorUsername) ? "" : this.uNameCss;
  }

  get passwordCssValidationObject() {
    const obj = this.getValidationCssObject("password");
    this.pwdCss["has-value"] = obj["has-value"];
    this.pwdCss["is-valid"] = obj["is-valid"],
    this.pwdCss["is-invalid"] = obj["is-invalid"];
    return (!this.showErrorPassword) ? "" : this.pwdCss;
  }

  constructor(
    private formBld: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private tenantService: TenantService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private store: Store<any>,
    /*private bsLocaleService: BsLocaleService*/
  ) {
    this.loggedIn$ = this.store.select("state");
    this.loggedIn$.subscribe((value: any) => {
      if (value && value.isLoggedIn) {
        this.isUserLoggedIn = value.isLoggedIn;
      } else {
        this.isUserLoggedIn = false;
      }
    });
    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if (value && value.locale) {

        this.selectedLocale = value.locale;
        this.useLocale(value.locale);

      }
    });
    this.createForm();
    this.copyrightMessage = MessageUtils.getCopyrightMessage();
  }

  ngOnInit() {
    if (!this.tenantService.tenantData) {
      // route to root landing page
      this.router.navigate(["/"]);
    }

    if(this.tenantService.isCXOne){
      return;
    }

    if (this.authenticationService.isAuthorized()) {
      this.ifAuthorized();
    } else if (this.authenticationService.isLoggedIn()) {
      this.authorizeUser();
    }

    this.localeComboKeys = this.locales.map((item) => item.locale);
    this.localeComboList = this.locales.map((item) => item.display);

    if (this.selectedLocale) {
      let localeComboIndex = this.localeComboKeys.indexOf(this.selectedLocale);
      let localeComboValue = this.localeComboList[localeComboIndex];
      this.localeComboSelected = localeComboValue;
      this.useLocale(this.selectedLocale);
    }
  }

  updateLocaleCombo(localeCombo) {
    if (this.localeComboSelected !== localeCombo) {
      this.localeComboSelected = localeCombo;

      let localeComboIndex = this.localeComboList.indexOf(this.localeComboSelected);
      let localeComboKey = this.localeComboKeys[localeComboIndex];
      this.useLocale(localeComboKey);
    }
  }

  compareLocaleForSelect(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.locale === c2.locale : c1 === c2;
  }

  private createForm() {
    this.loginForm = this.formBld.group({
      username: [null, [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      password: [null, [
        Validators.required, Validators.minLength(1),
        Validators.maxLength(256)
      ]],
      locale: [this.locales.find(x => x.locale == "en-US")]
    });
    this.loginForm.valueChanges.subscribe(val => {
      this.showInvalidLoginMessage.next(false)
    })
  }

  private getValidationCssObject(controlName) {
    return {
      "is-valid": this.formControlIsValid(controlName),
      "is-invalid": !this.formControlIsValid(controlName),
      "has-value": this.doesControlHasValue(controlName)
    };
  }

  private doesControlHasValue(controlName) {
    return this.loginForm.get(controlName).value && this.loginForm.get(controlName).value !== null
      && this.loginForm.get(controlName).value !== "";
  }

  togglePasswordIcon() {
    this.needToShowPassword = !this.needToShowPassword;
    if (this.doesControlHasValue("password")) {
      this.inputPassword.nativeElement.focus();
    }
  }

  formControlIsValid(controlName) {
    return (this.loginForm.get(controlName).dirty || this.loginForm.get(controlName).touched)
      && this.loginForm.get(controlName).valid;
  }

  formControlIsInvalid(controlName) {
    return (this.loginForm.get(controlName).dirty || this.loginForm.get(controlName).touched)
      && this.loginForm.get(controlName).invalid;
  }

  getFormControlErrors(controlName): any {
    return this.loginForm.get(controlName).errors;
  }

  logUserIn() {
    let self = this;

    if(!this.hasValidForm) {
      this.showErrorUsername = (!this.formControlIsValid("username")) ? true : false;
      this.showErrorPassword = (!this.formControlIsValid("password")) ? true : false;
      return;
    }

    self.isLoadingFlag = true;
    this.showInvalidLoginMessage.next(false);
    this.authenticationService.authenticate(
      self.txtUsername,
      self.txtPassword,
      self.translate.currentLang,
      self.tenantService.tenantIdentifier)
      .then(
        data => {
          self.isUserLoggedIn = true;
          return this.authorizeUser();
        })
      .catch(function (err) {
        self.isUserLoggedIn = false;
        self.isLoadingFlag = false;
        self.showInvalidLoginMessage.next(true);
        self.loginErrorMessage = self.translate.instant("login.error.failure");
        if (err instanceof HttpErrorResponse && err.status === 401 && err.error.errorId === "WFM_PASSWORD_EXPIRED") {
          self.loginErrorMessage = self.translate.instant("login.error.passwordExpired");
        }
      });
  }

  authorizeUser() {
    let self = this;
    self.isLoadingFlag = true;
    self.isUserLoggedIn = true;
    return this.authenticationService.authorizeUser()
      .then(
        data => {
          self.isLoadingFlag = false;
          self.ifAuthorized();
        }
      )
      .catch(function (err) {
        self.isLoadingFlag = false;
      });
  }

  onLocaleChange(event) {
    this.useLocale(this.loginForm.controls["locale"].value.locale);
  }

  useLocale(locale: string) {
    let newLocale = this.locales.find(x => x.locale == locale);
    if (newLocale && this.loginForm) {
      this.loginForm.controls["locale"].setValue(newLocale);
    }
    this.translate.use(locale);
    /*this.bsLocaleService.use('es');*/
  }

  ifAuthorized() {
    this.router.navigate(["/plans"]);
  }

  ngAfterViewInit() {
    //  setTimeout(() => {
    //    //this.store.dispatch(new SetLoadingState(false));
    //  }, 500);
  }

}
