// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-progress-container {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.b-p-progress {
  max-width: 200px;
  border: 1px solid #DAE2E8;
  background: white;
}

.b-p-label {
  color: #404040;
  font: Bold 16px/22px Open Sans;
  width: 45px;
}

.b-p-textlabel {
  font: Regular 13px/18px Open Sans;
  color: #404040;
  width: 70px;
  text-align: right;
}

.b-progress-bar {
  transition: width 0.2s ease-in;
}`, "",{"version":3,"sources":["webpack://./src/app/components/task-progress/task-progress.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,oCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AACA;EACE,gBAAA;EACA,yBAAA;EACA,iBAAA;AAEF;;AACA;EACE,cAAA;EACA,8BAAA;EACA,WAAA;AAEF;;AAAA;EACE,iCAAA;EACA,cAAA;EACA,WAAA;EACA,iBAAA;AAGF;;AADA;EACE,8BAAA;AAIF","sourcesContent":[".task-progress-container{\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.8);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n.b-p-progress{\n  max-width: 200px;\n  border: 1px solid #DAE2E8;\n  background: white;\n\n}\n.b-p-label{\n  color: #404040;\n  font: Bold 16px/22px Open Sans;\n  width:45px;\n}\n.b-p-textlabel{\n  font: Regular 13px/18px Open Sans;\n  color: #404040;\n  width: 70px;\n  text-align: right;\n}\n.b-progress-bar{\n  transition: width 0.2s ease-in;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
