import { Component, OnInit } from "@angular/core";
import {IHeaderAngularComp} from "ag-grid-angular";
import {IAfterGuiAttachedParams, IHeaderParams} from "ag-grid-community";

@Component({
  selector: "wfm-esp-week-header-v2",
  templateUrl: "./week-header-v2.component.html",
  styleUrls: ["./week-header-v2.component.scss"]
})
export class WeekHeaderComponentV2 implements OnInit,IHeaderAngularComp {


  params:IHeaderParams;

  constructor() { }

  ngOnInit(): void {
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }
  isResetAvailable(){
    if(this.params.context.isReadOnly()){
      return false;
    }

    let firstRow = this.params.api.getRowNode("0");
    const isEdited:boolean = firstRow && firstRow.data[this.params.column.getColDef().field]!==undefined;
    if(isEdited){
      return true;
    }
    return false;
  }
  resetColumn(){
    this.params.context.resetColumn(this.params.column);
  }

  isDisabled(){
    const isValidBase: boolean = this.params.context.isValidColumn("base");
    const isValidColumn = this.params.context.isValidColumn(this.params.column.getColDef().field);
    const isGridValid = this.params.context.isGridValid();

    const isEnabled = isGridValid || (isValidBase && isValidColumn==false);

    return !isEnabled;
  }
  refresh(params: IHeaderParams): boolean {
    return false;
  }

}
