import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, of, throwError, timer} from "rxjs";
import {Store} from "@ngrx/store";
import {catchError, concatMap, delay, filter, map, mergeMap, retry, take, takeUntil} from "rxjs/operators";
import {AppActionTypes, TaskCancel, TaskComplete, TaskCreate, TaskError, TaskStart, TaskStatus} from "../store/actions";
import {TaskState} from "../store/application-state";
import {environment} from '../../environments/environment';
import {Actions, ofType} from '@ngrx/effects';

const INITIAL_INTERVAL = 200;
const STATUS_INTERVAL = 2000;
const RETRIES = 5;

@Injectable({
  providedIn: "root"
})
export class TasksService {
  taskIds = 0;
  count= 1;
  constructor(private store: Store, private http: HttpClient,private action$: Actions) {

  }

  createTask(method, url, options): Observable<any> {
    let taskId = this.taskIds++;

    this.store.dispatch(new TaskCreate({taskId, progressPercent: 0, status: TaskState.Ready}));
    return this.http.request(method, url, options).pipe(
      takeUntil(this.action$.pipe(ofType(AppActionTypes.TaskCancelAction))),
      mergeMap((payload: any) => {
        this.store.dispatch(new TaskStart({taskId, uuid: payload.uuid, progressPercent: 0, status: TaskState.InProgress,...payload}));
        if (payload.links && payload.links.result) {
          return this.completeTask(taskId, payload.links.result);
        } else if (payload.links && payload.links.status) {
          return this.monitorTask(taskId, payload.links.status);
        }
      }),
      catchError((e)=>{
        console.log(e);
        this.store.dispatch(new TaskError({taskId,...e}));
        return throwError({taskStatus:TaskState.Ready,...e});
      })
    );
  }

  monitorTask(taskId, endpoint) {
    return timer(INITIAL_INTERVAL, STATUS_INTERVAL).pipe(
      concatMap(() => {

          return this.http.request(endpoint.type, environment.espEndpointBaseUrl + endpoint.href);
      }),
      map((response: any) => {
        this.store.dispatch(new TaskStatus({taskId, ...response}));
        return response;
      }),
      filter((response: any) => {
        //if(this.count++%2==0){
        if(!response)
          return false;
        return (response.links && response.links.result) || response.errors;
        /*}else{
          return false;
        }*/

      }),
      takeUntil(this.action$.pipe(ofType(AppActionTypes.TaskCancelAction))),
      take(1),
      retry(RETRIES),
      map((response: any) => {
        if(response.errors){
          throw {taskId,...response.errors}
        }else{
          this.store.dispatch(new TaskStatus({taskId,progressPercent:100, ...response}));
          return response.links.result;
        }
      }),
      catchError((e)=>{
        this.store.dispatch(new TaskError({taskId,...e}));
        return throwError({taskStatus:TaskState.InProgress,...e});
      }),
      concatMap((endpoint: any) => this.completeTask(taskId, endpoint)));
  }

  completeTask(taskId, endpoint) {
    return this.http.request(endpoint.type, environment.espEndpointBaseUrl + endpoint.href).pipe(
      map((payload: any) => {
        this.store.dispatch(new TaskComplete({taskId, progressPercent: 100, status: TaskState.Complete}));
        return payload;
      }),
      catchError((e)=>{
        this.store.dispatch(new TaskError({taskId,...e}));
        return throwError({taskStatus:TaskState.Complete,...e});
      })
    );
  }
}
