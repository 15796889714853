export class DebounceUtil {
  static debounce(fn: Function, delay: number): () => void {
    let timeoutId: any;

    return function (...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  }
}
