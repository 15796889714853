// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label{
  margin-bottom: 2px;
}
p{
  margin-bottom: 8px;
}
.modal-body{
  //width:500px;
}
ag-grid-angular{
  height:274px;
  width:500px;
  border:0px solid #c1bebe;
  margin-top: 4px;
}
:host ::ng-deep .ag-body-viewport{
  border-left:1px solid #dae2e8;
  border-right:1px solid #dae2e8;
  border-bottom:1px solid #dae2e8;
}
:host ::ng-deep .ag-row-first:not(.ag-row-hover){
  background: #f5f5f5;
}
:host ::ng-deep .ag-row-first{
  border-bottom: 2px solid #d9dcde;
  font-weight: 600;
}
:host ::ng-deep .ag-center-cols-container :not(.ag-row-first) [col-id="displayName"]{
  padding-left: 24px;
}
.close:focus-visible,.icon-Close:focus-visible{
  outline:none;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/mswf-rules-dialog/mswf-rules-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,WAAW;EACX,wBAAwB;EACxB,eAAe;AACjB;AACA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,+BAA+B;AACjC;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;AACd","sourcesContent":["label{\n  margin-bottom: 2px;\n}\np{\n  margin-bottom: 8px;\n}\n.modal-body{\n  //width:500px;\n}\nag-grid-angular{\n  height:274px;\n  width:500px;\n  border:0px solid #c1bebe;\n  margin-top: 4px;\n}\n:host ::ng-deep .ag-body-viewport{\n  border-left:1px solid #dae2e8;\n  border-right:1px solid #dae2e8;\n  border-bottom:1px solid #dae2e8;\n}\n:host ::ng-deep .ag-row-first:not(.ag-row-hover){\n  background: #f5f5f5;\n}\n:host ::ng-deep .ag-row-first{\n  border-bottom: 2px solid #d9dcde;\n  font-weight: 600;\n}\n:host ::ng-deep .ag-center-cols-container :not(.ag-row-first) [col-id=\"displayName\"]{\n  padding-left: 24px;\n}\n.close:focus-visible,.icon-Close:focus-visible{\n  outline:none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
