// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox input{
  display: none;
}

table tbody td{
}
.timepicker-container{
  width:100%;
  display:flex;
}
cxone-time-picker{
  flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/ct-settings/operating-hours/operating-hours.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;AACA;AACA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,cAAc;AAChB","sourcesContent":[".checkbox input{\n  display: none;\n}\n\ntable tbody td{\n}\n.timepicker-container{\n  width:100%;\n  display:flex;\n}\ncxone-time-picker{\n  flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
