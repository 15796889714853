// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customHeaderLabel {
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  color: #2E2E2E;
}
.customHeaderLabel span {
  text-transform: uppercase;
}

.icon {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-detail-header-row/plan-detail-header-row.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;AAAE;EACE,yBAAA;AAEJ;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".customHeaderLabel {\n  cursor: pointer;\n  font-size: 11px;\n  font-weight: bold;\n  color: #2E2E2E;\n  span {\n    text-transform: uppercase;\n  }\n}\n\n.icon {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
