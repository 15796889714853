import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import { Observable, of } from "rxjs";
import {
  AppActionTypes, GetApiCredential, GetApiCredentialSuccess, GetApiCredentialFailed,
  GenerateApiCredential, GenerateApiCredentialSuccess, GenerateApiCredentialFailed,
  DeactivateApiCredential, DeactivateApiCredentialSuccess, DeactivateApiCredentialFailed
} from "../actions";
import { HttpClient } from "@angular/common/http";
import { TenantService } from "src/app/tenant.service";
import { map, mergeMap, catchError } from "rxjs/operators";
import { ApiCredential, ApiCredentialResponse } from "src/app/models/api-credential";
import { environment } from "src/environments/environment";
import { appSettings } from "src/app/app-settings";


@Injectable()
export class ApiCredentialEffects {

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private tenantService: TenantService
  ) {
  }

  getApiCredential$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GetApiCredential),
    map((action: GetApiCredential) => action.payload),
    mergeMap((payload: any) => {
      let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.apiCredentials;
      return this.http.get<ApiCredentialResponse>(apiURL)
        .pipe(
          // If successful, dispatch success action with result
          map((response: any) => new GetApiCredentialSuccess(response)),
          // If request fails, dispatch failed action
          catchError(err => of(new GetApiCredentialFailed(err))),
        );
    })
  ));

  generateApiCredential$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.GenerateApiCredential),
    map((action: GenerateApiCredential) => action.payload),
    mergeMap((payload: any) => {
      let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.apiCredentials;
      return this.http.post<ApiCredential>(apiURL,"")
        .pipe(
          // If successful, dispatch success action with result
          map((response: any) => new GenerateApiCredentialSuccess(response)),
          // If request fails, dispatch failed action
          catchError(err => of(new GenerateApiCredentialFailed(err))),
        );
    })
  ));

  deactivateApiCredential$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.DeactivateApiCredential),
    map((action: DeactivateApiCredential) => action.payload),
    mergeMap((payload: any) => {
      let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.deactivateApiCredentials;
      return this.http.put<ApiCredential>(apiURL, {oid: payload.oid})
        .pipe(
          // If successful, dispatch success action with result
          map((response: any) => new DeactivateApiCredentialSuccess(response)),
          // If request fails, dispatch failed action
          catchError(err => of(new DeactivateApiCredentialFailed(err))),
        );
    })
  ));
}
