import {
  AfterViewInit,
  Component, EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import {TimePickerComponent} from '@niceltd/cxone-components/date-time-pickers';
import {DateUtils} from '../../../../common/utils/date';

const now = new Date();

@Component({
  selector: 'esp-operating-hours',
  templateUrl: './operating-hours.component.html',
  styleUrls: ['./operating-hours.component.css']
})
export class OperatingHoursComponent implements OnInit,OnChanges, AfterViewInit{
  @ViewChildren(TimePickerComponent) timePickers: QueryList<TimePickerComponent>;
  @Input() operatingHours;
  @Output() modelChanged = new EventEmitter<{model:any,isValid:boolean}>();

  startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
  days = {
    "SUNDAY": {value: '00:00', isValid: true},
    "MONDAY": {value: '00:00', isValid: true},
    "TUESDAY": {value: '00:00', isValid: true},
    "WEDNESDAY": {value: '00:00', isValid: true},
    "THURSDAY": {value: '00:00', isValid: true},
    "FRIDAY": {value: '00:00', isValid: true},
    "SATURDAY": {value: '00:00', isValid: true}
  }
  dayNames = Object.keys(this.days)
  constructor() { }

  ngOnInit(): void {


  }

  onTimeChanged($event: any, day:string) {
    if($event.isValid===true){
      this.days[day].value = $event.value;
    }
    this.days[day].isValid = $event.isValid;
    this.notifyModelChange();

    /*    setTimeout(()=>{
          let child = this.timePickers.toArray()[2];
          child.selectedTimeString = "--:--";
    },100)*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.operatingHours){
      this.setOperatingHours();
    }
  }
  ngAfterViewInit() {
    this.timePickers.toArray().forEach((timePicker,index)=>{
      let timeOption = {...timePicker.timeOptions[0]};
      timeOption.label = "24:00";
      timeOption.diff = "24:00";
      timePicker.timeOptions.push(timeOption);
    })
  }

  setOperatingHours() {
    if(this.operatingHours){
      Object.keys(this.days).forEach((day)=>{
        let isMultiValue = this.operatingHours[day] ==="--:--";
        this.days[day].value = isMultiValue?this.operatingHours[day]:DateUtils.convertMinutesToTimeString(this.operatingHours[day]);
        this.days[day].isValid = true;
      });
      setTimeout(()=>{
        this.timePickers.toArray().forEach((timePicker)=>{
          timePicker.selectedTimeString = this.days[timePicker.name].value;
        })
      },0)
    }
  }



  notifyModelChange() {
    let isValid = true;
    let model = {};
    Object.keys(this.days).forEach((day)=>{
      isValid = isValid && this.days[day].isValid;
      model[day]=this.days[day].value;
      if(model[day]!=="--:--" && isValid){
        model[day]=DateUtils.convertTimeStringToMinutes(model[day]);
      }
    });
    this.modelChanged.emit({model,isValid});
  }
}
