
export interface User {
  username: string;
  password?: string;
  isLoggedIn?: boolean;
  userName?: string;
  token?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export class UserData {
  espPermission: string;
  expirationTimestamp: number;
  firstName: string;
  lastName: string;
  language: string;
  locale: string;
  token: string;
  username: string;
  userRoles?: string[];
  publicApiFeature?: boolean;
}
