import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from "@ngrx/effects";
import { Observable, Subscription } from 'rxjs';
import { AppActionTypes, EditEntitySettings, EditPlanDetailReceived } from "src/app/store/actions";
import { DialogOptions } from 'src/app/common/dialog-options';
import { WfmModalComponent } from 'src/app/components/wfm-modal/wfm-modal.component';
import { EditEntitySettingsDto } from 'src/app/models/plan';
import {NumberUtils} from "src/app/common/utils/number.utils";
import {take} from "rxjs/operators";

@Component({
  selector: 'wfm-esp-plan-settings-ct-dialog',
  templateUrl: './plan-settings-ct-dialog.component.html',
  styleUrls: ['./plan-settings-ct-dialog.component.css']
})
export class PlanSettingsCtDialogComponent implements OnInit, OnDestroy {
  public _isLoading = false;
  public _enableCancel = true;
  public _enableAccept = false;
  public _showValidationError: boolean;

  private minStartingBacklog: number = 0;
  private maxStartingBacklog: number = 99999999;
  public startingBacklog = "0";
  protected subscriptionList: Array<Subscription> = [];
  public currentPlanDetail$: Observable<string>;
  private planId: string;
  private currentInterval: string;
  public selectedOid: string;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<any>,
    private action$: Actions,
    private modalService: NgbModal) {
    this.currentPlanDetail$ = this.store.select("state");
    this.addToSubscriptionList(
      this.currentPlanDetail$.pipe(take(1)).subscribe(this.currentPlanDetailHandler.bind(this))
    );
  }

  ngOnInit(): void {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailReceived))
        .subscribe(this.editPlanDetailReceiveHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditEntitySettingsError))
        .subscribe(this.editEntitySettingsErrorHandler)
    );
  }

  ngOnDestroy(): void {
    this.clearSubscriptionList();
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
    this.subscriptionList = [];
  }

  currentPlanDetailHandler(value: any) {
    if (value?.currentPlanDetail) {
      if (value.currentPlanDetail.settings?.startingBacklog) {
        this.startingBacklog = value.currentPlanDetail.settings?.startingBacklog;
      }

      this.planId = value.currentPlanDetail.planId;
      this.currentInterval = value.currentPlanDetail.interval;
    }
  }

  editPlanDetailReceiveHandler = (action: EditPlanDetailReceived) => {
    this._isLoading = false;
    this._enableCancel = true;
    this.activeModal.close(this.startingBacklog);
  };

  editEntitySettingsErrorHandler = (action: any) => {
    console.error(action);
    this.dismiss();
    const done = ()=>{};
    const dialogOpt: DialogOptions = new DialogOptions();
    dialogOpt.titleKey = "generic.err.title";
    dialogOpt.messageKey = "unrecoverable.err.msg";
    dialogOpt.msgType = "error";
    dialogOpt.confirmLabel = "btn.close";
    dialogOpt.showCancel = false;
    WfmModalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
  }

  startingBacklogUpdated(event) {
    this._showValidationError = false;
    const startingBacklog: string = String(event.target.value).trim();
    this._enableAccept = (startingBacklog.length > 0);
  }

  checkStartingBacklog(): boolean {
    const sb = Number(String(this.startingBacklog).trim());
    const isValid = NumberUtils.isIntegerNumber(this.startingBacklog)
      && sb >= this.minStartingBacklog && sb <= this.maxStartingBacklog;
    this._showValidationError = !isValid;
    return isValid;
  }

  onKeyDown(event) {
    const CHARS_ALLOWED = "12345674890";
    const KEYS_ALLOWED = [
      "Backspace", "Delete", "Home", "End", "Enter",
      "ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"
    ];


    const isNumber = CHARS_ALLOWED.indexOf(event.key) > -1;
    const isKeyCodeAllowed = KEYS_ALLOWED.indexOf(event.key) > -1;
    return isNumber || isKeyCodeAllowed;
  }

  submit() {
    this._showValidationError = false;
    if (this.checkStartingBacklog()) {
      this._enableCancel = false;
      this._enableAccept = false;
      this._isLoading = true;

      const payload: EditEntitySettingsDto = this.buildEditEntitySettingsDto();
      this.store.dispatch(new EditEntitySettings(payload));
    }
  }

  private buildEditEntitySettingsDto(): EditEntitySettingsDto {
    const ENTITY_TYPE = "CT";

    let dto: EditEntitySettingsDto = new EditEntitySettingsDto();
    dto.planId = this.planId;
    dto.entityType = ENTITY_TYPE;
    dto.entityOid = this.selectedOid;
    dto.interval = this.currentInterval;
    dto.startingBacklog = Number(String(this.startingBacklog).trim());

    return dto;
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
