import {Component, OnInit} from "@angular/core";
import {IHeaderGroupAngularComp} from "ag-grid-angular";
import {EventEmitter, Output} from "@angular/core";
import {PlanDetailHeaderBase} from "../base/plan-detail-header";
import {IHeaderParams} from 'ag-grid-community';

@Component({
  selector: "wfm-esp-plan-detail-header-row",
  templateUrl: "./plan-detail-header-row.component.html",
  styleUrls: ["./plan-detail-header-row.component.scss"]
})
export class PlanDetailHeaderRowComponent extends PlanDetailHeaderBase implements IHeaderGroupAngularComp {

  private collapsed = false;

  @Output()
  public drilldownIconClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  get isCollapsed() {
    return this.collapsed;
  }
  get isDisabled(){
    let params:any = this.params;

    //plan detail v2 doesn't have grid validity
    if(params.context.isGridValid && params.context.isGridValid()==false){
      return true;
    }

    return false;

  }
  toggleCollapsed() {
    let params:any = this.params;

    if (this.isDisabled) {
      return;
    }

    this.collapsed = !this.collapsed;
    this.drilldownIconClicked.emit(this.collapsed);

    params.context.updateViewMode(this.collapsed, params.columnGroup.children[0]);

  }
}
