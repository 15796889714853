import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'esp-multi-select-item',
  templateUrl: './multi-select-item.component.html',
  styleUrls: ['./multi-select-item.component.css']
})
export class MultiSelectItemComponent implements OnInit {

  @Input() item;

  constructor() { }

  ngOnInit(): void {
  }

}
