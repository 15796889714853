
export class PlanDetailCommonHelper {

  /*
   * Remove parameters associated to historical data.
   */
  static removeHistoricalDataParams(params) {
    delete params["ACTUAL_CONTACTS"];
    delete params["VARIANCE_CONTACTS"];

    delete params["ACTUAL_AHT"];
    delete params["VARIANCE_AHT"];

    return params;
  }

}
