// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The input text */
#component-container {
  position: relative;
}
#component-container .search-icon, #component-container .clear-search-icon {
  color: #b8b8b8;
  position: absolute;
  right: 3%;
  top: 23%;
  cursor: pointer;
  fill: currentColor;
}
#component-container .data-grid-search-input {
  padding: 8px 36px 8px 8px !important;
  clear: both !important;
  height: 38px !important;
  border: 1px solid #dae2e8 !important;
  box-sizing: border-box !important;
  border-width: 0px 0px 1px 0px !important;
  outline: none !important;
}
#component-container .data-grid-search-input::-ms-clear {
  display: none !important;
}

.b-inputtext {
  height: 40px;
  min-width: 220px;
  padding: 10px 10px 12px 10px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  border: solid 1px #d6dee5;
  box-sizing: border-box;
  outline: none;
  border-radius: 0px;
  width: 100%;
}
.b-inputtext.error {
  border-color: #d82132;
}
.b-inputtext.disabled {
  background-color: #eee;
  border-color: #e0e7ed;
  color: #9b9ea0;
}
.b-inputtext.disabled:hover {
  border-color: #e0e7ed;
}
.b-inputtext:hover, .b-inputtext:active, .b-inputtextfocus {
  border-color: #237bc2;
}`, "",{"version":3,"sources":["webpack://./src/app/components/input-text/input-text.component.scss"],"names":[],"mappings":"AAAA,mBAAA;AACA;EAEE,kBAAA;AAAF;AAEE;EACE,cAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAGE;EACE,oCAAA;EACA,sBAAA;EACA,uBAAA;EACA,oCAAA;EACA,iCAAA;EACA,wCAAA;EACA,wBAAA;AADJ;AAIE;EACE,wBAAA;AAFJ;;AAMA;EACE,YAAA;EACA,gBAAA;EACA,4BAAA;EACA,eAAA;EACA,sBAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;AAHF;AAKE;EACE,qBAAA;AAHJ;AAME;EACE,sBAAA;EACA,qBAAA;EACA,cAAA;AAJJ;AAMI;EACE,qBAAA;AAJN;AAQE;EACE,qBAAA;AANJ","sourcesContent":["/* The input text */\n#component-container {\n\n  position: relative;\n\n  .search-icon, .clear-search-icon {\n    color: #b8b8b8;\n    position: absolute;\n    right: 3%;\n    top: 23%;\n    cursor: pointer;\n    fill: currentColor;\n  }\n\n  .data-grid-search-input {\n    padding: 8px 36px 8px 8px !important;\n    clear: both !important;\n    height: 38px !important;\n    border: 1px solid #dae2e8 !important;\n    box-sizing: border-box !important;\n    border-width: 0px 0px 1px 0px !important;\n    outline: none !important;\n  }\n\n  .data-grid-search-input::-ms-clear {\n    display: none !important;\n  }\n}\n\n.b-inputtext {\n  height: 40px;\n  min-width: 220px;\n  padding: 10px 10px 12px 10px;\n  font-size: 14px;\n  background-color: #fff;\n  color: #333;\n  border: solid 1px #d6dee5;\n  box-sizing: border-box;\n  outline: none;\n  border-radius: 0px;\n  width: 100%;\n\n  &.error {\n    border-color: #d82132;\n  }\n\n  &.disabled {\n    background-color: #eee;\n    border-color: #e0e7ed;\n    color: #9b9ea0;\n\n    &:hover {\n      border-color: #e0e7ed;\n    }\n  }\n\n  &:hover, &:active, &focus {\n    border-color: #237bc2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
