import { Component, OnInit } from "@angular/core";

@Component({
  selector: "entity-field",
  templateUrl: "./entity-field.component.html",
  styleUrls: ["./entity-field.component.scss"]
})
export class EntityFieldComponent implements OnInit {
  private currentItem;
  private params;
  content: String;

  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    if (params && params.data) {
      this.currentItem = params.data;
      this.params = params;
      this.content = params.data.entityId + " " + params.data.entityName;
    }

  }
}
