export class ImportForecasts {
  createdBy:  string;
  createdTime: string;
  forecastName: string;
  importStatus: importStatus;
  s3Url: string;
  oid: string;
  modifiedTime:  string;
  startDate:  string;
  endDate:  string;
}

export class ImportForecastsListResponse {
  metadata: Array<ImportForecasts>;
  offset: number;
  limit: number;
  totalCount: number;
  sortBy: string;
  sortOrder: string;
}

export class ImportForecastDetailsResponse {
  finalStatus: boolean;
  exists: boolean;
  errorStatus: boolean;
}

enum importStatus {
  PENDING, PROCESSED, FAILED, DELETED, UPLOADING
}
