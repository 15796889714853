import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "wfm-esp-grid-item-action",
  templateUrl: "./grid-item-action.component.html",
  styleUrls: ["./grid-item-action.component.scss"]
})
export class GridItemActionComponent implements OnInit, ICellRendererAngularComp {

  private currentItem: any;
  private params: any;
  private callbackMethod: any;
  private messageDelete: string;

  @Output()
  deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  agInit(params: any) {
    this.currentItem = params.data;
    this.params = params;
    this.callbackMethod = params.callback;
    this.messageDelete = params.messageDelete;
  }

  emitData() {
    this.deleteClicked.emit(this.currentItem);
  }

  confirm(){
    this.callbackMethod(this.currentItem);
  }

  refresh(): boolean {
    return false;
  }
}
