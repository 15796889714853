import {Component, EventEmitter, Input, OnChanges, OnInit, Output, HostListener} from "@angular/core";
import { MenuItem, SubMenuItem } from "./types";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import { filter, map, take, tap } from "rxjs/operators";
import { fromEvent } from "rxjs";

export enum KEY_CODE {
  DOWN_ARROW = 40,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ENTER = 13,
  TAB = 9
}

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"]
})
export class SideNavComponent implements OnInit, OnChanges {
  @Input() menuTitle: string;
  @Input() inputMenuItems: MenuItem[];
  @Input() isMenuExpanded:boolean;
  @Input() tabIndex:number;
  @Output() onMenuItemClick: EventEmitter<any> = new EventEmitter();
  @Output() SidebarToggle:EventEmitter<any> = new EventEmitter<any>();

  activeItem: MenuItem = null;
  activeSubItem: SubMenuItem = null;
  focusedItem: MenuItem = null;
  focusedSubItem: SubMenuItem = null;
  routeTriggered: Boolean = false;
  keyboardTriggered: Boolean = false;
  keyupBlocked: Boolean = false;
  ariaActiveDescendant: string = null;
  isInFocus: boolean = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    // Set the active items based on the navigation route in case user refreshes page.
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.updateSelectedItem();
      });
  }

  ngOnChanges(){
    this.updateSelectedItem();
  }

  updateSelectedItem(){
    this.activeItem = null;
    this.activeSubItem = null;
    let route = this.router.url;
    let i = 0;
    let j = 0;
    if (route.startsWith("/")) {
      route = route.slice(1);
    }
    this.inputMenuItems.forEach((menuItem: MenuItem) => {
      menuItem.expanded = false;
      if (menuItem.route === route || route.indexOf(menuItem.route + "?")===0) {
        this.activeItem = menuItem;
      }
      menuItem.sequence = i;
      menuItem.id = "menuItem_" + i;
      if (menuItem.subItems) {
        j=0;
        menuItem.subItems.forEach((subItem: SubMenuItem) => {
          if (subItem.route === route || route.indexOf(subItem.route + "?")===0) {
            this.activeSubItem = subItem;
            this.activeItem = menuItem;
            menuItem.expanded = true;
          }
          subItem.sequence = j;
          subItem.id = menuItem.id + "_subItem_" + j;
          j++;
        });
      }
      i++;
    });
    this.setFocusedItemsToActiveItems();
    this.menuMouseLeave();
  }
  /**
   * Sets this menuItem as the active item, navigates if necessary, then emits an event.
   * If the menuItem item has a route and no sub-items, then it will navigate to the route.
   * @param event The click event
   * @param item The menuItem item to set as active.
   */
  async handleMenuEvent(item: MenuItem, event?: Event) {
    // If menuItem has sub-items, then toggle or set the expanded attribute.
    if(item.subItems){
      if (item.expanded) {
        this.toggleFocusedItemExpandCollapse(item, false);
      } else {
        this.inputMenuItems.forEach((menu: MenuItem) => {
          if (menu !== item) menu.expanded = false;
        });
        this.toggleFocusedItemExpandCollapse(item, true);
      }
      return;
    }

    if (!item.subItems && item.route) {
      this.ariaActiveDescendant = item.id;
      let result = await this.router.navigate([item.route]);
      if(result){
        this.collapseMenuAfterTriggeringRoute();
        return;
      } else {
        let self = this;
        self.keyupBlocked = true;
        setTimeout(function() {
          self.keyupBlocked = false;
        },500);
        return;
      }
    }

    this.onMenuItemClick.emit(item);
  }


  /**
   * Sets this sub-item to active, navigates if a route is present, then emits an event.
   * @param menuItem The parent menuItem item
   * @param event The click event
   * @param subItem The sub-item to set as active.
   */
  async handleSubMenuEvent(menuItem: MenuItem, subItem: SubMenuItem, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.ariaActiveDescendant = subItem.id;
    if (subItem.route) {
      this.inputMenuItems.forEach((menu: MenuItem) => {
        if (menu !== menuItem) menu.expanded = false;
      });
      let result = await this.router.navigate([subItem.route]);
      if(result) {
        this.activeItem = menuItem;
        this.activeSubItem = subItem;
        this.setFocusedItemsToActiveItems();
        this.collapseMenuAfterTriggeringRoute();
      }else{
        let self = this;
        self.keyupBlocked = true;
        setTimeout(function() {
          self.keyupBlocked = false;
        },500);
        return;
      }
    }

    if (subItem.event) {
      this.onMenuItemClick.emit(subItem);
    }
  }

  setFocusedItemsToActiveItems() {
    if (this.activeItem) {
      this.focusedItem = this.activeItem;
      this.focusedSubItem = this.activeSubItem;
    } else {
      this.focusedItem = this.inputMenuItems[0];
      this.focusedSubItem = null;
    }
  }

  menuMouseLeave() {
    if (!this.isMenuExpanded && !this.keyboardTriggered) {
      this.collapseAllSubMenus();
      this.focusedSubItem = null;
    }
  }

  collapseAllSubMenus(){
    this.inputMenuItems.forEach((menuItem: MenuItem) => {
      menuItem.expanded = false;
      if (menuItem.subItems) {
        menuItem.subItems.forEach((subItem: SubMenuItem) => {
            menuItem.expanded = false;
        });
      }
    });
  }

  collapseMenuAfterTriggeringRoute() {
    this.routeTriggered = true;
    let self = this;
    setTimeout(function() {
      const mouseMoves = fromEvent(document, "mousemove").subscribe((evt: MouseEvent) => {
        self.routeTriggered = false;
        mouseMoves.unsubscribe();
      });
    },500);
  }

  toggleMenuCollapse() {
    this.collapseMenuAfterTriggeringRoute();
    this.SidebarToggle.emit();
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.keyupBlocked) {
      this.handleKeyEvent(event.keyCode);
    }
  }

  handleKeyEvent(keyCode) {

    this.keyupBlocked = false;
    this.keyboardTriggered = true;
    this.routeTriggered = false;
    let newFocusId = "";
    let scrollUp = false;

    if (keyCode === KEY_CODE.RIGHT_ARROW) {

      // is the focused item at level 1?
      if (!this.focusedSubItem) {

        // does it have a subMenu?
        if (this.focusedItem.subItems) {

          // is its subMenu expanded?
          if (this.focusedItem.expanded) {

            // focus on its first child
            this.focusedSubItem = this.focusedItem.subItems[0];
            newFocusId = this.focusedSubItem.id;

          } else {

            // expand it
            this.toggleFocusedItemExpandCollapse(this.focusedItem,true);

          }

        } // if there's no subMenu, then do nothing

      } // if it is a subMenu item, then do nothing

    }

    if (keyCode === KEY_CODE.LEFT_ARROW) {

      scrollUp = true;

      // is the focused item at level 1?
      if (!this.focusedSubItem) {

        // does it have an expanded subMenu beneath it?
        if (this.focusedItem.subItems && this.focusedItem.expanded) {

          // is its subMenu expanded?
          if (this.focusedItem.expanded) {

            // collapse its subMenu
            this.toggleFocusedItemExpandCollapse(this.focusedItem, false);

          }
        } // if the subMenu is collapsed, do nothing

      } else {

        // focus on its parent
        this.focusedSubItem = null;
        newFocusId = this.focusedItem.id;
      }

    }

    if (keyCode === KEY_CODE.DOWN_ARROW) {

      // is the focused item at level 1?
      if (!this.focusedSubItem) {

        // does it have an expanded subMenu beneath it?
        if (this.focusedItem.subItems && this.focusedItem.expanded) {

          // focus on its first child
          let newFocusedSubItem = this.focusedItem.subItems.find(element => element.sequence == 0);
          if (newFocusedSubItem) {
            this.focusedSubItem = newFocusedSubItem;
            newFocusId = newFocusedSubItem.id;
          }

        } else {

          // focus on its downward level 1 neighbor
          let newSequence = this.focusedItem.sequence + 1;
          let newFocusedItem = this.inputMenuItems.find(element => element.sequence == newSequence)
          if (newFocusedItem) {
            this.focusedItem = newFocusedItem;
            newFocusId = this.focusedItem.id;
          }

        }

      } else {

        // is it the last child in its subMenu?
        if (this.focusedItem.subItems.length == (this.focusedSubItem.sequence + 1)) {

          // focus on its parent's downward level 1 neighbor
          let newSequence = this.focusedItem.sequence + 1;
          let newFocusedItem = this.inputMenuItems.find(element => element.sequence == newSequence)
          if (newFocusedItem) {
            this.focusedItem = newFocusedItem;
            this.focusedSubItem = null;
            newFocusId = this.focusedItem.id;
          }

        } else {

          // focus on its downward level 2 neighbor
          let newSequence = this.focusedSubItem.sequence + 1;
          let newFocusedSubItem = this.focusedItem.subItems.find(element => element.sequence == newSequence)
          if (newFocusedSubItem) {
            this.focusedSubItem = newFocusedSubItem;
            newFocusId = newFocusedSubItem.id;
          }

        }

      }

    }

    if (keyCode === KEY_CODE.UP_ARROW) {

      scrollUp = true;

      // is the focused item at level 1?
      if (!this.focusedSubItem) {

        // start by selecting its parent's upward level 1 neighbor
        let newSequence = this.focusedItem.sequence - 1;
        let newFocusedItem = this.inputMenuItems.find(element => element.sequence == newSequence)
        if (newFocusedItem) {

          // does that neighbor have an expanded subMenu above it?
          if (newFocusedItem.subItems && newFocusedItem.expanded) {

            // focus on that neighbor's last child
            let newFocusedSubItem = newFocusedItem.subItems.find(element => element.sequence == (newFocusedItem.subItems.length-1));
            if (newFocusedSubItem) {
              this.focusedItem = newFocusedItem;
              this.focusedSubItem = newFocusedSubItem;
              newFocusId = this.focusedSubItem.id;
            }

          } else {

            // leave the focus on that neighbor
            this.focusedItem = newFocusedItem;
            this.focusedSubItem = null;
            newFocusId = this.focusedItem.id;

          }

        }

      } else {

        // is it the first child in its subMenu?
        if (this.focusedSubItem.sequence == 0) {

          // focus on its parent
          this.focusedSubItem = null;
          newFocusId = this.focusedItem.id;

        } else {

          // focus on its upward level 2 neighbor
          let newSequence = this.focusedSubItem.sequence - 1;
          let newFocusedSubItem = this.focusedItem.subItems.find(element => element.sequence == newSequence)
          if (newFocusedSubItem) {
            this.focusedSubItem = newFocusedSubItem;
            newFocusId = newFocusedSubItem.id;
          }

        }

      }

    }

    if (keyCode === KEY_CODE.ENTER) {

      // is the focused item at level 1?
      if (!this.focusedSubItem) {

        // does it have a subMenu?
        if (this.focusedItem.subItems) {

          // expand or collapse its subMenu
          this.toggleFocusedItemExpandCollapse(this.focusedItem, !this.focusedItem.expanded);

        } else {

          // trigger what the link does when clicked
          this.handleMenuEvent(this.focusedItem);
          let menu = (document.querySelector('div.menu-items') as HTMLElement);
          if (menu) {
            menu.focus();
          }

        }

      } else {

        // trigger what the link does when clicked
        this.handleSubMenuEvent(this.focusedItem, this.focusedSubItem);
        let menu = (document.querySelector('div.menu-items') as HTMLElement);
        if (menu) {
          menu.focus();
        }

      }

    }

    if (keyCode == KEY_CODE.TAB) {
      this.ariaActiveDescendant = null;
      if (!this.ariaActiveDescendant) {
        let id = null;
        if (this.focusedItem) {
          if (this.focusedSubItem) {
            id = this.focusedSubItem.id;
          } else {
            id = this.focusedItem.id;
          }
          let self = this;
          self.ariaActiveDescendant = id;
        }
      }
    }

    if (newFocusId) {
      this.ariaActiveDescendant = newFocusId;
      let item = (document.querySelector('li#' + newFocusId) as HTMLElement);
      if (item) {
        this.scrollIntoViewIfOutOfView(item,scrollUp);
      }
    }

  }

  scrollIntoViewIfOutOfView(el, scrollUp) {
    var topOfPage = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    var heightOfPage = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var elY = 0;
    var elH = 0;
    for(var p=el; p&&p.tagName!='BODY'; p=p.offsetParent){
      elY += p.offsetTop;
    }
    elH = el.offsetHeight;
    let multiplier = (scrollUp ? 2 : 1);
    if ((topOfPage + heightOfPage) < (elY + (elH * multiplier))) {
      el.scrollIntoView({block: "end", inline: "nearest", behavior: "smooth"});
    }
  }

  onFocus() {
    this.isInFocus = true;
  }

  onFocusOut() {
    this.isInFocus = false;
  }

  toggleFocusedItemExpandCollapse(item, newValue) {
    item.expanded = newValue;
    let menu = (document.querySelector('div.menu-items') as HTMLElement);
    let self = this;
    if (menu) {
      setTimeout(function() {
        self.ariaActiveDescendant = item.id;
        menu.setAttribute("aria-activedescendant","");
        menu.setAttribute("aria-activedescendant",self.ariaActiveDescendant);
      },1);
    }
  }

}
