import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IconNameDirective} from "./icon-name.directive";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [IconNameDirective],
  exports: [IconNameDirective]
})
export class SvgIconsModule {
}
