// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  display:flex;
}
.custom-header{
  height:36px;
  display:flex;
  align-items: center;
  flex:1 1 auto;
  line-height:27px;
  overflow:hidden;
}
.rollup-header{
  margin-left:11px
}
.ag-group-expanded,.ag-group-contracted{
  display: inline-block;
  margin-left:11px;
}
.nhtp-link{
  margin-left:16px;
  flex-shrink: 0;
}
.header-title{
  overflow:hidden;
  display:flex;
}
.group-name{
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/mu-group-header/mu-group-header.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE;AACF;AACA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[":host{\n  display:flex;\n}\n.custom-header{\n  height:36px;\n  display:flex;\n  align-items: center;\n  flex:1 1 auto;\n  line-height:27px;\n  overflow:hidden;\n}\n.rollup-header{\n  margin-left:11px\n}\n.ag-group-expanded,.ag-group-contracted{\n  display: inline-block;\n  margin-left:11px;\n}\n.nhtp-link{\n  margin-left:16px;\n  flex-shrink: 0;\n}\n.header-title{\n  overflow:hidden;\n  display:flex;\n}\n.group-name{\n  overflow: hidden;\n  text-overflow: ellipsis;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
