

export class ValidateTenantUtils {
  public static username = null;
  public static tenant = null;
  public static recordTenant(subdomain: string) {
    ValidateTenantUtils.tenant = subdomain;
    ValidateTenantUtils.report();
  }
  public static recordUser(usernameHash){
    ValidateTenantUtils.username = usernameHash;
    ValidateTenantUtils.report();
  }
  public static report(){
    if ((window as any).FS &&
      ValidateTenantUtils.tenant) {
      (window as any).FS.setUserVars({
        tenant: ValidateTenantUtils.tenant
      })
    }

    if ((window as any).aptrinsic &&
      ValidateTenantUtils.tenant &&
      ValidateTenantUtils.username) {
      (window as any).aptrinsic('identify',{
        id: ValidateTenantUtils.tenant + "-"+ ValidateTenantUtils.username,
        firstName:ValidateTenantUtils.tenant,
        lastName:ValidateTenantUtils.username

      },{
        id: ValidateTenantUtils.tenant,
        name: ValidateTenantUtils.tenant
      })
    }
  }
}
