import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp, IHeaderAngularComp } from 'ag-grid-angular';
import {ICellRendererParams, IHeaderParams} from 'ag-grid-community/main';

@Component({
  selector: 'mu-group-header',
  templateUrl: './mu-group-header.component.html',
  styleUrls: ['./mu-group-header.component.css']
})
export class MuGroupHeaderComponent implements OnInit,ICellRendererAngularComp {
  private params: ICellRendererParams;
  private planSettings: any;

  constructor() { }

  public get isRollupHeader(){
    return !!this.params.data?.isRollupHeader;

  }
  public get newHireTrainingWeeks(){
    return this.params.context.planSettings()?.newHireTrainingWeeks
  }

  public get isRollupCollapsed(){
    if(this.params.context.isRollupCollapsed){
      return this.params.context.isRollupCollapsed();
    }else{
      return false;
    }

  }

  public toggleRollupGroup(){
    if(this.params.context.toggleRollupGroup && this.isRollupHeader) {
      this.params.context.toggleRollupGroup();
    }
  }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }


  showNhtpDialog(event) {
    event.preventDefault();
    this.params.context.showNhtpDialog();
  }
}
