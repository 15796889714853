import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'esp-multi-select-content',
  templateUrl: './multi-select-content.component.html',
  styleUrls: ['./multi-select-content.component.css']
})
export class MultiSelectContentComponent implements OnInit {
  @Input() items;
  @Input() emptyMessage;

  constructor() { }

  ngOnInit(): void {
  }

}
