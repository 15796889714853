// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .breeze .p-tooltip.validation-error{
  max-width: 250px;
}
::ng-deep .breeze .p-tooltip.validation-error .p-tooltip-arrow{
/*  border: 1px solid #d0d2d3;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background: white;
  border-width: 1px !important;*/
}
::ng-deep .breeze .validation-error.bs-popover-bottom.bs-popover-bottom-left{
  display: none;

}
::ng-deep .breeze .validation-error .message-text p{
  margin-bottom: 0;
}
::ng-deep .breeze .validation-error .message-text{
  font-family: "OpenSans", Arial, sans-serif;
  font-size: 12px;
  max-width: 300px;
  color: #2e2e2e;
  text-align: left;
  text-decoration: none;
  line-height: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-distribution/validation-indicator-cell-v2/validation-indicator-cell-v2.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;AACA;;;;;gCAKgC;AAChC;AACA;EACE,aAAa;;AAEf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,0CAA0C;EAC1C,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":["::ng-deep .breeze .p-tooltip.validation-error{\n  max-width: 250px;\n}\n::ng-deep .breeze .p-tooltip.validation-error .p-tooltip-arrow{\n/*  border: 1px solid #d0d2d3;\n  width: 10px;\n  height: 10px;\n  transform: rotate(45deg);\n  background: white;\n  border-width: 1px !important;*/\n}\n::ng-deep .breeze .validation-error.bs-popover-bottom.bs-popover-bottom-left{\n  display: none;\n\n}\n::ng-deep .breeze .validation-error .message-text p{\n  margin-bottom: 0;\n}\n::ng-deep .breeze .validation-error .message-text{\n  font-family: \"OpenSans\", Arial, sans-serif;\n  font-size: 12px;\n  max-width: 300px;\n  color: #2e2e2e;\n  text-align: left;\n  text-decoration: none;\n  line-height: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
