import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'esp-backlog',
  templateUrl: './backlog.component.html',
  styleUrls: ['./backlog.component.scss']
})
export class BacklogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ctSettings;
  @Output() modelChanged = new EventEmitter<{value:number,isValid:boolean}>();

  private backlogForm: FormGroup;
  private subscription: Subscription;

  constructor(private translateSrv: TranslateService, private fb: FormBuilder) {
    this.backlogForm = this.fb.group({
      backlogInput: new FormControl('0', this.minMaxValidator(0, 99999999))
    });
  }

  ngOnInit(): void {
    this.subscription = this.backlogForm.controls["backlogInput"].valueChanges.subscribe(value => {
      this.modelChanged.emit({
        value: this.backlogForm.controls["backlogInput"].value,
        isValid: this.backlogForm.controls["backlogInput"].valid
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      // console.debug("removing valueChanges subscription");
      this.subscription.unsubscribe();
    }
  }

  isWorkload() {
    return this.ctSettings?.workloadCt;
  }

  get params() {
    return {
      image: '2',
      getOverlayMsg: () => {
        return this.translateSrv.instant("plan.ct.settings.backlog.not.workload.msg");
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ctSettings) {
      this.setBacklog();
    }
  }

  private setBacklog() {
    if (this.ctSettings?.startingBacklog) {
      this.backlogForm.controls["backlogInput"].setValue(this.ctSettings.startingBacklog, {emitEvent: false});
    } else {
      this.backlogForm.controls["backlogInput"].setValue(0, {emitEvent: false});
    }
    // console.debug("setBacklog form-status:", this.backlogForm.status);
  }

  public get backlogInput() {
    return this.backlogForm.get("backlogInput").value;
  }

  public get invalidBacklogInput() {
    return this.backlogForm.get("backlogInput").status === "INVALID";
  }

  minMaxValidator(min, max): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valStr = String(control.value).trim();
      const valNum = Number(valStr);
      if (control.value === null || valStr === "" || !Number.isInteger(valNum)
        || valNum < min || valNum > max) {
        return {invalidBacklog: {value: control.value}};
      }

      return null;
    };
  }

  onInput(event: any) {
    this.backlogForm.controls["backlogInput"].setValue(event.value, {emitEvent: true});
  }
}
