import {Component, OnInit} from "@angular/core";

import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable} from "rxjs";
import {select, Store} from "@ngrx/store";
import {RequestSuccessful} from "../../store/actions";

@Component({
  selector: "refresh-prompt",
  templateUrl: "./refresh-prompt.component.html",
  styleUrls: ["./refresh-prompt.component.scss"],

})
export class RefreshPromptComponent implements OnInit {

  private tokenMinutesUntilExpiration$: Observable<number>;
  private tokenMinutesUntilExpiration: number;
  private paramMinutesUntilExpiration: Object;

  /**
   * Handle object creation and initialization
   */
  constructor(
    public activeModal: NgbActiveModal,
    public store: Store<any>
  ) {
    this.tokenMinutesUntilExpiration$ = this.store.select("state");
    this.tokenMinutesUntilExpiration$.subscribe((value: any) => {
      this.updateExpiration(value);
    });
  }

  /**
   * Lifecycle hook to handle additional
   */
  ngOnInit(): void {
  }

  /**
   * Update the session expiration information
   *
   * @param value - slice of ngrx store state
   */
  private updateExpiration(value: any) {
    if (value && value.user && value.user.username) {
      this.tokenMinutesUntilExpiration = value.tokenMinutesUntilExpiration;
      this.paramMinutesUntilExpiration = {x: this.tokenMinutesUntilExpiration};
    }
  }

  /**
   * Logout current user
   */
  logout() {
    // calling dismiss() triggers a user logout in AppComponent
    this.activeModal.dismiss();
  }

  /**
   * Allow the user to continue working by extending the session
   * if necessary.
   */
  continue() {
    this.store.dispatch(new RequestSuccessful(true));
    this.activeModal.close();
  }

}


