import {DatePipe, FormatWidth, getLocaleDateFormat} from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { getJSDocParameterTags } from "typescript";

@Component({
  selector: "date-range-field",
  templateUrl: "./date-range-field.component.html",
  styleUrls: ["./date-range-field.component.scss"]
})
export class DateRangeFieldComponent implements OnInit {
  private currentItem;
  private params;
  content: String;

  constructor() { }

  ngOnInit() {
  }

  agInit(params) {
    if (params && params.data) {
      let locale = (params.locale) ? params.locale : environment.defaultLocale;
      let dateFormat = (params.dateFormat) ? params.dateFormat : (environment.locales.find(l => l.locale === locale)).dateFormat;
      dateFormat = dateFormat?dateFormat:getLocaleDateFormat(locale, FormatWidth.Short);
      this.currentItem = params.data;
      this.params = params;
      //var offset = new Date().getTimezoneOffset() * 60 * 1000;
      if(params.data.startDate && params.data.endDate) {
        let datePipe = new DatePipe(locale);
        let sDateStr = datePipe.transform(new Date(params.data.startDate + "T00:00:00"), dateFormat);
        let eDateStr = datePipe.transform(new Date(params.data.endDate + "T00:00:00"), dateFormat);
        this.content = sDateStr + " - " + eDateStr;
      } else {
        this.content = "";
      }

    }
  }
}
