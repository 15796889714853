// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cell-span {
  background-color: white;
  border-bottom: 1px solid #c1bebe !important;
}

.table-list {
  border: 1px solid #c1bebe;
}

::ng-deep .groupedColumnHeader {
  font-size: 11px;
  font-weight: bold;
  color: #2E2E2E;
  text-transform: uppercase;
}

::ng-deep .ag-header-cell .ag-header-cell-label .ag-header-cell-text {
  margin-left: 5px !important;
}

.muSelectorDropDown {
  display: inline-block;
  width: 270px;
  text-align: left;
}

:host ::ng-deep .ag-header-cell {
  padding-right: 0px;
}

wfm-task-progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

::ng-deep wfm-esp-base-header {
  width: 100%;
}

.embedded-info {
  margin-left: 14px;
}

.toolbar-container {
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/plan-distribution/plan-distribution-v2.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,2CAAA;AACF;;AACA;EACE,yBAAA;AAEF;;AAGA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,2BAAA;AAAF;;AAGA;EACE,qBAAA;EACA,YAAA;EACA,gBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;AAAF;;AAEA;EACE,WAAA;AACF;;AAMA;EACE,iBAAA;AAHF;;AAKA;EACE,8BAAA;AAFF","sourcesContent":["::ng-deep .cell-span {\n  background-color: white;\n  border-bottom: 1px solid #c1bebe !important;\n}\n.table-list{\n  border: 1px solid #c1bebe;\n}\n::ng-deep .groupedColumnHeader .ag-header-group-text{\n  //margin-left: 5px;\n}\n::ng-deep .groupedColumnHeader{\n  font-size: 11px;\n  font-weight: bold;\n  color: #2E2E2E;\n  text-transform: uppercase;\n\n}\n::ng-deep .ag-header-cell .ag-header-cell-label .ag-header-cell-text{\n  margin-left: 5px !important;\n}\n\n.muSelectorDropDown{\n  display: inline-block;\n  width: 270px;\n  text-align: left;\n}\n\n:host ::ng-deep .ag-header-cell{\n  padding-right: 0px;\n}\n\nwfm-task-progress{\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1;\n}\n::ng-deep wfm-esp-base-header{\n  width:100%;\n}\n::ng-deep .ag-cell.read-only{\n\n}\n\n\n.embedded-info{\n  margin-left: 14px;\n}\n.toolbar-container{\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
