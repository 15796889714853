// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  font-size: 14px;
  min-height: 90px;
  min-width: 500px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 0px;
  line-height: 1.5;
}

textarea {
  resize: none;
  font-size: 12px;
  margin-top: 5px;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

::ng-deep .wfm-modal .modal-content {
  border-radius: 0;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
}
::ng-deep .wfm-modal .modal-header.warn {
  border-bottom: solid 1px #FC9537;
}
::ng-deep .wfm-modal .modal-header.error {
  border-bottom: solid 1px #fa3f40;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/imported-forecasts/imported-forecasts-view-log/imported-forecasts-view-log.component.scss"],"names":[],"mappings":"AACA;EAEE,2CAAA;EACA,gCAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,YAAA;EACA,eAAA;EACA,eAAA;AADF;;AAIA;EACE,2CAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;AADF;;AAIA;EACE,iBAAA;EACA,6BAAA;AADF;;AAKE;EACE,gBAAA;EACA,yBAAA;AAFJ;AAKE;EACE,YAAA;EACA,mBAAA;AAHJ;AAKI;EACE,gCAAA;AAHN;AAMI;EACE,gCAAA;AAJN;AASE;EACE,YAAA;EACA,6BAAA;AAPJ","sourcesContent":["\n.modal-header {\n  // padding: 12px 20px 11px 20px;\n  font-family: \"Open Sans\", Arial, sans-serif;\n  border-bottom: 1px solid #DAE2E8;\n  border-radius: 0;\n}\n\n.modal-body {\n  font-size: 14px;\n  min-height: 90px;\n  min-width: 500px;\n  background-color: #e9f1f5;\n  padding: 20px;\n  margin-left: 0px;\n  line-height: 1.5;\n}\n\ntextarea {\n  resize: none;\n  font-size: 12px;\n  margin-top: 5px;\n}\n\n.modal-title {\n  font-family: \"Open Sans\", Arial, sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: normal;\n  color: #404040;\n  margin-left: 5px;\n}\n\n.modal-footer {\n  padding: 12px 8px;\n  border-top: solid 1px #d0d5d9;\n}\n\n::ng-deep .wfm-modal {\n  .modal-content {\n    border-radius: 0;\n    border: 1px solid #DAE2E8;\n  }\n\n  .modal-header {\n    height: 80px;\n    align-items: center;\n\n    &.warn {\n      border-bottom: solid 1px #FC9537;\n    }\n\n    &.error {\n      border-bottom: solid 1px #fa3f40;\n    }\n\n  }\n\n  .modal-footer {\n    height: 80px;\n    border-top: solid 1px #d0d5d9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
