// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-container{
  border:1px solid rgba(193, 190, 190, 0.49);
  justify-content:space-between;
  align-items:center;
  padding: 0 10px;
}

cxone-tabs {
  display:block;
  height: calc(100vh - 236px);
  /*border:1px solid #c1bebe;*/
  margin-top:16px;
}

p-multiSelect {
  min-width:250px;
}

.esp-tab {
  padding: 20px;
  height: 100%;
}

wfm-plan-loading-overlay {
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 50;
}

.loading-error {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 180px);
  font-size: larger;
  box-shadow: 0 2px 6px #1f324d33;
  overflow: hidden;
}

span.err-msg {
  padding: 10px;
}
wfm-task-progress{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.no-op-hrs,
.no-fte-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: none;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/ct-settings/ct-settings.component.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,iBAAiB;EACjB,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,UAAU;AACZ;;AAEA;;EAEE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;AACd","sourcesContent":[".toolbar-container{\n  border:1px solid rgba(193, 190, 190, 0.49);\n  justify-content:space-between;\n  align-items:center;\n  padding: 0 10px;\n}\n\ncxone-tabs {\n  display:block;\n  height: calc(100vh - 236px);\n  /*border:1px solid #c1bebe;*/\n  margin-top:16px;\n}\n\np-multiSelect {\n  min-width:250px;\n}\n\n.esp-tab {\n  padding: 20px;\n  height: 100%;\n}\n\nwfm-plan-loading-overlay {\n  background: white;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  z-index: 50;\n}\n\n.loading-error {\n  background: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: calc(100vh - 180px);\n  font-size: larger;\n  box-shadow: 0 2px 6px #1f324d33;\n  overflow: hidden;\n}\n\nspan.err-msg {\n  padding: 10px;\n}\nwfm-task-progress{\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1;\n}\n\n.no-op-hrs,\n.no-fte-settings {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  flex: none;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
