import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit} from "@angular/core";

export class ValidationResult {
  message?: string;

  constructor(msg: string) {
    this.message = msg;
  }
}

@Component({
  selector: "wfm-textbox",
  templateUrl: "./wfm-textbox.component.html",
  styleUrls: ["./wfm-textbox.component.scss"]
})
export class WfmTextboxComponent implements OnInit, AfterViewInit {
  @Input() required = false;
  @Input() label = null;
  @Input() textValue = "";
  @Input() placeHolder = "";
  @Input() validators: Array<(value: any) => ValidationResult>;
  @Input() serverSideErrMsgs: Array<string>;
  @Input() clientSideErrMsgs: Array<string>;
  @Input() showMultilineError: Boolean;
  @Input() onEventHideServerSideError = true;
  @Input() inlineStyleObject = {};
  @Input() wfmAutoComplete = "on";
  @Input() inputId = null;
  @Input() inputName = null;
  @Input() inputType = "text";
  @Input() maxValue = null;
  @Input() minValue = null;
  @Input() maxLength: Number = null;

  @Input() hasFocus = false;

  untouchedInput = true;

  get untouched() {
    return this.untouchedInput;
  }

  get isValid() {
    if (this.untouched) {
      return true;
    }
    return (
      this.serverSideErrMsgs.length < 1 && this.clientSideErrMsgs.length < 1
    );
  }

  @Output() wfmTextboxEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputOnBlur: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputOnInput: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("input") inputEl: ElementRef;

  constructor() {
    this.validators = [];
    this.serverSideErrMsgs = [];
    this.clientSideErrMsgs = [];
    this.showMultilineError = false;
  }

  ngOnInit() {
    
  }

  ngAfterViewInit():void {
    if(this.hasFocus){
      this.inputEl.nativeElement.focus();
    }
  }

  validate() {
    // if (this.serverSideErrMsgs.length > 0) {
    //   this.serverSideErrMsgs = [];
    // }
    if (this.clientSideErrMsgs.length > 0) {
      this.clientSideErrMsgs = [];
    }
    this.validators.forEach(validator => {
      const result: ValidationResult = validator.call(
        this,
        this.inputEl.nativeElement.value
      );
      if (result.message !== null && result.message !== "") {
        this.clientSideErrMsgs.push(result.message);
      }
    });
    return this.isValid;
  }

  onBlur(event) {
    if (this.validators.length > 0) {
      this.updateValue(event);
    }
    this.inputOnBlur.emit(event);
    this.hasFocus = false;
  }

  onFocus(event) {
    this.hasFocus = true;
  }

  updateValue(event) {
    this.untouchedInput = false;
    this.validate();
    this.textValue = event.target.value;
    this.wfmTextboxEvent.emit(event);
    this.inputOnInput.emit(event);
  }

  hasErrorMsg() {
    if (this.serverSideErrMsgs.length > 0) {
      return true;
    } else if (!this.untouchedInput && this.clientSideErrMsgs.length > 0) {
      return true;
    }
    return false;
  }

  getSingleError() {
    if (this.serverSideErrMsgs.length > 0) {
      return this.serverSideErrMsgs[0];
    } else if (this.clientSideErrMsgs.length > 0) {
      return this.clientSideErrMsgs[0];
    }
    return null;
  }

  get hasServerError() {
    return this.serverSideErrMsgs.length > 0;
  }

  get hasClientError() {
    return this.clientSideErrMsgs.length > 0;
  }

  setFocus() {
    this.inputEl.nativeElement.focus();
  }

  setValue(value: string) {
    this.untouchedInput = false;
    this.textValue = value;
    this.validate();
  }
}
