import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Actions, ofType} from "@ngrx/effects";
import {MessageService} from "primeng/api";
import {AppActionTypes, EditPlanDetail, EditPlanDetailReceived} from "../../../store/actions";
import {EditPlanDetailRequestDTO, EntityType} from "../../../models/plan";
import {DialogOptions} from "../../../common/dialog-options";
import {WfmModalComponent} from "../../../components/wfm-modal/wfm-modal.component";

@Component({
  selector: "wfm-esp-new-hire-dialog",
  templateUrl: "./new-hire-dialog.component.html",
  styleUrls: ["./new-hire-dialog.component.scss"],
  providers: [DatePipe, MessageService]

})
export class NewHireDialogComponent implements OnInit, OnDestroy {


  public static readonly NEW_HIRE_TRAINING_PERIOD = "NEW_HIRE_TRAINING_PERIOD";

  private state$: Observable<any>;
  private token: String;
  private newHireTrainingWeeks = "";
  private prevNewHireTrainingWeeks = "";
  private planId: string;
  private planEntityType: string;
  private interval: string;
  private selectedType: string;
  private selectedOid: string;
  private intervalStart: string;
  private intervalEnd: string;
  private newHireTrainingWeeksMin = 0;
  private newHireTrainingWeeksMax = 52;
  public _isLoading = false;
  public _enableAccept = false;
  public _enableCancel = true;
  public _showValidationError: boolean;
  protected subscriptionList: Array<Subscription> = [];

  planSettingsForm: FormGroup;

  public currentPlanDetail$: Observable<string>;

  @Input()
  public currentContact = null;
  @Input()
  public planVersion: number = 1;

  public canEdit:boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private store: Store<any>,
    private action$: Actions,
    private messageService: MessageService,
    private modalService: NgbModal) {
    this.currentPlanDetail$ = this.store.select("state");
    this.addToSubscriptionList(
      this.currentPlanDetail$.subscribe(this.currentPlanDetailHandler.bind(this))
    );
  }

  close(result) {
    this.activeModal.close(result);
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  private labelKeys = {
    newHireTrainingWeeks: "plan.settings.newhiretraining"
  };

  clearable = false;

  @Input() dataToTakeAsInput: any;

  ngOnInit(): void {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailReceived))
        .subscribe(this.editPlanDetailReceiveHandler)
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditNewHireTrainingError))
        .subscribe(this.editNHTErrorHandler.bind(this))
    );

    this.init();
  }

  private init(): void {
    if (this.planVersion === 2) {
      this.canEdit = this.currentContact?.type === EntityType.MU;
      if (this.canEdit) {
        this.selectedType = EntityType.MU;
      }
    } else {
      this.canEdit = !!this.selectedOid || this.planEntityType === EntityType.CT;
      if (this.canEdit) {
        this.selectedType = EntityType.CT;
      }
    }

    this.prevNewHireTrainingWeeks = this.newHireTrainingWeeks;

    if (!this.selectedType) {
      this.newHireTrainingWeeks = "";
    } else if (this.canEdit && this.newHireTrainingWeeks === "") {
      this.newHireTrainingWeeks = "0";
    }

    console.debug("contactType:" + this.selectedType
      + " selectedOid:" + this.selectedOid
      + " NHTP:" + this.newHireTrainingWeeks
      + " planVersion:" + this.planVersion);
  }

  ngOnDestroy(): void {
    this.clearSubscriptionList();
  }

  currentPlanDetailHandler(value: any) {
    let isNotDirty = (this.prevNewHireTrainingWeeks === "");
    if (value && value.currentPlanDetail) {
      console.debug(value);
      if (value.currentPlanDetail.settings?.newHireTrainingWeeks > 0 && isNotDirty) {
        this.newHireTrainingWeeks = value.currentPlanDetail.settings.newHireTrainingWeeks;
      }

      this.intervalStart = value.currentPlanDetail.settings?.startDate;
      this.intervalEnd = value.currentPlanDetail.settings?.endDate;
      this.planId = value.currentPlanDetail.planId;
      this.interval = value.currentPlanDetail.interval;
      this.selectedOid = value.currentPlanDetail.selectedOid;
      this.planEntityType = value.currentPlanDetail.entityType;
    }
  }

  editPlanDetailReceiveHandler = (action: EditPlanDetailReceived) => {
    this._isLoading = false;
    if (action.payload === null) { // remove if block
      this._enableAccept = true;
      this._enableCancel = true;
      let failureMsg = this.translate.instant("plan.settings.weeks.failure");
      this.messageService.add({severity: "error", detail: failureMsg});
    } else {
      this.close(this.newHireTrainingWeeks);
    }
  };

  editNHTErrorHandler(err:any){
    console.error(err);
    this.dismiss();
    const done = ()=>{};
    const dialogOpt:DialogOptions = new DialogOptions();
    dialogOpt.titleKey = "generic.err.title";
    dialogOpt.messageKey = "unrecoverable.err.msg";
    dialogOpt.msgType = "error";
    dialogOpt.confirmLabel = "btn.close";
    dialogOpt.showCancel = false;
    WfmModalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
  }

  getLabelByFieldName(fieldName: string): Observable<any> {
    return this.translate.get(this.labelKeys[fieldName]);
  }

  changedNewHireTrainingWeeks(e) {
    this._showValidationError = false;
    const weeks = e.target.value;
    if (weeks !== "") {
      this._enableAccept = true;
    } else {
      this._enableAccept = false;
    }
  }

  checkNewHireTrainingWeeks() {
    const weeks = Number(this.newHireTrainingWeeks);

    if(this.canEdit==false){
      return true;
    }

    if (String(this.newHireTrainingWeeks).trim() === ""
      || Number.isNaN(weeks)
      || weeks < this.newHireTrainingWeeksMin
      || weeks > this.newHireTrainingWeeksMax
      || !Number.isInteger(weeks)) {
      this._showValidationError = true;
      return false;
    }
    return true;
  }

  submit() {
    if (this._enableAccept) {
      this._showValidationError = false;
      if (this.checkNewHireTrainingWeeks()) {
        this._enableAccept = false;
        this._enableCancel = false;
        this._isLoading = true;
        const payload: EditPlanDetailRequestDTO = {
          planId: this.planId,
          startDate: this.intervalStart,
          endDate: this.intervalEnd,
          interval: this.interval,
          planParameterLabel: NewHireDialogComponent.NEW_HIRE_TRAINING_PERIOD,
          editedValue: String(this.newHireTrainingWeeks),
          previousValue: String(this.prevNewHireTrainingWeeks),
          ctOid: this.selectedType == EntityType.CT ? this.selectedOid : null,
          muOid: this.selectedType == EntityType.MU ? this.selectedOid : null
        };
        this.store.dispatch(new EditPlanDetail(payload));
      }
    }
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

}
