import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {DetailViewMode} from "../plan-detail/plan-detail.component";
import {PlanDetailChartComponent} from "../plan-detail-chart/plan-detail-chart.component";
import {
  trigger,
  state,
  style,
  animate,
  transition, group,
  // ...
} from "@angular/animations";
import {Features} from '../../../models/plan';
import {AuthenticationService} from '../../../authentication.service';

let animations = [
  trigger("paramsAnimation", [
    transition(":enter", [
      style({ transform: "translateX(100%)",opacity:1}),
      group([animate("250ms ease-out", style({ transform: "!" ,opacity:"!"}))]),
    ]),
    transition(":leave", [
      style({ transform: "!",opacity:"!"}),
      group([animate("250ms ease-out", style({ transform: "translateX(100%)",opacity:0 }))]),
    ])
  ])
];


@Component({
  selector: "plan-multi-chart",
  templateUrl: "./plan-multi-chart.component.html",
  styleUrls: ["./plan-multi-chart.component.scss"],
  animations
})
export class PlanMultiChartComponent implements OnInit,OnChanges,AfterViewInit {

  @ViewChild("chart0") chart0: PlanDetailChartComponent;
  @ViewChild("chart1") chart1: PlanDetailChartComponent;
  @ViewChild("chart2") chart2: PlanDetailChartComponent;
  @ViewChild("chart3") chart3: PlanDetailChartComponent;

  @Input() gridContext;
  @Output() drillDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() rollUp: EventEmitter<any> = new EventEmitter<any>();
  @Input() rowData;
  @Input() isLoading;
  @Input() columnDefs;
  @Input() isVisible = false;
  @Input() viewMode;
  @Input() contactType;
  @Input() chartsCount=4;
  @Input() locale = "en-US";
  @Input() isV1 = false;

  currentParamsList = 0;
  paramsList = []
  charts=[];
  sidebar=["Hidden","Hidden","Hidden","Hidden"];

  isSidebarOpen:boolean;
  refreshTimer = null;

  constructor(private authService: AuthenticationService) {

  }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    this.charts=[this.chart0,this.chart1,this.chart2,this.chart3];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isVisible"] && changes["isVisible"].currentValue == "false") {
      this.sidebar = Array(4).fill("Hidden");
      this.isSidebarOpen = false;
    }
    if(changes["chartsCount"]){
      this.onCloseParams();
    }
    if(changes["isLoading"]){
      this.onCloseParams();
    }
  }
  onParamsListChanged(params,chartId){
    this.paramsList[chartId]=params;
  }

  onParamSelectionChange(item){
    this.charts[this.currentParamsList].onParamSelectionChange(item);
  }

  updateViewMode(e){
    if(e===false){
      this.drillDown.emit();
    }else{
      this.rollUp.emit();
    }
  }
  onDisplayParams(id){
    this.currentParamsList=id;
    this.isSidebarOpen=true;
    this.closeTooltips();
  }

  onCloseParams(){
    this.currentParamsList=0;
    this.isSidebarOpen=false;
  }

  onPointHovered(source,$event: any) {
    this.charts.forEach((chart)=>{
      if(chart!=source){
        chart.highlight($event);
      }
    })

  }

  onPointOut(source, $event: any) {
    this.charts.forEach((chart)=>{
      if(chart!=source){
        chart.removeHighlight($event);
      }
    })
  }

  onSetExtremes($event: any) {

    this.chart0.syncExtremes($event);
    this.chart1.syncExtremes($event);
    this.chart2.syncExtremes($event);
    this.chart3.syncExtremes($event);
  }

  closeTooltips(){
    this.charts.forEach((chart)=>{
      chart.hideTooltips();
    })
  }

}
