// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{
  width:100%;
  height:100%;
}
label{
  font-size: 13px;
  margin-left: 14px;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  padding-right: 20px;
  margin-bottom: 0;
}
.item-separator{
  border-bottom: 1px solid #d9d9d9;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/single-select-item/single-select-item.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,gCAAgC;AAClC","sourcesContent":[":host{\n  width:100%;\n  height:100%;\n}\nlabel{\n  font-size: 13px;\n  margin-left: 14px;\n  max-width: 100%;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  display: inline-block;\n  padding-right: 20px;\n  margin-bottom: 0;\n}\n.item-separator{\n  border-bottom: 1px solid #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
