import {EventEmitter, HostListener, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResizeAwareService {
  resizeTimer = null;
  Resized =  new EventEmitter<any>();
  constructor() {

  }

  //debounce calls
  NotifyResize(){
    if(this.resizeTimer){
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer=setTimeout(()=>{
      this.Resized.emit();
      this.resizeTimer=null;
    },100)

  }
}
