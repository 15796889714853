export class DialogOptions {

   titleKey = "dialog.title";
   messageKey = "dialog.msg";
   cancelDisable = false;
   okDisable = false;
   showCancel = true;
   cancelLabel = "btn.cancel.label";
   confirmLabel = "btn.confirm.label";
   msgType = "warn";
   container = "div.dialog-container";
}
