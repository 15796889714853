import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";

export interface CheckboxListItem {
  display:String,
  isChecked:boolean,
  data?:any,
  isNested:boolean,
  isPrimary:boolean
}

@Component({
  selector: "checkbox-list",
  templateUrl: "./checkbox-list.component.html",
  styleUrls: ["./checkbox-list.component.scss"]
})
export class CheckboxListComponent implements OnInit {

  @Input() items:CheckboxListItem[];

  @Output() itemChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  handleClick($event,item){
    item.isChecked=!item.isChecked;
    this.itemChanged.emit(item);
  }
  handlePrimary($event,item){
    $event.stopPropagation();
    item.isPrimary=!item.isPrimary
    this.itemChanged.emit(item);
  }
}
