// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.first-row {
  padding-top: 10px;
}

.last-row {
  padding-top: 20px;
}

.read-only-row {
  padding-top: 15px;
}

::ng-deep .wfm-modal.api-keys {
  z-index: 1062;
}

::ng-deep .api-keys-dialog.modal-dialog .modal-content {
  width: auto;
}

.api-credentials .modal-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

breeze-button#generate-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.modal-body {
  margin-left: 0;
  width: 470px;
}

.embedded-warning {
  font-size: 14px;
  margin: 0 0 -6px 0;
}

::ng-deep .popover.deactivate-popup .button-response button {
  margin-right: 0;
  margin-left: 10px;
}

::ng-deep .popover.deactivate-popup .message-text p {
  color: black;
}

::ng-deep .popover.deactivate-popup {
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/api-credential/api-credential.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,QAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".first-row {\n  padding-top: 10px;\n}\n\n.last-row {\n  padding-top: 20px;\n}\n\n.read-only-row {\n  padding-top: 15px;\n}\n\n::ng-deep .wfm-modal.api-keys {\n  z-index: 1062;\n}\n\n::ng-deep .api-keys-dialog.modal-dialog .modal-content {\n  width: auto;\n}\n\n.api-credentials .modal-buttons {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n}\n\nbreeze-button#generate-btn {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n}\n\n.modal-body {\n  margin-left: 0;\n  width: 470px;\n}\n\n.embedded-warning {\n  font-size: 14px;\n  margin: 0 0 -6px 0;\n}\n\n::ng-deep .popover.deactivate-popup .button-response button {\n  margin-right: 0;\n  margin-left: 10px;\n}\n\n::ng-deep .popover.deactivate-popup .message-text p {\n  color: black;\n}\n\n::ng-deep .popover.deactivate-popup {\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
