import {Component} from "@angular/core";
import {IHeaderAngularComp} from "ag-grid-angular";
import {IHeaderParams} from "ag-grid-community/main";
import {PlanDetailHeaderBase} from "../base/plan-detail-header";

@Component({
  selector: "plan-detail-clickable-header",
  templateUrl: "./plan-detail-clickable-header.component.html",
  styleUrls: ["./plan-detail-clickable-header.component.scss"]
})
export class PlanDetailClickableHeaderComponent extends PlanDetailHeaderBase implements IHeaderAngularComp {

  constructor() {
    super();
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  emitHeader(event) {
    if(this.isDisabled){
      return;
    }

    let params:any = this.params;
    params.context.updateViewMode(false, params.column);

  }

  get isDisabled(){
    let params:any = this.params;

    //plan detail v2 doesn't have grid validity
    if(params.context.isGridValid && params.context.isGridValid()==false){
      return true;
    }

    return false;

  }
  refresh(params: IHeaderParams): boolean {
    return false;
  }
}
