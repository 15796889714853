import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ElementRef} from "@angular/core";

import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ValidationResult, WfmTextboxComponent} from "../wfm-textbox/wfm-textbox.component";
import {SelectItem, WfmSelectComponent} from "../wfm-select/wfm-select.component";
import {CreatePlanRequest, Features} from "../../models/plan";
import {FormBuilder, FormGroup} from "../../../../node_modules/@angular/forms";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {CreatePlanService} from "./create-plan.service";
import {DatePipe, FormatWidth, getLocaleDateFormat} from "@angular/common";
import {appSettings} from "src/app/app-settings";
import { AuthenticationService } from "src/app/authentication.service";
import { PlanHelper } from "src/app/helpers/plan-helper";
import { Store } from "@ngrx/store";
import  * as appActions from "src/app/store/actions";

@Component({
  selector: "create-plan",
  templateUrl: "./create-plan.component.html",
  styleUrls: ["./create-plan.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreatePlanService, DatePipe]
})
export class CreatePlanComponent implements OnInit, AfterViewInit, OnDestroy {

  private state$: Observable<any>;
  private token: String;

  createPlanForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, 
    private fb: FormBuilder, 
    private planService: CreatePlanService, 
    private datePipe: DatePipe,
    private translate: TranslateService,
    private store: Store<any>,
    private authService: AuthenticationService) {
    this.planModel = {planName: "", entityType: "", entity: "", forecast: ""};
  }

  createPlan() {
    let result = this.submit();
    this.close(result);
  }

  close(result) {
    console.log("close");
    this.activeModal.close(result);
  }

  dismiss() {
    console.log("dismiss");
    this.activeModal.dismiss();
  }

  private labels = {
    planName: "Plan Name",
    entityType: "Entity Type",
    entity: "Entity",
    forecastLabel: "Long-Term Forecast"
  };

  private labelKeys = {
    planName: "plan.label.name",
    entityType: "plan.label.entitytype",
    entity: "plan.label.entity",
    forecastLabel: "plan.label.forecast"
  };

  entityTypeList:string[] = ["Contact Type"];
  entityTypeKeys: string[] = ["ct", "ctset", "bu", "buset", "eg", "egset"];

  clearable = false;
  planModel: CreatePlanRequest;
  selectedEntity: SelectItem = null;
  selectedForecast: SelectItem = null;

  @ViewChild("planName") planNameEl: WfmTextboxComponent;
  @ViewChild("entityType") entityTypeEl: WfmSelectComponent;
  @ViewChild("entity") entityEl: WfmSelectComponent;
  @ViewChild("forecast") forecastEl: WfmSelectComponent;


  ngOnInit(): void {
    this.createPlanForm = this.fb.group({});
    this.entityTypeList = this.entityTypeList.concat(["Contact Type Set", "Business Unit", "Business Unit Set", "Enterprise Group", "Enterprise Group Set"]);    
  }

  ngAfterViewInit(): void {
    this.planNameEl.validators.push(this.planNameValidator);
  }

  ngOnDestroy(): void {

  }

  getLabelByFieldName(fieldName: string): Observable<any> {
    return this.translate.get(this.labelKeys[fieldName]);

  }

  getEntityLabel(): Observable<any> {
    var index = this.entityTypeList.indexOf(this.planModel.entityType);
    var entityTypeKey = this.entityTypeKeys[index];
    if (entityTypeKey) {
      return this.translate.get(this.labelKeys["entity"] + "." + entityTypeKey);
    } else {
      return this.translate.get(this.labelKeys["entity"]);
    }

  }

  setPlanName(str: string) {

    this.planModel.planName = str;

  }

  hasValidPlanName() {
    return this.planModel.planName.trim() !== "";
  }

  checkingUniquePlanName = false;

  checkPlanNameExists(event: any) {
    this.checkingUniquePlanName = true;
    const planName = event.target.value;
    const successHandler = (res: any) => {
      this.planNameEl.serverSideErrMsgs = [];
      if (res.status === 200) {
        this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
      }// returns 204 if unique
      this.planNameEl.wfmTextboxEvent.emit(event);
      this.checkingUniquePlanName = false;
    }
    const failHandler = (err: any) => {
      //this.dismiss();
      this.store.dispatch(new appActions.UnrecoverableError(err));
      
    }
    if (this.hasValidPlanName())
      this.planService.planExists(planName).subscribe(successHandler, failHandler);
  }

  get entityTypeDisabled() {
    const enable = this.hasValidPlanName();
    if (!enable && this.entityTypeEl) {
      this.planModel.entityType = null;
      this.entityTypeEl.clear();
    }
    return !enable;
  }


  _entityDisabled = true;

  get entityDisabled() {
    const enable = this.planModel.entityType != null && this.planModel.entityType !== "" && !this.entityTypeDisabled;
    if (!enable && this.entityEl) {
      this.entityEl.label = "Entity";
      this.planModel.entity = "";
      this.entityEl.clear();
    }
    this._entityDisabled = !enable;
    return this._entityDisabled;
  }

  _forecastDisabled = true;
  get forecastDisabled() {
    const enable = this.planModel.entity !== "" && this.planModel.entity !== null;
    if (!enable && this.forecastEl) {
      this.planModel.forecast = "";
      this.forecastEl.clear();
    }
    this._forecastDisabled = !enable;
    return this._forecastDisabled;
  }

  _currEntityList: Array<SelectItem> = new Array<SelectItem>();

  get entityList() {
    return this._currEntityList;
  }

  updateEntityType(entityType) {
    if (this.planModel.entityType !== entityType) {
      this._entityDisabled = true;

      this.planModel.entityType = entityType;
      this.loadEntityList();
      this.updateEntity(null);
    }

  }

  _entityListLoading = false;
  get entityListLoading() {
    return this._entityListLoading;
  }

  loadEntityList() {

    var index = this.entityTypeList.indexOf(this.planModel.entityType);
    var entityTypeKey = this.entityTypeKeys[index];
    if (entityTypeKey) {
      console.log(environment.espEndpointBaseUrl + appSettings.resourceLocations.entityList + `/${entityTypeKey}`);


      this._entityListLoading = true;
      const successHandler = (data: any) => {
        this._currEntityList = new Array<SelectItem>();
        data.entityList.forEach(e => {
          var selectLabel: string = e.id + "  " + e.name;
          var item: SelectItem = {label: selectLabel, value: e.id, object: e};
          this._currEntityList.push(item);

        });
        this._entityListLoading = false;
        this.entityEl.showLoadingIcon(false);
      };
      const errorHandler = (error: any) => {
        console.log(error);
        if (error.status === 500 || error.status === 401) {
          this.store.dispatch(new appActions.UnrecoverableError(error));
          this.dismiss();
        }        
      }; // error path
      this.planService.getEntityList(entityTypeKey).subscribe(successHandler, errorHandler);

    }
  }

  updateEntity(p: SelectItem) {
    this.selectedEntity = p;
    var entity = p ? p.object : null;
    if (this.planModel.entity !== entity) {
      this.planModel.entity = entity;
      if (this.planModel.entity) {
        this.loadForecastList();
      }
    }

    this.updateForecast(null);
  }

  _currForecastList: Array<SelectItem> = new Array<SelectItem>();

  get forecastList() {
    return this._currForecastList;
  }

  forecastListLoading = false;

  loadForecastList() {
    let dateShortFormat = "M/d/yy";
    if (this.translate.currentLang) {
      dateShortFormat = getLocaleDateFormat(this.translate.currentLang, FormatWidth.Short);
    }
    var index = this.entityTypeList.indexOf(this.planModel.entityType);
    var entityTypeKey = this.entityTypeKeys[index];

    var entityOid = this.planModel.entity.oid;

    if (entityTypeKey && entityOid) {
      console.log(environment.espEndpointBaseUrl + appSettings.resourceLocations.forecastList + `/${entityTypeKey}` + `/${entityOid}`);

      this.forecastListLoading = true;
      const successHandler = (data: any) => {
        this._currForecastList = new Array<SelectItem>();
        data.ltfcastEntries.forEach(e => {
          let startDate = "";
          if (e.startDate !== null) {
            startDate = this.datePipe.transform(new Date(e.startDate), dateShortFormat, "UTC +0000");
          }
          let endDate = "";
          if (e.endDate !== null) {
            endDate = this.datePipe.transform(new Date(e.endDate), dateShortFormat, "UTC +0000");
          }

          let fcLabel = e.name + ` (${startDate}`;
          if (startDate !== "" && endDate !== "") {
            fcLabel = fcLabel + " - ";
          }
          fcLabel = fcLabel + `${endDate})`;
          const item: SelectItem = {label: fcLabel, value: e.oid, object: e};
          this._currForecastList.push(item);
        });

        this.forecastListLoading = false;
        this.forecastEl.showLoadingIcon(false);
      };
      const errorHandler = (error: any) => {
        console.log(error);
        if(error.status===401){
          this.store.dispatch(new appActions.LTFRetrieveFail(error));
          this.dismiss();

        } else if (error.status === 500) {
          this.store.dispatch(new appActions.UnrecoverableError(error));
          this.dismiss();
        }
      }; // error path
      this.planService.getLTForecastList(entityTypeKey, entityOid).subscribe(successHandler, errorHandler);

    }

  }

  updateForecast(event: SelectItem) {
    this.selectedForecast = event;
    var ltf = event ? event.object : null;
    this.planModel.forecast = ltf;
  }

  checkFormValidationStatus() {

  }

  get hasValidForm() {

    var uniquePlanName = this.planNameEl && (this.planNameEl.serverSideErrMsgs.length == 0);
    var validPlanName = PlanHelper.planNameRegEx.test(this.planModel.planName) && uniquePlanName;
    var validEntityType = this.planModel.entityType !== null && this.planModel.entityType.length > 1;
    var validEntity = this.planModel.entity !== null;
    var validForecast = this.planModel.forecast !== null;
    var res = (validPlanName && validEntityType && validEntity && validForecast);
    return res;
  }

  get enableCreatePlan() {
    return this.hasValidForm && !this.planNameEl.hasFocus && !this.checkingUniquePlanName;
  }

  planNameValidator(name: string) {

    if (name.length < 1) {
      return new ValidationResult("This field is required");
    } else if (name.length > 32) {
      return new ValidationResult("Maximum 32 characters");
    } else if (!PlanHelper.planNameRegEx.test(name)) {
      return new ValidationResult("Invalid Plan Name");
    } else {
      return new ValidationResult("");
    }
  }


  submit() {
    const index = this.entityTypeList.indexOf(this.planModel.entityType);
    const entityTypeKey = this.entityTypeKeys[index].toLocaleUpperCase();
    const sDate = new Date(this.planModel.forecast.startDate);
    const eDate = new Date(this.planModel.forecast.endDate);

    const planCreateReq = {
      planName: this.planModel.planName,
      entityType: entityTypeKey,
      entityId: this.planModel.entity.id,
      entityOid: this.planModel.entity.oid,
      entityName: this.planModel.entity.name,
      ltfcstName: this.planModel.forecast.name,
      ltfcstOid: this.planModel.forecast.oid,
      startDate: this.datePipe.transform(sDate, "yyyy-MM-dd", "UTC +0000"),
      endDate: this.datePipe.transform(eDate, "yyyy-MM-dd", "UTC +0000"),
    };

    return this.planService.createPlan(planCreateReq);
  }
}


