import {IHeaderParams, IHeaderGroupParams} from "ag-grid-community/main";

export class PlanDetailHeaderBase {

  protected params: IHeaderGroupParams|IHeaderParams;

  // equivalent of init in IHeaderGroupComp,
  // IHeaderGroupCompParams is same as non Angular version
  agInit(params: IHeaderGroupParams|IHeaderParams): void {
    this.params = params;
  }

  get displayName() {
    return this.params.displayName;
  }
}
