// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  font-size: 14px;
  color: #787878;
  border: solid 1px #ced4da;
  width: 400px;
  height: calc(1.5em + 0.75rem + 2px);
  padding-left: 10px;
}
input::-ms-clear {
  display: none;
}

input:read-only {
  background-color: #F8F8F8;
}

.input-with-icon {
  position: relative;
}

.input-with-icon div.icon {
  cursor: pointer;
  padding-right: 30px;
  color: #787878;
  border: 0;
  position: absolute;
  right: 0;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-with-icon div.icon:active {
  right: -1px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wfm-icontextbox/wfm-icontextbox.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,yBAAA;EACA,YAAA;EAEA,mCAAA;EACA,kBAAA;AAAJ;AAEI;EACI,aAAA;AAAR;;AAIA;EACE,yBAAA;AADF;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,eAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,QAAA;EAEA,2BAAA;AADJ;;AAIA;EACI,WAAA;AADJ","sourcesContent":["input {\n    font-size: 14px;\n    color: #787878;\n    border: solid 1px #ced4da;\n    width: 400px;\n    //line-height: 1.5;\n    height: calc(1.5em + 0.75rem + 2px);\n    padding-left: 10px;\n\n    &::-ms-clear {\n        display: none;\n    }\n}\n\ninput:read-only {\n  background-color: #F8F8F8;\n}\n\n.input-with-icon {\n    position: relative;\n}\n\n.input-with-icon div.icon {\n    cursor: pointer;\n    padding-right: 30px;\n    color: #787878;\n    border: 0;\n    position: absolute;\n    right: 0;\n    margin: 0;\n    top: 50%;\n    -ms-transform: translateY(-50%);\n    transform: translateY(-50%);\n}\n\n.input-with-icon div.icon:active {\n    right: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
