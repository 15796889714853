import {DatePipe, FormatWidth, getLocaleDateFormat} from "@angular/common";
import {Component, OnInit} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {environment} from "src/environments/environment";

@Component({
  selector: "modified-date-field",
  templateUrl: "./modified-date-field.component.html",
  styleUrls: ["./modified-date-field.component.scss"]
})
export class ModifiedDateFieldComponent implements OnInit {
  private currentItem;
  private params;
  content: String;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  agInit(params) {
    if (params && params.data) {
      let locale = (params.locale) ? params.locale : environment.defaultLocale;
      let dateFormat = (params.dateFormat) ? params.dateFormat : (environment.locales.find(l => l.locale === locale)).dateFormat;
      dateFormat = dateFormat?dateFormat:getLocaleDateFormat(locale, FormatWidth.Short);

      this.currentItem = params.data;
      this.params = params;
      let date: Date = params.data.modifiedTime ? new Date(params.data.modifiedTime) : null;
      if (date) {
        let datePipe = new DatePipe(locale);
        this.content = datePipe.transform(date, dateFormat);
      } else {
        this.content = this.translate.instant("plan.planStatus." + params.data.status.toUpperCase());
      }
    }

  }
}
