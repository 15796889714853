import {Directive, HostBinding, Input, OnDestroy, OnInit, Renderer2} from "@angular/core";
import {AgGridAngular} from "ag-grid-angular";
import {fromEvent, Observable, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Directive({
  selector: "[full-width-table]"
})
export class FullWidthTableDirective implements OnInit, OnDestroy {

  @Input()
  tableRef: AgGridAngular;

  private resizeStream$: Observable<any>;
  private resizeSubscription: Subscription;

  constructor(private renderer: Renderer2) {
  }

  @HostBinding("class.full-width-table")
  fullWidthTable = true;

  private resizeTable() {
    this.tableRef.api.sizeColumnsToFit();
  }

  ngOnInit() {
    this.resizeStream$ = fromEvent(window, "resize").pipe(debounceTime(500));
    this.resizeSubscription = this.resizeStream$.subscribe(this.resizeTable.bind(this));
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}
