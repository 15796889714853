import {Injectable} from "@angular/core";
import {Action} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {AppActionTypes, LocaleUpdated, RefreshTokenRequest} from "../actions";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "src/app/authentication.service";
import {HttpClient} from "@angular/common/http";
import {TenantService} from 'src/app/tenant.service';

@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private tenantService: TenantService
  ) {
  }

  requestSuccessful$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.RequestSuccessful),
    map((action: RefreshTokenRequest) => action.payload),
    mergeMap((payload: any) => {
      return this.authenticationService.refreshTokenIfNecessary();
    })
  ));

  UpdateLocale$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(AppActionTypes.UpdateLocale),
    map((action: LocaleUpdated) => action.payload),
    mergeMap((payload: any) => {
      if (payload) {
        if (this.tenantService.isCXOne) {
          console.debug("CXOne-locale:", payload);
          this.translateService.use("en-US-cxone");
        } else {
          this.translateService.use(payload);
        }
        return of({type: AppActionTypes.LocaleUpdated, payload: true});
      }
      return of({type: AppActionTypes.LocaleUpdated, payload: false});
    })
  ));
}

