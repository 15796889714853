import { Component, OnInit } from '@angular/core';
import { MessageUtils } from 'src/app/common/utils/message.utils';

@Component({
  selector: 'wfm-esp-unrecoverable-error',
  templateUrl: './unrecoverable-error.component.html',
  styleUrls: ['./unrecoverable-error.component.css']
})
export class UnrecoverableErrorComponent implements OnInit {

  copyrightMessage = "";

  constructor() { }

  ngOnInit(): void {
    this.copyrightMessage = MessageUtils.getCopyrightMessage();
  }

}
