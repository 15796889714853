import {createFeatureSelector, createSelector} from "@ngrx/store";
import {Task} from "protractor/built/taskScheduler";

export interface UiState {
  isLoading: boolean,
  isCXoneTenant: boolean
}

export const INITIAL_UI_STATE: UiState = {
  isLoading: true,
  isCXoneTenant:false
};

const uiStateFeatureSelector = createFeatureSelector<UiState>("uiState");
export const isLoadingSelector = createSelector(uiStateFeatureSelector, (uiState) => uiState.isLoading);

