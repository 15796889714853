// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 1200px) {
  .modal-body.convert-modal-body {
    min-width: 500px;
  }
}
.primary-text {
  font-size: 15px;
  font-weight: bold;
  color: #464646;
}

.row.separator {
  margin: 18px 0;
  height: 1px;
  background: #d9d9d9;
}

.group-title {
  margin-bottom: 5px;
}

.input-label {
  margin-left: 2px;
  margin-top: 8px;
}

.warning-message i {
  color: #ffa638;
  font-size: 18px;
  vertical-align: text-bottom;
}

.warning-message {
  font-size: 14px;
  margin-top: 14px;
}

.popover {
  max-width: none;
}

.popover .button-response {
  width: 325px;
}

wfm-plan-loading-overlay {
  top: 0;
  bottom: 0;
  position: absolute;
  left: -1px;
  right: 0;
  background: rgba(0, 0, 0, 0.0784313725);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/convert-plan-dialog/convert-plan-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE;IACE,gBAAA;EACF;AACF;AACA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAGA;EACE,cAAA;EACA,WAAA;EACA,mBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,eAAA;AAAF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,2BAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;AAEF;;AACA;EACE,eAAA;AAEF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,MAAA;EACA,SAAA;EACA,kBAAA;EACA,UAAA;EACA,QAAA;EACA,uCAAA;EACA,UAAA;AAEF","sourcesContent":["@media (min-width: 1200px) {\n  .modal-body.convert-modal-body {\n    min-width: 500px;\n  }\n}\n.primary-text{\n  font-size: 15px;\n  font-weight: bold;\n  color:#464646;\n\n}\n\n.row.separator{\n  margin: 18px 0;\n  height: 1px;\n  background: #d9d9d9;\n}\n\n.group-title{\n  margin-bottom: 5px;\n}\n\n.input-label{\n  margin-left: 2px;\n  margin-top: 8px\n}\n.warning-message i{\n  color:#ffa638;\n  font-size: 18px;\n  vertical-align: text-bottom;\n}\n.warning-message{\n  font-size:14px;\n  margin-top:14px;\n}\n//::ng-deep \n.popover{\n  max-width: none;\n}\n//::ng-deep \n.popover .button-response{\n  width:325px;\n}\n\nwfm-plan-loading-overlay{\n  top: 0;\n  bottom: 0;\n  position: absolute;\n  left: -1px;\n  right: 0;\n  background: #00000014;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
