// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
}

.non-workload {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: none;
  width: 100%;
  height: 100%;
}

/*.non-workload.message {*/
/*  padding-top: 15px;*/
/*  font-family: inherit;*/
/*}*/
.backlog-setting {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
}

.user-field {
  padding-top: 12px;
}

input.input-wider {
  width: 8em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

:host ::ng-deep p-inputnumber.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #d82132;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/plan/ct-settings/backlog/backlog.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA,0BAAA;AACA,uBAAA;AACA,0BAAA;AACA,IAAA;AAEA;EACE,wBAAA;EACA,0BAAA;EACA,4BAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAGA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AAAF;;AAGA,YAAA;AACA;EACE,0BAAA;AAAF;;AAIE;EACE,qBAAA;AADJ","sourcesContent":[".container {\n  height: 100%;\n}\n\n.non-workload {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  flex: none;\n  width: 100%;\n  height: 100%;\n}\n\n/*.non-workload.message {*/\n/*  padding-top: 15px;*/\n/*  font-family: inherit;*/\n/*}*/\n\n.backlog-setting {\n  border-bottom-width: 1px;\n  border-bottom-style: solid;\n  border-bottom-color: #cccccc;\n  padding-bottom: 12px;\n  font-weight: 700;\n  font-size: 14px;\n}\n\n.user-field {\n  padding-top: 12px;\n}\n\ninput.input-wider {\n  width: 8em;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\n:host ::ng-deep {\n  p-inputnumber.ng-invalid>.p-inputnumber>.p-inputtext {\n    border-color: #d82132;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
