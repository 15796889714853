
export class NumberUtils {
  public static DEFAULT_DECIMAL_DIGITS: number = 2;
  public static DECIMAL_POINT = ".";

  public static formatNumberIfNecessary(value, locale: string, decimalDigits: number) {
    let decimal = (decimalDigits) ? decimalDigits : this.DEFAULT_DECIMAL_DIGITS;
    if (value === "???" || value === undefined) {
      return "???";
    }

    if (value !== null && value !== "" && !isNaN(value)) {
      return Number(value).toLocaleString(locale, { minimumFractionDigits: decimal, maximumFractionDigits: decimal, useGrouping:true });
    }
    return value;
  }

  public static isIntegerNumber(value: string) {
    const regExpInteger = /^\d+$/;
    return (String(value).trim() !== "" && regExpInteger.test(value));
  }

  public static isNegativeNumber(value) {
    return !Object.is(Math.abs(value), +value);
  }

}
