import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {ControlValueAccessor} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {DateUtils} from "../../common/utils/date";

@Component({
  selector: "wfm-esp-week-picker",
  templateUrl: "./week-picker.component.html",
  styleUrls: ["./week-picker.component.scss"]
})
export class WeekPickerComponent implements OnInit ,ControlValueAccessor, AfterViewInit {


  @Input()
  minDate:Date;

  @Input()
  maxDate:Date;


  @Input()
  startDate:Date;

  @Output()
  onDateChange = new EventEmitter();

  displayDate: string;

  minMaxRange = {
    fromDate:null,
    toDate:null
  }

  currentDate = {
    fromDate: null,
    toDate: null
  };

  hoveredDate = {
    fromDate: null,
    toDate: null
  };

  private display="";

  @ViewChild(NgbDatepicker, { static: false }) ngbDatepicker;

  set week(value) {
    this.onChange({ year: this.currentDate.fromDate.year, week: value });
  }

  disabled = false;
  onChange: (_: any) => void;
  onTouched: any;

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe:DatePipe) {
   // this.currentDate.fromDate = calendar.getToday();
   // this.currentDate.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {

    if(this.startDate) {
      this.onDateSelection(this.convertDateToNgbDate(this.startDate));
    }

    if(this.minDate){
      this.minMaxRange.fromDate=this.convertDateToNgbDate(this.minDate);
    }

    if(this.maxDate){
      this.minMaxRange.toDate=this.convertDateToNgbDate(this.maxDate);
    }

  }

  isHovered(date: NgbDate) {
    /*return this.currentDate.fromDate &&
      !this.currentDate.toDate &&
      this.hoveredDate &&
      date.after(this.currentDate.fromDate) && date.before(this.hoveredDate);*/
    return  this.hasDate(this.hoveredDate) && (
            date.equals(this.hoveredDate.fromDate) ||
            date.equals(this.hoveredDate.toDate) ||
            (date.after(this.hoveredDate.fromDate) && date.before(this.hoveredDate.toDate)));
  }
  isInsideCurrent(date: NgbDate) {
    if(this.currentDate.fromDate && this.currentDate.toDate){
      return date.after(this.currentDate.fromDate) && date.before(this.currentDate.toDate);
    }else{
      return this.hasDate(this.hoveredDate) && date.after(this.hoveredDate.fromDate) && date.before(this.hoveredDate.toDate);
    }
  }

  isCurrentRange(date: NgbDate) {
    if(this.currentDate.fromDate && this.currentDate.toDate){
      return date.equals(this.currentDate.fromDate) || date.equals(this.currentDate.toDate);
    }else{
      return this.hasDate(this.hoveredDate) &&(date.equals(this.hoveredDate.fromDate) || date.equals(this.hoveredDate.toDate));
    }
  }
  onHover(date: NgbDate){
    this.hoveredDate = this.getWeekRange(date);
  }
  hasDate(dateRange){
    return dateRange && dateRange.fromDate && dateRange.toDate
  }
  onDateSelection(date: NgbDate) {
    this.currentDate = this.getWeekRange(date);
    this.updateDisplay();
    this.onDateChange.emit(this.convertNgbDateToDate(this.currentDate.fromDate));

    if (this.onTouched) this.onTouched();
    //if (this.onChange) this.week = this.calculateWeek(fromDate);
  }
  getWeekRange(date:NgbDate){
    let range = {
      fromDate: null,
      toDate:null

    };

    let fromDate = new Date(date.year + "/" + date.month + "/" + date.day);
    let time = fromDate.getDay();
    fromDate = new Date(fromDate.getTime() - time * 24 * 60 * 60 * 1000);
    range.fromDate = new NgbDate(
      fromDate.getFullYear(),
      fromDate.getMonth() + 1,
      fromDate.getDate()
    );
    const toDate = new Date(fromDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    range.toDate = new NgbDate(
      toDate.getFullYear(),
      toDate.getMonth() + 1,
      toDate.getDate()
    );
    return range;
  }
  calculateDate(week: number, year: number) {
    const firstDay = new Date(year + "/1/4");
    const date = new Date(
      firstDay.getTime() + (week - 1) * 7 * 24 * 60 * 60 * 1000
    );
    const selectDate = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    this.onDateSelection(selectDate);
  }
  ngAfterViewInit() {
    if (this.currentDate.fromDate) {
      setTimeout(() => {
        //this.datePicker.navigateTo(this.currentDate.fromDate);
      });
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  writeValue(value: any): void {
    if (value) {
      this.calculateDate(value.week, value.year);
    }
  }
  convertNgbDateToDate(ngbDate:NgbDate){
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }
  convertDateToNgbDate(date:Date){
    var ngbDateStruct = new NgbDate(date.getUTCFullYear(),date.getUTCMonth() + 1,date.getUTCDate());
    return ngbDateStruct;
  }
  updateDisplay(){
    this.display = DateUtils.getShortDate2(this.datePipe, this.convertNgbDateToDate(this.currentDate.fromDate))
                   + " - " +
                   DateUtils.getShortDate2(this.datePipe, this.convertNgbDateToDate(this.currentDate.toDate))
  }

}
