import {Component, Input, OnInit} from '@angular/core';
import {CellPosition, ColumnApi, GridApi} from '@ag-grid-enterprise/all-modules';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'validation-navigator',
  templateUrl: './validation-navigator.component.html',
  styleUrls: ['./validation-navigator.component.css'],
  animations: [
   /* trigger('slideFadeIn', [
      state('void', style({
        opacity: 0,
        transform: 'translateY(50%)'
      })),
      transition(':enter', [
        animate('200ms ease-in', style({
          opacity: 1,
          transform: 'translateY(0)'
        }))
      ])
    ])*/
  ]
})
export class ValidationNavigatorComponent implements OnInit {

  @Input() gridApi: GridApi;
  @Input() gridContext: any;
  @Input() invalidCount: number=0;

  constructor() {
  }

  ngOnInit(): void {

  }

  onClick() {
    if (this.gridApi) {
      let gridContext = this.gridContext;

      let foundCell = this.searchGrid(this.gridApi, (node, column) => {
        return gridContext.isValidCell({data: node.data, colDef: column}) == false;
      });
      this.focusAndFlashCell(this.gridApi, foundCell.node, foundCell.index, foundCell.column);
    }
  }

  searchGrid(gridApi, searchCallback) {
    const focusedCell = gridApi.getFocusedCell();
    const startRow = focusedCell ? focusedCell.rowIndex : 0;
    const startColumn = focusedCell ? focusedCell.column.colId : 0;
    const columnDefs = this.getColumnDefinitionsFlat();
    let foundCell = null;

    let initialColIndex = focusedCell ? columnDefs.findIndex(colDef => colDef.field === startColumn) + 1 : 0;


    gridApi.forEachNode((node, index) => {
      if (foundCell) return;

      if (index >= startRow) {
        for (let colIndex = 0; colIndex < columnDefs.length; colIndex++) {
          if (index === startRow && colIndex < initialColIndex) continue;

          const column = columnDefs[colIndex];


          if (searchCallback(node, column)) {
            foundCell = {node, index, column};
            return;
          }
        }
        initialColIndex = 0;
      }
    });

    if(foundCell){
      return foundCell;
    }else {
      //if we got here, it means we couldn't find invalid cell from focused cell
      //so we start again but from the top left cell
      let firstColumn = this.gridApi.getColumnDefs()[0];
      this.gridApi.setFocusedCell(0, firstColumn["field"]);

      return this.searchGrid(gridApi, searchCallback);
    }

  }
  focusAndFlashCell(gridApi: GridApi, node: any, rowIndex: number, column: any): void {
    gridApi.ensureIndexVisible(rowIndex, 'middle');
    gridApi.ensureColumnVisible(column.field);

    const cellRangeParams = {
      rowStartIndex: rowIndex,
      rowEndIndex: rowIndex,
      columnStart: column.field,
      columnEnd: column.field,
    };

    gridApi.clearRangeSelection();
    gridApi.addCellRange(cellRangeParams);

    gridApi.setFocusedCell(rowIndex, column.field);
    gridApi.flashCells({ rowNodes: [node], columns: [column.field] });
  }
  getColumnDefinitionsFlat() {
    let columnDefsFlat = [];
    //should replace this with flatMap
    this.gridApi["columnModel"].columnDefs.forEach((colDef) => {
      if (colDef.children) {//it's a week/day view
        colDef.children.forEach((colDefChild) => {
          columnDefsFlat.push(colDefChild);
        });
      } else {
        columnDefsFlat.push(colDef);
      }
    });

    return columnDefsFlat;
  }

}
