import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "esp-multi-select-footer",
  templateUrl: "./multi-select-footer.component.html",
  styleUrls: ["./multi-select-footer.component.scss"]
})
export class MultiSelectFooterComponent implements OnInit {
  @Input() multiSelect;

  get filteredOptionsCount(){
    if(this.multiSelect.visibleOptions()) {
      return this.multiSelect.visibleOptions().length
    }else{
      return this.optionsCount;
    }
  }
  get optionsCount(){
    if(this.multiSelect._options()){
      return this.multiSelect._options().length
    }else{
      return 0;
    }
  }
  constructor() { }

  ngOnInit(): void {

  }

}
