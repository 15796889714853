import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BackgroundTask} from "../../store/application-state";
import {Store} from '@ngrx/store';
import {TaskCancel} from '../../store/actions';

@Component({
  selector: "wfm-task-progress",
  templateUrl: "./task-progress.component.html",
  styleUrls: ["./task-progress.component.scss"]
})
export class TaskProgressComponent implements OnInit {
  @Input() task:BackgroundTask
  @Input() canCancel:Boolean=false;
  @Output() cancelTask: EventEmitter<any> = new EventEmitter<any>();
  constructor() {

  }

  ngOnInit(): void {

  }
  onCancelTask(){
    this.cancelTask.emit();
  }
}
